import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { SharedUiModule } from '@dal/shared-ui';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ActivityQueuePageComponent } from './components/activityQueuePage/activityQueuePage.component';
import { HomeComponent } from './components/home/home.component';
import { EditAssetPageComponent } from './components/EditAssetPage/edit-asset-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BulkAddEditMetadataComponent } from './components/uploadPage/bulkAddEditMetadata/bulk-add-edit-metadata.component';
import { AssetPageEditMetadataComponent } from './components/EditAssetPage/AssetPageEditMetadata/AssetPage-edit-metadata.component';
import { AssetPagePreviewMetadataComponent } from './components/EditAssetPage/AssetPagePreviewMetadata/AssetPage-preview-metadata.component';
import { ActivityQueueDialogComponent } from './components/activityQueueDialog/activityQueueDialog.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SearchResultComponent } from './components/search-results/search-result.component';
import { AssetUploadPreviewComponent } from './components/uploadPage/assetuploadpreview/assetuploadpreview.component';
import { UploadPageComponent } from './components/uploadPage/uploadPage.component';
import { UserPageComponent } from './components/userPage/userPage.component';
import { EditUserPageComponent } from './components/editUserPage/editUserPage.component';
import {
  AuthInterceptor,
  ErrorInterceptor,
  SpinnerHttpInterceptorService,
} from '@dal/core';
import { OktaCallbackComponent } from './components/oktaCallback/oktaCallback.component';
import { MetadataTemplateAdminPageComponent } from './components/metadataTemplateAdminPage/metadataTemplateAdminPage.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EditAssetPageComponent,
    ActivityQueuePageComponent,
    BulkAddEditMetadataComponent,
    AssetPageEditMetadataComponent,
    ActivityQueueDialogComponent,
    AssetPagePreviewMetadataComponent,
    SearchResultComponent,
    UploadPageComponent,
    AssetUploadPreviewComponent,
    UserPageComponent,
    AssetUploadPreviewComponent,
    EditUserPageComponent,
    OktaCallbackComponent,
    MetadataTemplateAdminPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    SharedUiModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerHttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
