<div class="mt-4 flex items-center justify-between">
  <span class="pl-[2%] mt-4 text-2xl text-primary">{{fileName}}</span>
  <span *ngIf="!isMetadataEdit" class="mr-4 flex">
    <button class="button bg-primary w-[8rem] h-[2.5em] border-[1px] border-none text-white font-[2px] text-[0.8rem] rounded-3xl mx-[1%] shadow-0 cursor-pointer"  (click)="saveData()">
      SAVE CHANGES
    </button>
    <button class="button bg-white w-[10rem] h-[2.5em] border-[1px] mx-[1%] rounded-3xl text-primary text-[0.8em] border-solid cursor-pointer"  (click)="saveData()">
      SAVE AND EDIT NEXT
    </button>
    <button class="button bg-white w-[8rem] h-[2.5em] border-[1px] rounded-3xl text-primary text-[0.8em] border-solid cursor-pointer" (click)="toogleMetdataView()">
      CANCEL
    </button>
  </span>
</div>


<div class="pl-[2%] mt-4 pr-[2%] flex justify-between">
  <span class="pb-2">Metadata: Images</span>
  <span *ngIf="isMetadataEdit" class="inline-flex">
    <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="black" fill-opacity="0.01" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12ZM13.0001 17.9999V12.9999H18.0001V10.9999H13.0001V5.99994H11.0001V10.9999H6.00007V12.9999H11.0001V17.9999H13.0001Z"
        fill="#265867" />
    </svg>
    <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none" (click)="toogleMetdataView()"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="black" fill-opacity="0.01" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.53531 14.3136L19.1419 3.70696C19.5324 3.31643 20.1656 3.31643 20.5561 3.70696L23.3846 6.53538C23.7735 6.92429 23.7735 7.56069 23.3846 7.9496L12.7779 18.5562L7.12109 19.9704L8.53531 14.3136ZM9.06564 16.2581L8.53531 18.5562L10.8334 18.0259L9.06564 16.2581ZM20.5561 9.36381L22.6774 7.24249L19.849 4.41406L9.24241 15.0207L12.0708 17.8491L19.849 10.0709L17.7277 7.9496L18.4348 7.24249L20.5561 9.36381Z"
        fill="#265867" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M5.83318 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H18.435L17.435 4H3V20H6.08318L5.83318 21Z"
        fill="#009BB5" />
    </svg>
    <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="black" fill-opacity="0.01" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M12.7077 15.536L7.75796 10.5862L8.46507 9.87913L13.4148 14.8289C13.8053 15.2194 14.4385 15.2194 14.829 14.8289C15.2196 14.4384 15.2196 13.8052 14.829 13.4147L7.75796 6.3436C6.97692 5.56255 5.71059 5.56255 4.92954 6.3436C4.14849 7.12465 4.14849 8.39098 4.92954 9.17203L14.1219 18.3644C15.2935 19.536 17.193 19.536 18.3646 18.3644C19.5361 17.1928 19.5361 15.2933 18.3646 14.1218L9.87928 5.63649L10.5864 4.92939L19.0717 13.4147C20.6338 14.9768 20.6338 17.5094 19.0717 19.0715C17.5096 20.6336 14.9769 20.6336 13.4148 19.0715L4.22243 9.87913C3.05086 8.70756 3.05086 6.80806 4.22243 5.63649C5.394 4.46492 7.2935 4.46492 8.46507 5.63649L15.5361 12.7076C16.3172 13.4886 16.3172 14.7549 15.5361 15.536C14.7551 16.317 13.4888 16.317 12.7077 15.536Z"
        fill="#265867" />
    </svg>

    <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="black" fill-opacity="0.01" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M9 5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5H20V7H19V21C19 21.55 18.55 22 18 22H6C5.44772 22 5 21.5523 5 21V7H4V5H9ZM10 5H14C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5ZM6 7V21H18V7H6ZM14 9H15V19H14V9ZM11 9H12V19H11V9ZM8 9H9V19H8V9Z"
        fill="#265867" />
    </svg>

    <svg class="ml-2" width="24" height="24" viewBox="0 0 24 24" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" fill="black" fill-opacity="0.01" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M4 19H20V20H4V19ZM21 5V17C21 17.55 20.55 18 20 18H4C3.44772 18 3 17.5523 3 17V5C3 4.44772 3.44772 4 4 4H20C20.55 4 21 4.45 21 5ZM4 5V17H20V5H4ZM10 8L15 11L10 14V8Z"
        fill="#265867" />
    </svg>

  </span>
</div>

<div class="ml-6 mr-4">
  <div class="grid grid-cols-2 border-2 border-grey-500">
    <div class="bg-gray-100">
      <!-- (formChaangeEvent)="c($event)" -->
      <dal-asset-page-edit-metadata *ngIf="!isMetadataEdit" [assetDetails]=assetDetails></dal-asset-page-edit-metadata>
      <dal-asset-page-preview-metadata *ngIf="isMetadataEdit" [assetID]=assetID></dal-asset-page-preview-metadata>
    </div>
    <div class="bg-gray-100">
      <dal-shared-ui-assetpreview [mediaFiles]=mediaFiles></dal-shared-ui-assetpreview>
    </div>
  </div>
</div>
