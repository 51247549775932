import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { OktaAuthService } from '@dal/core';

@Component({
  selector: 'dal-shared-ui-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
})
export class SidenavComponent implements OnChanges {
  @Input() elements: {
    name: string;
    link: string;
  }[] = []
  @Input() showSideNav = false;
  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(private route: Router, private oktaAuthService: OktaAuthService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showSideNav'].currentValue) {
      this.sidenav?.open()
    } else {
      this.sidenav?.close()
    }
  }


  goto(link: string): void {
    this.route.navigateByUrl(link);
  }

  logout(): void {
    this.oktaAuthService.logout();
  }

}
