import { Component, EventEmitter, Input, Output, Renderer2, ElementRef, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'dal-shared-ui-dragdropfile',
  templateUrl: './dragdropfile.component.html',
  styleUrl: './dragdropfile.component.scss',
})
export class DragdropfileComponent implements AfterViewChecked {

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  @Input() files: File[] = [];
  @Input() role: string = "";
  @Input() duplicateFiles: string[] = [];
  @Output() uploadEvent = new EventEmitter<any>();

  ngAfterViewChecked() {
    if (this.duplicateFiles.length !== 0 ){
      for (let i = 0; i < this.duplicateFiles.length; i++) {
        const element = document.getElementById(this.duplicateFiles[i]);
        const element2 = document.getElementById('error');
        if (element) {
          element.style.borderColor = 'red';
        }
        if (element2) {
          element2.style.display = 'block';
        }
      }
    }else{
      const element2 = document.getElementById('error');
      if (element2) {
        element2.style.display = 'none';
      }
    }
  }

  onFileDropped($event: any): void {
    this.prepareFilesList($event.dataTransfer.files);
  }

  fileBrowseHandler(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement?.files) {
      this.prepareFilesList(inputElement.files);
    }
  }

  prepareFilesList(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      this.files.push(file);
    }
    this.uploadEvent.emit(this.files);
  }

  deleteFile(index: number): void {
    for (let i = 0; i < this.files.length; i++) {
      const file: File = this.files[i];
      if(this.duplicateFiles.includes(this.files[i].name)){
        this.duplicateFiles = this.duplicateFiles.filter(item => item !== this.files[i].name);
      }
    }
    this.files.splice(index, 1);
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
