import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AssetPageEditMetadataComponent } from './AssetPageEditMetadata/AssetPage-edit-metadata.component';
import { AbsService, AssetMetadataService, AssetService, MetadataService } from '@dal/core';
import { forkJoin, Subscription, switchMap } from 'rxjs';


@Component({
  selector: 'dal-edit-asset-page',
  templateUrl: './edit-asset-page.component.html',
  styleUrl: './edit-asset-page.component.scss',
})

export class EditAssetPageComponent implements OnInit {
  isMetadataEdit: boolean = true;
  // @ViewChild(AssetPageEditMetadataComponent)
  @ViewChild(AssetPageEditMetadataComponent) AssetPageEditMetadataComponent!: AssetPageEditMetadataComponent; // Access child component
  childComponent: AssetPageEditMetadataComponent;
  fileName: string = '';
  assetDALID: any;
  assetID: number;
  metadataEditSuccess: boolean = false;
  metadataEditFailed: boolean = false;
  isSaveButtonDisabled: boolean = false;
  mediaFiles: any[] = [];
  assetDetails: any;
  assetData: any;
  AssetMetadata: any;
  numberOfPeopleOptions: any[] = [];
  metadata: any[];
  assetTypeId: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private absService: AbsService,
    private mdService: MetadataService,
    private assetService: AssetService,
    private assetMetadataService: AssetMetadataService
  ) {}

  ngOnInit() {
    this.assetDALID = this.route.snapshot.paramMap.get('id');

    if (this.assetDALID) {
      this.getAsset(this.assetDALID);
    } else {
      alert('DAL Asset ID is null');
      console.error('DAL Asset ID is null');
    }
  }

  getPreviewUrl(renditions: any[]): string {
    let preview = renditions.find(rendition => rendition.rendition_type.name === 'Preview');
    if (!preview) {
      preview = renditions.find(rendition => rendition.rendition_type.name === 'Original');
    }
    return preview ? preview.url : '';
  }

  getAsset(assetDALID: string) {
    this.assetService.getAssetByDalId(assetDALID).subscribe({
      next: (res: any) => {
        this.assetID = res.id;
        console.log('asset', this.assetID);
        this.assetMetadataService.getMetadataById(this.assetID).subscribe({
          next: (res: any) => {
            const mediaUrl = this.getPreviewUrl(res.asset.renditions) || '';
            this.assetData = res.asset;
            this.fileName = res.asset.filename;
            this.assetTypeId = res.asset.asset_type_id;
            this.mediaFiles.push({url: mediaUrl}); // Make sure mediaFiles is not undefined
            //TODO: 
            this.metadata = res.metadata;
            this.AssetMetadata = this.processMetadata(this.assetData, this.metadata);
            sessionStorage.setItem(
              'OriginalAssetMetadata',
              this.AssetMetadata
            );
            this.assetDetails = this.AssetMetadata;
            this.route.queryParams.subscribe(params => {
              if (params['edit'] === 'true') {
                this.toggleMetdataView();
              }
            });
          },
          error: (err: any) => console.error('Error fetching metadata:', err)
        });
      },
      error: (err: any) => console.error('Error fetching asset:', err)
    });
  }

  processMetadata(asset: any, metadata: any[]) {
    const processedMetadata: any = {

      Descriptive: {
        Title: asset?.title || '',
        Description: asset?.description || '',
      },
      Informational: {
        Filename: asset?.filename || '',
        SourceFilename: asset?.source_filename || '',
        DalID: asset?.xid || '',
        DateImported: asset?.created_at || '',
        ImportedBy: asset?.created_by || '',
        LastModifiedDate: asset?.updated_at || '',
        LastModifiedBy: asset?.updated_by || '',
      },
      Rights: {
        GPSUniqueID: asset?.gps_id || '',
      },
      Technical: {},
    };

    if (asset) {
      if (asset.title) {
        processedMetadata.Descriptive.Title = asset.title;
      }
      if (asset.description) {
        processedMetadata.Descriptive.Description = asset.description;
      }
      if (asset.filename) {
        processedMetadata.Informational.Filename = asset.filename;
      }
      if (asset.filename) {
        processedMetadata.Informational.SourceFilename = asset.filename;
      }
      if (asset.id) {
        processedMetadata.Descriptive.AssetID = asset.id;
      }

      if (asset.gps_id) {
        processedMetadata.Rights.GPSUniqueID = asset.gps_id;
      }
      if (asset.dal_id) {
        processedMetadata.Informational.DalID = asset.dal_id;
      }
      if (asset.created_at) {
        processedMetadata.Informational.DateImported = asset.created_at;
      }
      if (asset.created_by) {
        processedMetadata.Informational.ImportedBy = asset.created_by;
      }
      if (asset.updated_at) {
        processedMetadata.Informational.LastModifiedDate = asset.updated_at;
      }
      if (asset.updated_by) {
        processedMetadata.Informational.LastModifiedBy = asset.updated_by;
      }
    }

    if (metadata && Array.isArray(metadata)) {
      metadata.forEach((item) => {
        if(item !== null || item !== undefined){
        switch (item.key) {
          case 'title':
            processedMetadata.Descriptive.Title = item.values.join(', ');
            break;
          case 'description':
            processedMetadata.Descriptive.Description = item.values.join(', ');
            break;
          case 'temporal-spatial':
            processedMetadata.Descriptive.TemporalSpatial = item.values;
            break;
          case 'keywords':
            processedMetadata.Descriptive.Keyword = item.values;
            break;
          case 'age-group':
            processedMetadata.Descriptive.AgeGroup = item.values;
            break;
          case 'number-of-people':
            processedMetadata.Descriptive.NumberOfPeople = item.values.join(', ');
            break;
          case 'metadata-reviewed-by':
            processedMetadata.Descriptive.MetadataReviewedBy =
              item.values.join(', ');
            break;
          case 'file-name':
              processedMetadata.Informational.FileName =
                item.values.join(', ');
              break;
          case 'ethnicity':
            processedMetadata.Descriptive.Ethnicity = item.values;
            break;
          case 'gender':
            processedMetadata.Descriptive.Gender = item.values.join(', ');
            break;
          case 'disability':
            processedMetadata.Descriptive.Disability = item.values.join(', ');
            break;
          case 'metadata-status':
            processedMetadata.Descriptive.MetadataStatus = item.values.join(', ');
            break;
          case 'finalized':
            processedMetadata.Descriptive.FinalizedFlag = item.values.join(', ');
            break;
          case 'metadata-review-completed':
            processedMetadata.Descriptive.MetadataReviewCompleted =
              item.values.join(', ');
            break;
          case 'metadata-reviewed-date':
            processedMetadata.Descriptive.MetadataReviewedDate =
              item.values.join(', ');
            break;
          case 'image-color-or-bw':
            processedMetadata.Descriptive.ImageColor = item.values.join(', ');
            break;
          case 'orientation':
            processedMetadata.Descriptive.Orientation = item.values.join(', ');
            break;
          case 'image-type':
            processedMetadata.Descriptive.ImageType = item.values.join(', ');
            break;
          case 'image-characteristics':
            processedMetadata.Descriptive.CharacteristicsImage =
              item.values;
            break;
          case 'image-subject-genre':
            processedMetadata.Descriptive.ImageSubjectGenre =
              item.values;
            break;
          case 'video-color-or-bw':
            processedMetadata.Descriptive.ColorBWVideo = item.values.join(', ');
            break;
          case 'video-type':
            processedMetadata.Descriptive.VideoType = item.values.join(', ');
            break;
          case 'video-characteristics':
            processedMetadata.Descriptive.CharacteristicsVideo =
              item.values;
            break;
          case 'video-subject-genre':
            processedMetadata.Descriptive.VideoSubjectGenre =
              item.values;
            break;
          case 'video-sound':
            processedMetadata.Descriptive.VideoSound = item.values.join(', ');
            break;
          case 'audio-type':
            processedMetadata.Descriptive.AudioType = item.values.join(', ');
            break;
          case 'audio-subject-genre':
            processedMetadata.Descriptive.AudioSubjectGenre =
              item.values;
            break;
          case 'audio-characteristics':
            processedMetadata.Descriptive.CharacteristicsAudio =
              item.values;
            break;
          case 'media-type':
            processedMetadata.Informational.MediaType = item.values.join(', ');
            break;
          case 'alternate-file-name':
            processedMetadata.Informational.AlternateFileName = item.values;
            break;
          // // case 'source-filename':
          // //   metadata.Informational.SourceFilename = metadata.Informational.Filename = item.value;
          // //   break;
          case 'asset-creator':
            processedMetadata.Informational.AssetCreator = item.values.join(', ');
            break;
          case 'retail-collection':
            processedMetadata.Informational.RetailCollection =
              item.values.join(', ');
            break;
          case 'volume-label':
            processedMetadata.Informational.VolumeLabel = item.values.join(', ');
            break;
          case 'artistic-collection':
            processedMetadata.Informational.ArtisticCollection =
              item.values.join(', ');
            break;
          case 'provider-bu':
                processedMetadata.Informational.ProviderBU = item.values;
            break;
          case 'language':
            processedMetadata.Informational.Language = item.values;
            break;
          case 'date-imported':
            processedMetadata.Informational.DateImported = item.values.join(', ');
            break;
          case 'imported-by':
            processedMetadata.Informational.ImportedBy = item.values.join(', ');
            break;
          case 'last-modified-date':
            processedMetadata.Informational.LastModifiedDate =
              item.values.join(', ');
            break;
          case 'last-modified-by':
            processedMetadata.Informational.LastModifiedBy =
              item.values.join(', ');
            break;
          case 'metadata-last-modified-by':
            processedMetadata.Informational.MetadataLastModifiedBy =
              item.values.join(', ');
            break;
          case 'adaptation-rights':
            processedMetadata.Rights.AdaptationRights = item.values.join(', ');
            break;
          case 'asset-source':
            processedMetadata.Rights.AssetSource = item.values;
            break;
          case 'notes':
            processedMetadata.Rights.Notes = item.values;
            break;
          case 'rights-category':
            processedMetadata.Rights.RightsCategory = item.values.join(', ');
            break;
          case 'copyright-holder':
            processedMetadata.Rights.CopyrightHolder = item.values.join(', ');
            break;
          case 'displayed-credit-required':
            processedMetadata.Rights.DisplayedCreditRequired =
              item.values.join(', ');
            break;
          case 'credit-text':
            processedMetadata.Rights.CreditText = item.values.join(', ');
            break;
          case 'extended-credit-required':
            processedMetadata.Rights.ExtendedCreditRequired =
              item.values.join(', ');
            break;
          case 'extended-credit-text':
            processedMetadata.Rights.ExtendedCreditText = item.values.join(', ');
            break;
          case 'location-release':
            processedMetadata.Rights.LocationRelease = item.values.join(', ');
            break;
          case 'model-release':
            processedMetadata.Rights.ModelReleaseName = item.values.join(', ');
            break;
          case 'gps-asset-status':
            processedMetadata.Rights.GpsAssetStatus = item.values.join(', ');
            break;
          case 'gps-contract-status':
            processedMetadata.Rights.GpsContractStatus = item.values.join(', ');
            break;
          case 'business-unit-rights':
            processedMetadata.Rights.BusinessUnitRights = item.values;
            break;
          case 'distribution-rights':
            processedMetadata.Rights.DistributionRights = item.values;
            break;
          case 'marketing-use':
            processedMetadata.Rights.MarketingUse = item.values.join(', ');
            break;
          case 'usage-restriction':
            processedMetadata.Rights.UsageRestriction = item.values;
            break;
          case 'dal-asset-status':
            processedMetadata.Rights.DalAssetStatus = item.values.join(', ');
            break;
          case 'licensing-limitations':
            processedMetadata.Rights.LicensingLimitations =
              item.values;
            break;
          case 'mime-type':
            processedMetadata.Technical.MimeType = item.values.join(', ');
            break;
          case 'bitmap-color-map':
            processedMetadata.Technical.BitmapColorMap = item.values.join(', ');
            break;
          case 'aspect-ratio':
            processedMetadata.Technical.AspectRatio = item.values.join(', ');
            break;
          case 'frame-rate':
            processedMetadata.Technical.FrameRate = item.values;
            break;
          case 'frame-rate-video':
            processedMetadata.Technical.FrameRateVideo = item.values;
            break;
          case 'audio-tracks-video':
            processedMetadata.Technical.AudioTracksVideo = item.values.join(', ');
            break;
          case 'num-audio-tracks':
            processedMetadata.Technical.AudioTracksVideo = item.values.join(', ');
            break;
          case 'num-video-tracks':
            processedMetadata.Technical.VideoTracksVideo = item.values.join(', ');
            break;
          case 'audio-codec-name':
            processedMetadata.Technical.AudioCodecNameVideo =
              item.values.join(', ');
            break;
          case 'audio-sample-rate':
            processedMetadata.Technical.AudioSampleRateVideo = item.values;
            break;
          case 'audio-sample-size-video':
            processedMetadata.Technical.AudioSampleSizeVideo =
              item.values.join(', ');
            break;
          case 'bitrate-video':
            processedMetadata.Technical.BitRateVideo = item.values.join(', ');
            break;
          case 'bitmap-color-depth':
            processedMetadata.Technical.BitmapColorDepthImage =
              item.values.join(', ');
            break;
          case 'bitmap-height':
            processedMetadata.Technical.BitmapHeight = item.values.join(', ');
            break;
          case 'bitmap-orientation':
            processedMetadata.Technical.BitmapOrientation = item.values;
            break;
          case 'bitmap-resolution':
            processedMetadata.Technical.BitmapResolutionImage = item.values;
            break;
          case 'bitmap-width':
            processedMetadata.Technical.BitmapWidth = item.values;
            break;
          case 'audio-duration':
            processedMetadata.Technical.DurationAudio = item.values;
            break;
          case 'video-duration':
            processedMetadata.Technical.DurationVideo = item.values;
            break;
          case 'file-size':
            processedMetadata.Technical.FileSize = item.values;
            break;
          case 'frame-height':
            processedMetadata.Technical.FrameHeight = item.values;
            break;
          case 'frame-width':
            processedMetadata.Technical.FrameWidth = item.values;
            break;
          case 'video-dimension':
            processedMetadata.Technical.VideoDimension = item.values;
            break;
          case 'video-codec-name':
            processedMetadata.Technical.VideoCodecName = item.values;
            break;

          default:
            console.warn('Unrecognized metadata_type:', item.key);
        }
        }
      });
    }

    return processedMetadata;
  }

  saveData() {
    const formData = this.AssetPageEditMetadataComponent.getFormValue(); // Get form data from child
    // Transform the form data for both API calls
    const deletePayload = this.mdService.prepareDeletePayloadForSpecificKeys(this.assetDALID);
    const metadata = this.mdService.transformFormData(formData, this.assetID, 'sai');
    const data = this.mdService.transformFormDataToStructure(formData, this.assetID);

    this.absService.deleteAssetMetadataDetails(deletePayload).pipe(
      switchMap(() =>
        forkJoin([
          this.absService.saveAssetMetadataDetails(metadata),
          this.absService.saveAssetDetails(data),
        ])
      )
    ).subscribe({
      next: ([metadataResponse, assetDetailsResponse]) => {
        // Success: Handle the success of both API calls
        console.log('Metadata save response:', metadataResponse);
        console.log('Asset details save response:', assetDetailsResponse);

        // Set success flags
        this.metadataEditSuccess = true;
        this.isSaveButtonDisabled = true;
        this.toggleMetdataView();
      },
      error: (error) => {
        // Failure: Handle any error that occurs in either of the requests
        console.log('Error:', error);
        this.metadataEditFailed = true;
        this.isSaveButtonDisabled = false;
      },
    });
  }

  private queryParamsSubscription: Subscription | null = null;
  toggleMetdataView() {
    this.isMetadataEdit = !this.isMetadataEdit;
    
    if (this.isMetadataEdit) {
      this.metadataEditFailed = false;
      this.metadataEditSuccess = false;

      // Unsubscribe from any previous subscription
      if (this.queryParamsSubscription) {
        this.queryParamsSubscription.unsubscribe();
      }

      this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
        if (Object.keys(params).length > 0) {
          this.router.navigate([], { queryParams: null });
        } else {
          window.location.reload();
        }
      });
    }
  }


  dismissSuccessMessage() {
    this.metadataEditSuccess = false; // Hide the success message
  }

  dismissErrorMessage() {
    this.metadataEditFailed = false;
  }
}
