import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetMetadataService {
  private baseUrl = environment.apiBaseUrl

  constructor(private http: HttpClient) { }

  // Create metadata for multiple assets
  createMetadata(assets: any[]): Observable<any> {
    const url = `${this.baseUrl}/asset-metadata`;
    return this.http.post(url, assets);
  }

  // Get metadata for multiple assets by their IDs
  getMetadataBulk(ids: number[]): Observable<any> {
    const url = `${this.baseUrl}/asset-metadata/bulk`;
    return this.http.post(url, ids);
  }

  // Delete metadata entries by metadata IDs
  deleteMetadataBulk(ids: number[]): Observable<any> {
    const url = `${this.baseUrl}/asset-metadata/bulk-delete`;
    return this.http.request('delete', url, { body: ids });
  }

  // Get metadata for an asset by its ID
  getMetadataById(id: number): Observable<any> {
    const url = `${this.baseUrl}/asset-metadata/id/${id}`;
    return this.http.get(url);
  }

  deleteMetadata(assetId: number, payload: any) {
    return this.http.delete(`${this.baseUrl}/asset-metadata/${assetId}`, {
      body: payload
    });
  }
}
