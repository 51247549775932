import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetPageEditMetadataComponent } from './AssetPageEditMetadata/AssetPage-edit-metadata.component';
import { AbsService } from '@dal/core';


@Component({
  selector: 'dal-edit-asset-page',
  templateUrl: './edit-asset-page.component.html',
  styleUrl: './edit-asset-page.component.scss',
})
export class EditAssetPageComponent implements OnInit {
  isMetadataEdit: boolean = true;
  @ViewChild(AssetPageEditMetadataComponent)
  childComponent: AssetPageEditMetadataComponent;
  fileName: string = 'ABC.jpg';
  assetID: number = 1;
  assetDetails: any;
  assetData: any;
  AssetMetadata: any;
  numberOfPeopleOptions: any[] = [];
  metadata: any[];
  constructor(
    private route: ActivatedRoute,
    private assetService: AbsService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((items: any) => {
      this.assetID = items.assetID;
      this.fileName = items.filename;
      this.getAsset(this.assetID);
    });
  }

  getAsset(assetID: number) {
    this.assetService.getAsset(assetID).subscribe((res: any) => {
      this.assetData = res.asset;
      this.metadata = res.metadata;
      this.processMetadata(this.assetData, this.metadata);
      this.AssetMetadata = this.processMetadata(this.assetData, this.metadata);
      this.assetDetails = this.AssetMetadata;
    });
  }

  processMetadata(asset: any, metadata: any[]) {
    const processedMetadata: any = {

      Descriptive: {
        Title: asset?.title || '',
        Description: asset?.description || '',
      },
      Informational: {
        Filename: asset?.filename || '',
        SourceFilename: asset?.source_filename || '',
        DalID: asset?.xid || '',
        SourceAssetID: asset?.source_id || '',
        DateImported: asset?.created_at || '',
        ImportedBy: asset?.created_by || '',
        LastModifiedDate: asset?.updated_at || '',
        LastModifiedBy: asset?.updated_by || '',
      },
      Rights: {
        GPSUniqueID: asset?.gps_id || '',
        AssetSource: asset?.asset_source?.name || '',
      },
      Technical: {},
    };

    if (asset) {
      if (asset.title) {
        processedMetadata.Descriptive.Title = asset.title;
      }
      if (asset.description) {
        processedMetadata.Descriptive.Description = asset.description;
      }
      if (asset.filename) {
        processedMetadata.Informational.Filename = asset.filename;
      }
      if (asset.filename) {
        processedMetadata.Informational.SourceFilename = asset.filename;
      }
      if (asset.id) {
        processedMetadata.Descriptive.AssetID = asset.id;
      }

      if (asset.gps_id) {
        processedMetadata.Rights.GPSUniqueID = asset.gps_id;
      }
      if (asset.dal_id) {
        processedMetadata.Informational.DalID = asset.dal_id;
      }
      if (asset.asset_source_id) {
        processedMetadata.Informational.SourceAssetID = asset.asset_source_id;
      }
      if (asset.created_at) {
        processedMetadata.Informational.DateImported = asset.created_at;
      }
      if (asset.created_by) {
        processedMetadata.Informational.ImportedBy = asset.created_by;
      }
      if (asset.updated_at) {
        processedMetadata.Informational.LastModifiedDate = asset.updated_at;
      }
      if (asset.updated_by) {
        processedMetadata.Informational.LastModifiedBy = asset.updated_by;
      }
      if (asset.asset_source && asset.asset_source.name) {
        processedMetadata.Rights.AssetSource = asset.asset_source.name;
      }
    }

    metadata.forEach((item) => {
      switch (item.metadata_type.key) {
        case 'title':
          processedMetadata.Descriptive.Title = item.values.join(', ');
          break;
        case 'description':
          processedMetadata.Descriptive.Description = item.values.join(', ');
          break;
        case 'temporal-spatial':
          processedMetadata.Descriptive.TemporalSpatial =
            item.values.join(', ');
          break;
        case 'keywords':
          processedMetadata.Descriptive.Keyword = item.values.join(', ');
          break;
        case 'age-group':
          processedMetadata.Descriptive.AgeGroup = item.values.join(', ');
          break;
        case 'number-of-people':
          processedMetadata.Descriptive.NumberOfPeople = item.values.join(', ');
          break;

        case 'metadata-reviewed-by':
          processedMetadata.Descriptive.MetadataReviewedBy =
            item.values.join(', ');
          break;
        case 'ethnicity':
          processedMetadata.Descriptive.Ethnicity = item.values.join(', ');
          break;
        case 'gender':
          processedMetadata.Descriptive.Gender = item.values.join(', ');
          break;
        case 'disability':
          processedMetadata.Descriptive.Disability = item.values.join(', ');
          break;
        case 'metadata-status':
          processedMetadata.Descriptive.MetadataStatus = item.values.join(', ');
          break;
        case 'finalized':
          processedMetadata.Descriptive.FinalizedFlag = item.values.join(', ');
          break;
        case 'metadata-review-completed':
          processedMetadata.Descriptive.MetadataReviewCompleted =
            item.values.join(', ');
          break;
        case 'metadata-reviewed-date':
          processedMetadata.Descriptive.MetadataReviewedDate =
            item.values.join(', ');
          break;
        case 'image-color-or-bw':
          processedMetadata.Descriptive.ImageColor = item.values.join(', ');
          break;
        case 'orientation':
          processedMetadata.Descriptive.Orientation = item.values.join(', ');
          break;
        case 'image-type':
          processedMetadata.Descriptive.ImageType = item.values.join(', ');
          break;
        case 'image-characteristics':
          processedMetadata.Descriptive.CharacteristicsImage =
            item.values.join(', ');
          break;
        case 'image-subject-genre':
          processedMetadata.Descriptive.ImageSubjectGenre =
            item.values.join(', ');
          break;
        case 'video-color-or-bw':
          processedMetadata.Descriptive.ColorBWVideo = item.values.join(', ');
          break;
        case 'video-type':
          processedMetadata.Descriptive.VideoType = item.values.join(', ');
          break;
        case 'video-characteristics':
          processedMetadata.Descriptive.CharacteristicsVideo =
            item.values.join(', ');
          break;
        case 'video-subject-genre':
          processedMetadata.Descriptive.VideoSubjectGenre =
            item.values.join(', ');
          break;
        case 'video-sound':
          processedMetadata.Descriptive.VideoSound = item.values.join(', ');
          break;
        case 'audio-type':
          processedMetadata.Descriptive.AudioType = item.values.join(', ');
          break;
        case 'audio-subject-genre':
          processedMetadata.Descriptive.AudioSubjectGenre =
            item.values.join(', ');
          break;
        case 'audio-characteristics':
          processedMetadata.Descriptive.CharacteristicsAudio =
            item.values.join(', ');
          break;
        case 'media-type':
          processedMetadata.Informational.MediaType = item.values.join(', ');
          break;
        case 'asset-source-id':
          processedMetadata.Informational.SourceAssetID =
            item.values.join(', ');
          break;
        // // case 'source-filename':
        // //   metadata.Informational.SourceFilename = metadata.Informational.Filename = item.value;
        // //   break;
        case 'asset-creator':
          processedMetadata.Informational.AssetCreator = item.values.join(', ');
          break;
        case 'retail-collection':
          processedMetadata.Informational.RetailCollection =
            item.values.join(', ');
          break;
        case 'volume-label':
          processedMetadata.Informational.VolumeLabel = item.values.join(', ');
          break;
        case 'artistic-collection':
          processedMetadata.Informational.ArtisticCollection =
            item.values.join(', ');
          break;
        // case 'provider-bu':
        //       processedMetadata.Informational.ProviderBu = item.values.join(', ');
        //   break;

        case 'language':
          processedMetadata.Informational.Language = item.values.join(', ');
          break;
        case 'date-imported':
          processedMetadata.Informational.DateImported = item.values.join(', ');
          break;
        case 'imported-by':
          processedMetadata.Informational.ImportedBy = item.values.join(', ');
          break;
        case 'last-modified-date':
          processedMetadata.Informational.LastModifiedDate =
            item.values.join(', ');
          break;
        case 'last-modified-by':
          processedMetadata.Informational.LastModifiedBy =
            item.values.join(', ');
          break;
        case 'metadata-last-modified-by':
          processedMetadata.Informational.MetadataLastModifiedBy =
            item.values.join(', ');
          break;
        case 'adaptation-rights':
          processedMetadata.Rights.AdaptationRights = item.values.join(', ');
          break;

        case 'asset-source':
          processedMetadata.Rights.AssetSource = item.values.join(', ');
          break;
        case 'notes':
          processedMetadata.Rights.Notes = item.values.join(', ');
          break;
        case 'rights-category':
          processedMetadata.Rights.RightsCategory = item.values.join(', ');
          break;
        case 'copyright-holder':
          processedMetadata.Rights.CopyrightHolder = item.values.join(', ');
          break;
        case 'displayed-credit-required':
          processedMetadata.Rights.DisplayedCreditRequired =
            item.values.join(', ');
          break;
        case 'credit-text':
          processedMetadata.Rights.CreditText = item.values.join(', ');
          break;
        case 'extended-credit-required':
          processedMetadata.Rights.ExtendedCreditRequired =
            item.values.join(', ');
          break;
        case 'extended-credit-text':
          processedMetadata.Rights.ExtendedCreditText = item.values.join(', ');
          break;
        case 'location-release':
          processedMetadata.Rights.LocationRelease = item.values.join(', ');
          break;
        case 'model-release':
          processedMetadata.Rights.ModelReleaseName = item.values.join(', ');
          break;
        case 'gps-asset-status':
          processedMetadata.Rights.GpsAssetStatus = item.values.join(', ');
          break;
        case 'gps-contract-status':
          processedMetadata.Rights.GpsContractStatus = item.values.join(', ');
          break;
        case 'business-unit-rights':
          processedMetadata.Rights.BusinessUnitRights = item.values.join(', ');
          break;
        case 'distribution-rights':
          processedMetadata.Rights.DistributionRights = item.values.join(', ');
          break;
        case 'marketing-use':
          processedMetadata.Rights.MarketingUse = item.values.join(', ');
          break;
        case 'usage-restriction':
          processedMetadata.Rights.UsageRestriction = item.values.join(', ');
          break;
        case 'dal-asset-status':
          processedMetadata.Rights.DalAssetStatus = item.values.join(', ');
          break;
        case 'licensing-limitations':
          processedMetadata.Rights.LicensingLimitations =
            item.values.join(', ');
          break;
        case 'mime-type':
          processedMetadata.Technical.MimeType = item.values.join(', ');
          break;
        case 'bitmap-color-map':
          processedMetadata.Technical.BitmapColorMap = item.values.join(', ');
          break;
        case 'aspect-ratio':
          processedMetadata.Technical.AspectRatio = item.values.join(', ');
          break;
        case 'frame-rate':
          processedMetadata.Technical.FrameRate = item.values.join(', ');
          break;
        case 'audio-tracks-video':
          processedMetadata.Technical.AudioTracksVideo = item.values.join(', ');
          break;

        case 'num-video-tracks':
          processedMetadata.Technical.VideoTracksVideo = item.values.join(', ');
          break;

        case 'audio-codec-name':
          processedMetadata.Technical.AudioCodecNameVideo =
            item.values.join(', ');
          break;
        case 'audio-sample-rate':
          processedMetadata.Technical.AudioSampleRateVideo =
            item.values.join(', ');
          break;

        case 'audio-sample-size-video':
          processedMetadata.Technical.AudioSampleSizeVideo =
            item.values.join(', ');
          break;
        case 'bitrate-video':
          processedMetadata.Technical.BitRateVideo = item.values.join(', ');
          break;
        case 'bitmap-color-depth':
          processedMetadata.Technical.BitmapColorDepthImage =
            item.values.join(', ');
          break;
        case 'bitmap-height':
          processedMetadata.Technical.BitmapHeight = item.values.join(', ');
          break;
        case 'bitmap-orientation':
          processedMetadata.Technical.BitmapOrientation = item.values;
          break;
        case 'bitmap-resolution':
          processedMetadata.Technical.BitmapResolutionImage = item.values;
          break;
        case 'bitmap-width':
          processedMetadata.Technical.BitmapWidth = item.values;
          break;
        case 'audio-duration-smpte':
          processedMetadata.Technical.DurationAudio = item.values;
          break;
        case 'video-duration-smpte':
          processedMetadata.Technical.DurationVideo = item.values;
          break;
        case 'file-size':
          processedMetadata.Technical.FileSize = item.values;
          break;
        case 'frame-height':
          processedMetadata.Technical.FrameHeight = item.values;
          break;
        case 'frame-width':
          processedMetadata.Technical.FrameWidth = item.values;
          break;
        case 'video-dimension':
          processedMetadata.Technical.VideoDimension = item.values;
          break;
        case 'video-codec-name':
          processedMetadata.Technical.VideoCodecName = item.values;
          break;

        default:
          console.warn('Unrecognized metadata_type:', item.metadata_type.key);
      }
    });

    return processedMetadata;
  }
  saveData() {
    this.assetService.saveAssetDetails(this.assetDetails).subscribe();
  }

  toogleMetdataView() {
    this.isMetadataEdit = !this.isMetadataEdit;
    //delete unchecked values of multi valued field to be added on save submit
    if (this.isMetadataEdit) {
      this.childComponent.deleteUncheckedItems();
      window.location.reload();
    }
  }
}
