<div class="paginate-section bg-secondary text-xs text-white grid grid-cols-3 items-center flex">
  <div class="grid-cols-1">
    SORT BY:
    <mat-form-field appearance="outline" disableOptionCentering class="w-[120px]">
      <mat-select (valueChange)="onSortChange($event)">
        @for (option of sortBy; track option) {
        <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="grid-cols-1 " ></div>
  <div class="grid-cols-1 justify-end flex ">
    {{ total || '192' }} ASSETS
    <span>
      <i class="bi bi-chevron-left mr-1" (click)="onPageChange(-1)"></i>
    </span>
    <input class="rounded w-[30px] pl-2 text-black"  type="number" placeholder="0" />
    <span class="mx-[10px]">
      of {{total}}
      <i class="bi bi-chevron-right" (click)="onPageChange(1)"></i>
    </span>
      
  </div>
  <div *ngIf="loading" class="loading-spinner">
    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>

</div>
<div *ngIf="!loading">
<div class="main ">
  @for (data of dataSource; track data) {
  <div class="m-5 container">
    <div class="flex">
      <div class="image p-2">
        <img src="{{ data.filename }}" alt="Image" class="test" />
        <div class="icons pt-2">
          <a href="#">
            <mat-icon
              svgIcon="add-circle"
              aria-hidden="false"
              aria-label="Add"
            ></mat-icon>
          </a>
          <a [routerLink]="['/edit', data.dalUniqueId]">
            <mat-icon
              svgIcon="edit-pencil"
              aria-hidden="false"
              aria-label="Edit"
            ></mat-icon>
          </a>
          <a href="#">
            <mat-icon
              svgIcon="attach"
              aria-hidden="false"
              aria-label="attachment"
            ></mat-icon>
          </a>
          <a href="#">
            <mat-icon
              svgIcon="transcode"
              aria-hidden="false"
              aria-label="Transcode"
            ></mat-icon>
          </a>
          <a href="#">
            <mat-icon
              svgIcon="trash"
              aria-hidden="false"
              aria-label="Trash"
            ></mat-icon>
          </a>
        </div>
      </div>
      <div class="content">
        <p class="text-sub">FILENAME</p>
        <p class="text">{{ data.filename }}</p>
        <p class="text-sub pt-0.5">GPS ID</p>
        <p class="text">{{ data.gpsUniqueId }}</p>
        <p class="text-sub pt-0.5">DAL ID</p>
        <p class="text">{{ data.dalUniqueId }}</p>
        <p class="text-sub pt-0.5">TITLE</p>
        <p class="text">{{ data.title }}</p>
        <p class="text-sub pt-0.5">ASSET SOURCE</p>
        <p class="text">{{ data.assetSource }}</p>
        <p class="text-sub pt-0.5">RIGHTS CATEGORY</p>
        <p class="text">{{ data.rightsCategory }}</p>
        <p class="text-sub pt-0.5">FINALIZED FLAG</p>
        <p class="text">{{ data.finalizedFlag }}</p>
        <p class="text-sub pt-0.5">USUAGE RESTRICTIONS</p>
        <p class="text">{{ data.usageRestrictions }}</p>
      </div>
    </div>
  </div>
  }
</div>
<div *ngIf="dataSource.length === 0" class="no-results">
  No Results Found
</div>
</div>
