import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TABLE_DATA } from './activityQueuePage.constants';
import { ActivityQueueDialogComponent } from '../activityQueueDialog/activityQueueDialog.component';
import { SseService } from '../../services/sse.service';
import { Subscription } from 'rxjs';
import { JobsService } from '@dal/core';

@Component({
  selector: 'dal-activity-queue-page',
  templateUrl: './activityQueuePage.component.html',
  styleUrl: './activityQueuePage.component.scss',
})

export class ActivityQueuePageComponent implements OnInit, OnDestroy {
  
  constructor( public dialog: MatDialog, public _jobs: JobsService, private _sse: SseService) {}
  
  data: number = 137;
  quo: number = (this.data % 10 > 0) ? 1 : 0;
  pages: number = Math.floor(this.data/10) + this.quo;
  element: any
  title: string = 'Activities Queue';
  element2: any
  jobSteps: any = [];
  subscription: Subscription;
  currentPage: number = 1;
  dialogLink = true;
  options: string[] = ['Select','One', 'Two', 'Three'];
  displayedFilters: string[] = ['User', 'Status', 'Type'];
  displayedColumns: string[] = ['type', 'thumbnails', 'status', 'user', 'start_time', 'end_time'];
  dataSource = TABLE_DATA;

  ngOnInit(): void {
    this._jobs.getJobs().subscribe(response => {
      // update the table data with the response
        // this.updateTableData(response);
      }, error => {
        console.error('Error:', error);
      }
    );

    this.subscription = this._sse.getJobStepsStream().subscribe(
      data => {
        this.jobSteps = data;
        this.updateTableData(data);
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateTableData(newJobSteps: any[]): void {
    // Iterate over the new job steps to update the existing data source
    newJobSteps.forEach((newJobStep, index) => {
      // Update the status field of the corresponding item in dataSource
      if (this.dataSource[index]) {
        this.dataSource[index].status = newJobStep.status;
      }
    });
  
    // Update the reference to trigger change detection
    this.dataSource = [...this.dataSource];
  }

  getData(page: number) {
    this.currentPage = page;
  }

  openDialog(row: number) {
    this.dialog.open(ActivityQueueDialogComponent, {
      data: row
    });

    //handle dialog close

  }
}
