<h1 class="home-heading  text-[24px] text-primary mx-[4%] mt-[3%]">Upload</h1>
<!-- Search Component -->
<dal-shared-ui-drop-down [templates]="templates$ | async" (TemplateEvent)="onSelection($event)"></dal-shared-ui-drop-down>

<!-- Drag and Drop -->
<div class=" w-full p-[4%] h-auto ">
    <dal-shared-ui-dragdropfile [files]="files" [duplicateFiles]="duplicateFiles" (uploadEvent)="onUpload($event)" [role]="role"></dal-shared-ui-dragdropfile>
</div>

<!-- Button  -->
<div class="flex flex-row-reverse my-[2%] px-[4%] ">
  <button  class="button  bg-primary w-[8rem] h-[2.5em] border-[1px] border-none text-white font-[2px] text-[1rem]  rounded-3xl ml-[1%] shadow-0 cursor-pointer  focus:ring focus:ring-violet-300 disabled-link"  (click)="onSubmit()" [disabled]="disableSubmit()">UPLOAD</button>
  <button class="button bg-white w-[8rem] h-[2.5em] border-[1px] rounded-3xl text-primary text-[1em] border-solid cursor-pointer" (click)="onCancel()">CLOSE</button>
</div>