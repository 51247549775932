export const filters = [
  {
    title: 'Media Type',
    inputs: [
      { name: 'Image', value: false },
      { name: 'Audio', value: false },
      { name: 'Video', value: false },
    ],
  },
  {
    title: 'Asset Type',
    inputs: [
      { name: 'Photograph', value: false },
      { name: 'Creative Art(Illustration)', value: false },
      { name: 'Film / Video', value: false },
      { name: 'Narration', value: false },
      { name: 'Technical Art', value: false },
      { name: 'Music', value: false },
      { name: 'Sounds', value: false },
      { name: 'Animation', value: false },
      { name: 'Clip Art', value: false },
      { name: 'Document', value: false },
      { name: 'Source Files', value: false },
      { name: 'Mechanical Drawing', value: false },
      { name: 'Screenshot', value: false },
      { name: 'Combination', value: false },
      { name: 'Master Art / Design elements', value: false },
    ],
  },
  {
    title: 'Rights Category',
    inputs: [
      { name: 'Public Domain', value: false },
      { name: 'Rights Managed', value: false },
      { name: 'Royalty Free', value: false },
      { name: 'Wholly Owned', value: false },
    ],
  },
  {
    title: 'Asset Source',
    inputs: [
      { name: '123RF', value: false },
      { name: 'Alamy Images', value: false },
      { name: 'Getty Images', value: false },
      { name: 'Library of Congress', value: false },
      { name: 'McGraw - Hill Education', value: false },
      { name: 'Metropolitan Museum of Art', value: false },
      {
        name: 'National Aeronautics and Space Administration(NASA)',
        value: false,
      },
      { name: 'Shutterstock', value: false },
    ],
  },
  {
    title: 'Provider BU',
    inputs: [
      { name: 'APAC', value: false },
      { name: 'Canada', value: false },
      { name: 'EMEA', value: false },
      { name: 'India', value: false },
      { name: 'HPI - ALL MHHE', value: false },
      { name: 'HPI - McGraw - Hill Professional', value: false },
      { name: 'LATAM', value: false },
      { name: 'McGraw - Hill Education', value: false },
      { name: 'SEG - ALL', value: false },
    ],
  },
  {
    title: 'Business Unit Rights',
    inputs: [
      { name: 'A & R - CTB', value: false },
      { name: 'A & R - Grow Network', value: false },
      { name: 'SEG - ALL', value: false },
      { name: 'SEG - Glencoe', value: false },
      { name: 'SEG - Macmillan', value: false },
      { name: 'SEG - SRA', value: false },
      { name: 'SEG - Wright Group', value: false },
      { name: 'HPI - MHHE B & E', value: false },
      { name: 'HPI - MHHE Career Education', value: false },
      { name: 'HPI - MHHE CLS', value: false },
      { name: 'HPI - MHHE Digital', value: false },
      { name: 'HPI - MHHE ESL', value: false },
      { name: 'HPI - MHHE HSSL', value: false },
      { name: 'HPI - MHHE SEM', value: false },
      { name: 'MHHE USA', value: false },
      { name: 'HPI - IPG Asia', value: false },
      { name: 'HPI - IPG Australia', value: false },
      { name: 'HPI - IPG India', value: false },
      { name: 'HPI - IPG Italy', value: false },
      { name: 'HPI - IPG Mexico', value: false },
      { name: 'HPI - IPG Spain', value: false },
      { name: 'HPI - IPG United Kingdom', value: false },
      { name: 'HPI - McGraw - Hill Professional', value: false },
      { name: 'MHE USA', value: false },
      { name: 'MHE Canada', value: false },
      { name: 'MHE North AmericaMHE World', value: false },
      { name: 'MHE World', value: false },
    ],
  },
  {
    title: 'Adaptation Rights',
    inputs: [
      { name: 'Yes', value: false },
      { name: 'No', value: false },
    ],
  },
  {
    title: 'Usage Restrictions',
    inputs: [
      { name: 'Additional Restrictions', value: false },
      { name: 'Editorial Use Only', value: false },
      { name: 'Legal Reviewed Required', value: false },
      { name: 'Use in instructor’s website prohibited', value: false },
      { name: 'See Notes field', value: false },
      { name: 'Media restrictions', value: false },
      { name: 'Alteration restrictions', value: false },
      { name: 'No warranty', value: false },
      { name: 'No indemnification', value: false },
    ],
  },
  {
    title: 'Marketing Use',
    inputs: [
      {
        name: 'Product Marketing Only',
        value: false,
      },
      { name: 'Broad marketing allowed', value: false },
    ],
  },
  { title: 'Model Release', inputs: [] },
  { title: 'Location Release', inputs: [] },
  { title: 'DAL Asset Status', inputs: [] },
  { title: 'Licensing Limitations', inputs: [] },
  {
    title: 'Finalized Flag',
    inputs: [
      { name: 'Yes', value: false },
      { name: 'No', value: false },
    ],
  },
  {
    title: 'Metadata Status',
    inputs: [
      { name: 'Incomplete', value: false },
      { name: 'Complete', value: false },
    ],
  },
  {
    title: 'GPS Asset Status',
    inputs: [
      { name: 'Yes', value: false },
      { name: 'No', value: false },
    ],
  },
  { title: 'GPS Contract Status', inputs: [] },
  { title: 'Metadata Review Completed', inputs: [] },
  {
    title: 'Metadata Reviewed By',
    inputs: [
      { name: 'Archivist, DAL', value: false },
      { name: 'Archivist, Legacy', value: false },
      { name: 'Baker, Craig', value: false },
      { name: 'Carter, Divine', value: false },
      { name: 'Dierker, Mark', value: false },
      { name: 'Eudaley, Candace', value: false },
      { name: 'Fink-Green, Laurie', value: false },
      { name: 'Flanagan, Tina', value: false },
      { name: 'GRIFFIN, JULIE', value: false },
      { name: 'Hadley, Rob', value: false },
      { name: 'Henriques, Chivonne', value: false },
      { name: 'Hoppmann, Anna', value: false },
      { name: 'Joseph, Alben', value: false },
      { name: 'Kamal, Ayush', value: false },
      { name: 'Kaur, Prabnit', value: false },
      { name: 'May, Kitty', value: false },
      { name: 'Mills, Margaret', value: false },
      { name: 'Planchet, David', value: false },
      { name: 'Pusti, Shuvendu', value: false },
      { name: 'Rajan, Preethi', value: false },
      { name: 'Rapp, Christine', value: false },
      { name: 'Richardson, Margaret', value: false },
      { name: 'Sellers, Bill', value: false },
      { name: 'Steinmetz, Mark', value: false },
      { name: 'Walker, Christine', value: false },
    ],
  },
  { title: 'Distribution Rights', inputs: [] },
  {
    title: 'Gender',
    inputs: [
      { name: 'Female', value: false },
      { name: 'Male', value: false },
      { name: 'Mixed', value: false },
      { name: 'Unknown', value: false },
    ],
  },
  {
    title: 'Ethnicity',
    inputs: [
      { name: 'African-American', value: false },
      { name: 'Asian/Pacific Islander', value: false },
      { name: 'Hispanic/Latino', value: false },
      { name: 'Mixed Race Person', value: false },
      { name: 'Middle Eastern', value: false },
      { name: 'Native American Indian', value: false },
      { name: 'South Asian/Indian', value: false },
      { name: 'White/Caucasian', value: false },
      { name: 'Mixed group', value: false },
    ],
  },
  {
    title: 'Number of People',
    inputs: [
      { name: 'Nobody', value: false },
      { name: 'Individuals', value: false },
      { name: 'Two People', value: false },
      { name: 'Three People', value: false },
      { name: 'Four People', value: false },
      { name: 'Five People', value: false },
      { name: 'Six People', value: false },
      { name: 'Seven People', value: false },
      { name: 'Eight People', value: false },
      { name: 'Nine People', value: false },
      { name: 'Ten People', value: false },
      { name: 'Medium group (11-15)', value: false },
      { name: 'Large group (16-24)', value: false },
      { name: 'Crowds (25+)', value: false },
    ],
  },
  {
    title: 'Age Group',
    inputs: [
      { name: 'Infant', value: false },
      { name: 'Child', value: false },
      { name: 'Adolescent', value: false },
      { name: 'Adult', value: false },
      { name: 'Senior', value: false },
      { name: 'Mixed ages', value: false },
    ],
  },
  {
    title: 'Special Needs(Disability)',
    inputs: [
      { name: 'Yes', value: false },
      { name: 'No', value: false },
    ],
  },
  {
    title: 'Genre',
    inputs: [
      { name: 'Abstraction', value: false },
      { name: 'Agriculture', value: false },
      { name: 'Animals', value: false },
      { name: 'Aquatic Life', value: false },
      { name: 'Architecture', value: false },
      { name: 'Business & Occupations', value: false },
      { name: 'Caricature', value: false },
      { name: 'Cartoon & Comic Strip', value: false },
      { name: 'Education', value: false },
      { name: 'Educational & Instructional', value: false },
      { name: 'Environmental Impact', value: false },
      { name: 'Fine Art', value: false },
      { name: 'Food & Drink', value: false },
      { name: 'Historical & Archival', value: false },
      { name: 'Industry & Manufacturing', value: false },
      { name: 'Landscape', value: false },
      { name: 'Law & Law Enforcement', value: false },
      { name: 'Lifestyle', value: false },
      { name: 'Map & Cartography', value: false },
      { name: 'Medical & Anatomical', value: false },
      { name: 'Nature', value: false },
      { name: 'News & Photojournalism', value: false },
      { name: 'Performing Arts', value: false },
      { name: 'Politics & Government', value: false },
      { name: 'Portrait', value: false },
      { name: 'Religion & Spirituality', value: false },
      { name: 'Science', value: false },
      { name: 'Social Issues', value: false },
      { name: 'Sports & Recreation', value: false },
      { name: 'Still Life', value: false },
      { name: 'Symbols & Icons', value: false },
      { name: 'Technology', value: false },
      { name: 'Transportation', value: false },
      { name: 'Travel & Geography', value: false },
      { name: 'Visual Metaphor', value: false },
    ],
  },
  {
    title: 'Characteristics',
    inputs: [
      { name: '360', value: false },
      { name: '3D asset', value: false },
      { name: 'Aerial/Satellite', value: false },
      { name: 'AI Generated', value: false },
      { name: 'Background', value: false },
      { name: 'Close-up/Detail', value: false },
      { name: 'Composite/Manipulation', value: false },
      { name: 'Copy Space', value: false },
      { name: 'Cut Out', value: false },
      { name: 'Icons and design elements', value: false },
      { name: 'Infographic', value: false },
      { name: 'Interactive', value: false },
      { name: 'Line Art', value: false },
      { name: 'Macro/Micrograph', value: false },
      { name: 'Panorama', value: false },
      { name: 'Silhouette/Backlit', value: false },
      { name: 'Texture', value: false },
    ],
  },
  {
    title: 'Video Sound',
    inputs: [
      { name: 'Yes', value: false },
      { name: 'No', value: false },
    ],
  },
  {
    title: 'Orientation',
    inputs: [
      { name: 'Horizontal', value: false },
      { name: 'Vertical', value: false },
      { name: 'Square', value: false },
      { name: 'Panoramic', value: false },
    ],
  },
  {
    title: 'Color / B & W',
    inputs: [
      { name: 'Color', value: false },
      { name: 'B&W', value: false },
    ],
  },
  // { title: "Language", inputs: [] },
  { title: 'Retail Collection', inputs: [] },
  { title: 'Volume Label', inputs: [] },
  { title: 'Artistic Collection', inputs: [] },
  { title: 'Imported By', inputs: [] },
  { title: 'Displayed Credit Required', inputs: [] },
  { title: 'Extended Credit Required', inputs: [] },
  { title: 'Video Dimensions', inputs: [] },
];
