export const AssetType = {
  1: 'Image',
  2: 'Video',
  3: 'Audio',
  4: 'Application',
  5: 'Other'
}

export enum AssetTypeId {
  Image = 1,
  Video = 2,
  Audio = 3,
  Application = 4,
  Other = 5
}