<div class="container">
  <h2 class="heading text-[24px] text-primary mx-[4%] mt-[3%]">
    Margaret Richardson's Information
  </h2>

  <div
    class="buttons mt-4"
    style="display: flex; justify-content: flex-end; width: 100%"
  >
    <button
      class="bg-white w-[8rem] h-[1.9rem] border-[1px] border-primary rounded-3xl text-primary text-[0.8em] border-solid cursor-pointer mr-2"
      (click)="onDeleteUser()"
    >
      DELETE USER
    </button>
  </div>

  <form [formGroup]="userForm" (ngSubmit)="onSave()">
    <div class="basic-info-container">
      <h3 class="text-[18px] text-primary ml-[4%] font-extrabold">
        BASIC INFO
      </h3>

      <div class="row">
        <div class="col">
          <label for="firstName"
            >First Name
            <span class="text-red-500">*</span>
          </label>
        </div>
        <div class="col">
          <label for="lastName"
            >Last Name
            <span class="text-red-500">*</span>
          </label>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="form-group pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300">
            <input
              id="firstName"
              class="form-control text-[16px] text-black p-1 h-[2rem] w-full box-border"
              formControlName="firstName"
              type="text"
              placeholder="First Name"
            />
          </div>
          @if (userForm.get('firstName')?.invalid && userForm.get('firstName')?.touched) {
            <div class="text-red-500 mt-1">Please enter First Name.</div>
          }
        </div>
        <div class="col">
          <div class="form-group pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300">
            <input
              id="lastName"
              class="form-control text-[16px] text-black p-1 h-[2rem] w-full box-border"
              formControlName="lastName"
              type="text"
              placeholder="Last Name"
            />
          </div>
          @if (userForm.get('lastName')?.invalid && userForm.get('lastName')?.touched) {
            <div class="text-red-500 mt-1">Please enter Last Name.</div>
          }
        </div>
      </div>
      
      <div class="row">
        <div class="col">
          <label for="email">
            Email Address <span class="text-red-500">*</span>
          </label>
        </div>
      </div>
      
      <div class="row">
        <div class="col">
          <div class="form-group pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300">
            <input
              id="email"
              class="form-control text-[16px] text-black p-1 h-[2rem] w-full box-border"
              formControlName="email"
              type="email"
              placeholder="Email Address"
            />
          </div>
          @if (userForm.get('email')?.invalid && userForm.get('email')?.touched) {
            <div class="text-red-500 mt-1">Please enter Email Address.</div>
          }
        </div>
      </div>
      
      <div class="form-group">
        <label>Status </label>
        <div class="row">
          <div class="col">
            <label class="mr-4">
              <input type="radio" formControlName="status" value="Enabled" />
              Enabled
            </label>
            <label class="ml-4">
              <input type="radio" formControlName="status" value="Disabled" />
              Disabled
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="business-units-container">
      <h3 class="text-[18px] text-primary ml-[4%] font-extrabold">
        BUSINESS UNIT AND ROLES
      </h3>

      <div class="row">
        <div class="col">
          <label for="businessUnit">Business Unit </label>
        </div>
        <div class="col">
          <label for="role">Role </label>
        </div>
      </div>
      <div formArrayName="businessUnitsRoles">

     @for(unitRole of businessUnitsRoles.controls; track unitRole;let i = $index){
     <div  [formGroupName]="i"
     class="form-group">
          <div class="row">
            <div class="col">
              <select
              formControlName="businessUnit"
              class="form-control pl-4 pr-4 py-2 w-[80%] border-2 border-solid rounded-full focus:outline-none focus:border-blue-500 border-gray-300"
              (change)="onBusinessUnitChange(i)"
            >

            @for(unit of businessUnits; track unit){
              <option>
                {{ unit }}
              </option>
            }
            </select>
            
            </div>
            <div class="col">
              <select
                formControlName="role"
                class="form-control pl-4 pr-4 py-2 w-[80%] border-2 border-solid rounded-full focus:outline-none focus:border-blue-500 border-gray-300"
                [disabled]="getAvailableRoles(i).length === 0"
                >

                @for(role of getAvailableRoles(i); track role){
                <option>
                  {{ role }}
                </option>
              }
              </select>
            </div>
            <div class="col-auto flex items-center">
              @if (businessUnitsRoles.length > 1) {
              <button
                type="button"
                class="text-red-500 hover:text-red-700 focus:outline-none"
                (click)="removeBusinessUnitRole(i)"
              >
                <i class="fa fa-trash fa-lg"></i>
              </button>
              }
            </div>
          </div>
        </div>
      }
      </div>
      <div class="form-group mt-2">
        <button
          class="button bg-primary w-[8rem] h-[2.5em] border-[1px] border-none text-white font-[2px] text-[0.8rem] rounded-3xl mx-[1%] shadow-0 cursor-pointer"
          (click)="addBusinessUnitRole()"
          type="button"
        >
          Add
        </button>
      </div>
    </div>
<div class="buttons mt-4 flex justify-end w-full">
  <button
    class="bg-white w-[8rem] h-[1.9rem] border-[1px] border-primary rounded-3xl text-primary text-[0.8em] border-solid cursor-pointer mr-2"
    (click)="onDeleteUser()"
  >
    CANCEL
  </button>
  <button
    class="bg-primary w-[8rem] h-[1.9rem] border-[1px] border-primary text-white text-[0.8rem] rounded-3xl cursor-pointer"
  >
    SAVE
  </button>
</div>
</form>
</div>
