import { Component, OnInit } from '@angular/core';
import { filters } from './search-filter.constants';
import { SearchStateService } from '../../services/search-state.service';
import { combineLatest } from 'rxjs';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'dal-search-result',
  templateUrl: './search-result.component.html',
  styleUrl: './search-result.component.scss',
})
export class SearchResultComponent implements OnInit {
  filters = filters;
  showFilters = false;
  searchTerm: string = '';
  termFilters: { [key: string]: string[] } = {};
  loading: boolean;
  dataSource = [];

  constructor(
    private searchStateService: SearchStateService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.searchStateService.searchTerm$,
      this.searchStateService.filterTerm$,
    ]).subscribe(([searchTerm, filterTerm]) => {
      this.searchTerm = this.combineTerms(searchTerm, filterTerm);
      this.onSearch();
    });
    this.loadAllAssets();
  }

  onCheckedFilters(checkedFilters: any[]): void {
    this.termFilters =
      this.transformCheckedFiltersToTermFilters(checkedFilters);
    this.onSearch();
  }

  transformCheckedFiltersToTermFilters(checkedFilters: any[]): {
    [key: string]: string[];
  } {
    const termFilters: { [key: string]: string[] } = {};
    checkedFilters.forEach((filter) => {
      const fieldName = this.getFilterFieldName(filter.title);
      if (!termFilters[fieldName]) {
        termFilters[fieldName] = [];
      }
      termFilters[fieldName].push(...filter.inputs);
    });
    return termFilters;
  }

  getFilterFieldName(filterTitle: string): string {
    const filterMapping: { [key: string]: string } = {
      'Media Type': 'model',
      'Asset Type': 'assetType',
      'Rights Category': 'rightsCategory',
      'Asset Source': 'assetSource',
      'Provider BU': 'providerBu',
      'Business Unit Rights': 'businessUnitRights',
      'Adaptation Rights': 'adaptationRights',
      'Usage Restrictions': 'usageRestrictions',
      'Marketing Use': 'marketingUse',
      'Model Release': 'modelRelease',
      'Location Release': 'locationRelease',
      'DAL Asset Status': 'dalAssetStatus',
      'Licensing Limitations': 'licensingLimitations',
      'Finalized Flag': 'finalizedFlag',
      'Metadata Status': 'metadataStatus',
      'GPS Asset Status': 'gpsAssetStatus',
      'GPS Contract Status': 'gpsContractStatus',
      'Metadata Review Completed': 'metadataReviewCompleted',
      'Metadata Reviewed By': 'metadataReviewedBy',
      'Distribution Rights': 'distributionRights',
      Gender: 'gender',
      Ethnicity: 'ethnicity',
      'Number of People': 'numberOfPeople',
      'Age Group': 'ageGroup',
      'Special Needs(Disability)': 'specialNeeds',
      Genre: 'genre',
      Characteristics: 'characteristics',
      'Video Sound': 'videoSound',
      Orientation: 'orientation',
      'Color / B & W': 'colorBw',
      'Retail Collection': 'retailCollection',
      'Volume Label': 'volumeLabel',
      'Artistic Collection': 'artisticCollection',
      'Imported By': 'importedBy',
      'Displayed Credit Required': 'displayedCreditRequired',
      'Extended Credit Required': 'extendedCreditRequired',
      'Video Dimensions': 'videoDimensions',
    };
    return filterMapping[filterTitle] || filterTitle;
  }

  combineTerms(searchTerm = '', filterTerm = ''): string {
    return `${searchTerm} ${filterTerm}`.trim();
  }

  onSearch(
    keyword: string = this.searchTerm,
    filters: { [key: string]: string[] } = this.termFilters
  ): void {
    this.loading = true;
    if (keyword === '' && Object.keys(filters).length === 0) {
      this.loadAllAssets();
    } else {
      this.searchService.searchByKeyword(keyword, filters).subscribe(
        (response: any) => {
          const hits = response.hits?.hits || [];
          this.dataSource = hits.map((hit: any) =>
            this.transformToPreviewData(hit._source)
          );
          this.loading = false;
        },
        (error) => {
          console.error('Error during search:', error);
          this.dataSource = [];
          this.loading = false;
        }
      );
    }
  }

  loadAllAssets(): void {
    this.loading = true;
    this.searchService.getAssets().subscribe(
      (response: any) => {
        const hits = response.hits?.hits || [];
        this.dataSource = hits.map((hit: any) =>
          this.transformToPreviewData(hit._source)
        );
        this.loading = false;
      },
      () => {
        this.dataSource = [];
        this.loading = false;
      }
    );
  }

  private transformToPreviewData(source: any): any {
    return {
      filename: source.filename,
      image: source.filename,
      gpsUniqueId: source.gpsUniqueId || '',
      dalUniqueId: source.dalUniqueId || '',
      title: source.title || '',
      assetSource: source.assetSource || '',
      rightsCategory: source.rightsCategory || '',
      finalizedFlag: source.finalizedFlag || '',
      usageRestrictions: source.usageRestrictions || '',
    };
  }

  sortChanged(event: string) {
    // handle sort change
  }
  pageChanged(event: number) {
    // handle page change change
  }
  keywordChanged(keyword: string) {
    this.searchStateService.setFilterTerm(keyword);
  }
}
