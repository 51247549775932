import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { UploadAssetService } from '../../../services/upload-asset.service';

@Component({
  selector: 'dal-assetuploadpreview',
  templateUrl: './assetuploadpreview.component.html',
  styleUrl: './assetuploadpreview.component.scss',
})

export class AssetUploadPreviewComponent implements OnInit {
  selectedAsset: number = 0;

  constructor(
    public uploadAssetService: UploadAssetService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.uploadAssetService.formInitialization()
  }

  get fileControls() {
    return (this.uploadAssetService.assetPreviewForm.get('files') as FormArray)?.controls || [];
  }

  deleteImage(index: number) {
    if (this.fileControls && this.fileControls.length > index) {
      this.fileControls.splice(index, 1);
    }
    if (this.fileControls.length === 0) {
      this.router.navigate(['/']);
    }
  }


}
