export const TABLE_DATA = [
  {type: 'Import', thumbnails: ['one.JPG', 'two.JPG'], status: 'Uploading', user: 'Margaret Richardson', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Export', thumbnails: ['two.JPG', 'three.JPG', 'four.JPG'], status: 'Uploading', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Export', thumbnails: ['one.JPG', 'four.JPG'], status: 'Successfully uploaded', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Import', thumbnails: ['four.JPG', 'five.JPG', 'one.JPG', 'two.JPG'], status: 'Successfully uploaded', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Export', thumbnails: ['five.JPG', 'two.JPG'], status: 'Failed to upload', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Import', thumbnails: ['four.JPG', 'one.JPG'], status: 'Successfully uploaded', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Export', thumbnails: ['one.JPG', 'four.JPG'], status: 'Successfully uploaded', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'BulkEdit', thumbnails: ['four.JPG', 'five.JPG', 'one.JPG', 'two.JPG'], status: 'Successfully uploaded', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Export', thumbnails: ['five.JPG', 'two.JPG'], status: 'Failed to upload', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
  {type: 'Export', thumbnails: ['four.JPG', 'one.JPG'], status: 'Successfully uploaded', user: 'Kiran', start_time: '10/26/23 12:23:01', end_time: '10/26/23 12:23:01'},
];
