import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SseService } from '../../services/sse.service';
import { forkJoin } from 'rxjs';
import { JobsService } from '@dal/core';

@Component({
  selector: 'dal-activity-queue-dialog',
  templateUrl: './activityQueueDialog.component.html',
  styleUrl: './activityQueueDialog.component.scss',
})
export class ActivityQueueDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ActivityQueueDialogComponent>, private _jobs: JobsService, @Inject(MAT_DIALOG_DATA) public data: number, private _sse: SseService) { }

  eventDetails: boolean = true
  reqRes: boolean = false
  detailedProgress: boolean = false
  logs: boolean = false
  jobSteps: any = [];
  jobDetails: any = {};
  messages: string[] = [];
  responseCode: number;
  responseMessage: string;


  ngOnInit(): void {
    this.getDetails();
  }

  getDetails() {
    const jobSteps$ = this._jobs.getJobSteps(this.data);
    const jobDetails$ = this._jobs.getJobDetails(this.data);

    forkJoin({ jobSteps: jobSteps$, jobDetails: jobDetails$ }).subscribe({
      next: ({ jobDetails, jobSteps }) => {
        this.jobSteps = jobSteps.data;
        this.jobDetails = jobDetails.data;
        this.responseCode = jobDetails.code;
        this.responseMessage = jobDetails.message;
      },
      error: (error) => {
        console.error('Job service method error:', error);
      }
    });
  }
  
  toggleDropdown(section: string) {
    if (section == "eventDetails") {
      this.eventDetails = !this.eventDetails;
    }
    else if(section == "reqRes") {
      this.reqRes = !this.reqRes;
    }
    else if(section == "detailedProgress") {
      this.detailedProgress = !this.detailedProgress;
    }
    else if(section == "logs") {
      this.logs = !this.logs;
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
