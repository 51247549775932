<div class="popup-container">
  <div class="popup-heading pl-5 pt-5">
    Details
  </div>
  <hr class="separator">
  <div class="mt-3">
    <div class="menu pl-8 pt-2" (click)="toggleDropdown('eventDetails')">
      <i
        class="fa fa-chevron-up"
        [ngClass]="{ rotate: eventDetails }"
      ></i>
      <span class="pl-2 font-bold">Event Details</span>
    </div>
    <div *ngIf="eventDetails" class="event-details">
      <div class="px-10 pt-2">
        <table *ngIf="jobDetails[0]" class="striped-table">
          <tbody>
            <tr>
              <td class="first">Date</td>
              <td class="second">{{jobDetails[0].start_time}}</td>
            </tr>
            <tr>
              <td class="first">Action</td>
              <td class="second">{{jobDetails[0].job_name}}</td>
            </tr>
            <tr>
              <td class="first">User</td>
              <td class="second">{{jobDetails[0].user}}</td>
            </tr>
            <tr>
              <td class="first">Identifier (Id)</td>
              <td class="second">To be decided</td>
            </tr>
            <tr>
              <td class="first">Job Id</td>
              <td class="second">{{jobDetails[0].job_id}}</td>
            </tr>
            <tr>
              <td class="first">Status</td>
              <td class="second">{{jobDetails[0].status}}</td>
            </tr>
            <tr>
              <td class="first">Message</td>
              <td class="second">{{jobDetails[0].message}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="menu pl-8 pt-2" (click)="toggleDropdown('reqRes')">
      <i
        class="fa fa-chevron-up"
        [ngClass]="{ rotate: reqRes }"
      ></i>
      <span class="pl-2 font-bold">Request / Response</span>
    </div>
    <div *ngIf="reqRes" class="reqRes-details">
      <div class="px-10 pt-2">
        <table class="striped-table">
          <tbody>
            <tr>
              <td class="first">Header</td>
              <td class="second">Bearer Token</td>
            </tr>
            <tr>
              <td class="first">Body</td>
              <td class="second">null</td>
            </tr>
            <tr>
              <td class="first">Response Code</td>
              <td class="second">{{responseCode}}</td>
            </tr>
            <tr>
              <td class="first">Response Message</td>
              <td class="second">{{responseMessage}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="menu pl-8 pt-2" (click)="toggleDropdown('detailedProgress')">
      <i
        class="fa fa-chevron-up"
        [ngClass]="{ rotate: detailedProgress }"
      ></i>
      <span class="pl-2 font-bold">DAL MaMA Detailed Progress</span>
    </div>
    <div *ngIf="detailedProgress" class="detailed-progress">
      <div class="px-10 pt-2">
        <table *ngIf="jobSteps[0]" class="striped-table">
          <tbody>
            <tr>
              <td class="first">Check For Content</td>
              <td class="second">{{jobSteps[1].status}}</td>
            </tr>
            <tr>
              <td class="first">Import Assets</td>
              <td class="second">{{jobSteps[0].status}}</td>
            </tr>
            <tr>
              <td class="first">Collect Transcode Assets</td>
              <td class="second">{{jobSteps[2].status}}</td>
            </tr>
            <tr>
              <td class="first">Lock Assets</td>
              <td class="second">{{jobSteps[3].status}}</td>
            </tr>
            <tr>
              <td class="first">Process Video And Audio Assets</td>
              <td class="second">{{jobSteps[4].status}}</td>
            </tr>
            <tr>
              <td class="first">Unlock Assets</td>
              <td class="second">{{jobSteps[5].status}}</td>
            </tr>
            <tr>
              <td class="first">Analyze Assets</td>
              <td class="second">{{jobSteps[6].status}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="menu pl-8 pt-2" (click)="toggleDropdown('logs')">
      <i
        class="fa fa-chevron-up"       
        [ngClass]="{ rotate: logs }"
      ></i>
      <span class="pl-2 font-bold">DAL MaMA Logs</span>
    </div>
    <div *ngIf="logs" class="log-details">
      <div class="px-10 pt-2">
        <table class="striped-table">
          <tbody>
            <tr>
              <td class="first">Date/Name/Level/Messag</td>
              <td class="second">01/24/24 2:21 PM Log message to follow here</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <hr class="separator">
  <div style="height: 50px;">
    <button class="close" (click)="closePopup()">Close</button>
  </div>
</div>