import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'dal-shared-ui-drop-down',
  templateUrl: './dropDown.component.html',
  styleUrl: './dropDown.component.scss',
})
export class DropDownComponent {

  @Input() templates: any[] | null = [];
  @Input() heading = "DAL IMPORT TEMPLATES"
  @Input() label = "Select a DAL Import Template"
  @Input() showInfo = true
  @Input() onInfoHovers = false
  @Input() infoText = "Please select themes which helps to catgories the asset."
  @Output() TemplateEvent: EventEmitter<any> = new EventEmitter();

  onSelectionChange(event: any) {
    this.TemplateEvent.emit(event)
  }

  onInfoHover() {
    this.onInfoHovers = !this.onInfoHovers
  }
}
