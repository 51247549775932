<mat-form-field style="height: 50px;" class="custom-mat-form-field my-[10px] mr-[90px] w-[100%]" >
  <mat-label class="label">{{SearchLabel}}</mat-label>
  <input matInput autoActiveFirstOption="false" [matAutocomplete]="auto" type="text" [(ngModel)]="searchText" (keydown.enter)="search()" />
  <mat-autocomplete #auto="matAutocomplete">
    @for (option of options; track option) {
      <mat-option [value]="option">{{option}}</mat-option>
    }
  </mat-autocomplete>
  @if (showIcon){
    <mat-icon class="mr-[10px]" matTextPrefix>search</mat-icon>
  }
</mat-form-field>