import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  private baseUrl: string = environment.apiBaseUrl;

  constructor(private http: HttpClient) { }


  getJobDetails(id: number) {
    return this.http.get<any>(`${this.baseUrl}/jobs/${id}`);
  }

  getJobSteps(id: number) {
    return this.http.get<any>(`${this.baseUrl}/job-steps/job/${id}`);
  }

  getJobs() {
    return this.http.get<any>(`${this.baseUrl}/jobs`);
  }
}