import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { TABLE_DATA } from './userPage.constants';

@Component({
  selector: 'dal-user-page',
  templateUrl: './userPage.component.html',
  styleUrl: './userPage.component.scss',
})
export class UserPageComponent {
  
  data: number = 137;
  quo: number = (this.data % 10 > 0) ? 1 : 0;
  pages: number = Math.floor(this.data/10) + this.quo;
  element: any
  title: string = 'Users';
  element2: any
  jobSteps: any = [];
  subscription: Subscription;
  currentPage: number = 1;
  dialogLink = true;
  options: string[] = ['Select','One', 'Two', 'Three'];
  displayedFilters: string[] = ['User', 'Business Unit', 'Role', 'Account Status'];
  displayedColumns: string[] = ['name', 'email_name', 'participant_business_unit', 'role', 'account_status', 'date_created', 'actions'];
  dataSource = TABLE_DATA;

  getData(page: number) {
    this.currentPage = page;
  }

}
