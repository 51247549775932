import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { OktaAuthService } from '../services/okta-auth.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private oktaAuthService: OktaAuthService) { }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('ErrorInterceptor:', error);

        if (error.status === 401) {
          console.log('Unauthorized request - possibly invalid or expired token');
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Convert the Promise to an Observable
    return from(this.oktaAuthService.isAuthenticated()).pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          // If the user is not authenticated, redirect to login
          this.oktaAuthService.login()
          return throwError(() => new HttpErrorResponse({ error: 'Not authenticated', status: 401 }));
        } else {
          // If authenticated, retry the request
          return next.handle(req);
        }
      })
    );
  }
}
