import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { UploadAssetService } from '../../services/upload-asset.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { map, Observable } from 'rxjs';
import { TemplateDetails } from '../../models/asset.metadata.model';
import { environment, MetadataService } from '@dal/core';
import { DialogComponent, DropDownComponent } from '@dal/shared-ui'

@Component({
  selector: 'dal-upload-page',
  templateUrl: './uploadPage.component.html',
  styleUrl: './uploadPage.component.scss',
})
export class UploadPageComponent implements OnInit {
  isFinishClicked: boolean = false;
  templates$: Observable<TemplateDetails[]>;
  heading: string;
  selectedTemplate: string;
  label: string;
  mediaType: string = this.uploadAssetService.currentTemplate?.model || '';

  @ViewChild(DropDownComponent) DropDownComponent!: DropDownComponent;

  constructor(public uploadAssetService: UploadAssetService, public dialog: MatDialog, private metadataService: MetadataService,
    private router: Router) {
  }

  
  ngOnInit() {
    this.templates$ = this.metadataService.getAllMetadataTemplates().pipe(
      map(templates => {
        return templates.map((template: TemplateDetails) => {
          return {
            ...template,
            supportedMimeTypes: template.supported_mime_types.map((type: any) => type.extension),
            model: template.supported_mime_types[0].asset_type.name
          }
        })
          .filter((template: TemplateDetails) => template.model?.toLowerCase() === this.mediaType?.toLowerCase())
      })
    );

    this.selectedTemplate = this.uploadAssetService.currentTemplate?.name || '';
    this.heading = `DAL ${this.mediaType?.toUpperCase()} TEMPLATES`;
    this.label = `Select a DAL ${this.mediaType} Template`;

    if (this.uploadAssetService.files.length === 0) {
      this.reset()
    }
  }
  checkIfFormIsValid() {
    return this.uploadAssetService.assetPreviewForm?.valid && this.uploadAssetService.files.length > 0 && this.uploadAssetService.metadataForm.valid
  }

  submit() {
    this.isFinishClicked = true;
    if (this.checkIfFormIsValid()) {
      this.uploadAssetService.uploadToS3()
        .subscribe(() => {
          this.reset();
        });
    }
  }
  // Set up the event listner
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: BeforeUnloadEvent) {
    if (environment.environmentName === 'local') {
      return;
    }
    const condition = this.uploadAssetService.files.length > 0;
    event.preventDefault();
    event.returnValue = true;
    return condition;
  }


  reset() {
    this.isFinishClicked = false
    this.uploadAssetService.currentTemplate = {} as TemplateDetails;
    this.uploadAssetService.resetFileCache();
    this.router.navigate(['/']);
  }

  onSelection(template: TemplateDetails) {
    if (!!template && this.uploadAssetService.currentTemplate.id !== template.id) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          title: 'Confirm',
          dialogContent: 'Any changes made to metadata will be lost if you change the template. Are you sure that you want to continue?',
          buttons: ["No","Yes"]
      }});
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'Yes') {
          this.updateTemplate(template);
        } else {
          this.DropDownComponent.changeTemplate(this.selectedTemplate)
        }
      });
    }
  }

  updateTemplate (template: TemplateDetails){
    this.uploadAssetService.currentTemplate = template;
    this.selectedTemplate = template.name || '';
    this.uploadAssetService.formInitialization();
  }
}
