import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'dal-loader',
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  @Input() showLoader$: Observable<number>;
}
