<div class="bg-white w-[100%] h-[113vh] overflow-scroll">
  <!-- DESCRIPTIVE -->
  <div class="mb-4">
    <div class="pl-8 pt-4" (click)="toggleDropdown('descriptive')">
      <i class="fa fa-chevron-down" [ngClass]="{ rotate: DescriptiveOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">DESCRIPTIVE</span>
    </div>
    <div *ngIf="DescriptiveOpen">
      <form *ngIf="form" [formGroup]="form" class="w-[100%] ml-[5%] mt-[2%]">
        <form [formGroup]="form">
          <div class="pl-2 mt-4">
            <label>Description</label><br />
            <textarea
              formControlName="description"
              (keyup)="editMetadataField($event, 'description')"
              class="pl-2 pt-2 border-2 border-solid rounded w-[80%] h-[90px] focus:outline-none focus:border-blue-500 border-gray-300"
            >
            </textarea>
          </div>
        </form>

        <div class="pl-2 mt-4">
          <label>Temporal/Spatial</label><br />
          <input
            formControlName="temporalSpatial"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder
            (keyup)="addMetadataArray($event, ['temporalSpatial'])"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Keywords</label><br />
          <input
            formControlName="keywords"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder
            (keyup)="editMetadataField($event, 'keywords')"
          />
        </div>
        <div class="mt-4 pl-2">
          <label>Number of People</label><br />
          <mat-select
            placeholder="numberOfPeople"
            formControlName="numberOfPeople"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(80)"
              [value]="option"
              (click)="editMetadataArray(option, ['numberOfPeople'])"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Age Group</label><br />
          <mat-select
            placeholder="Age Group"
            formControlName="ageGroup"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'ageGroup')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(34)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Ethnicity</label><br />
          <mat-select
            placeholder="Ethnicity"
            formControlName="ethnicity"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'ethnicity')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(32)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Gender</label><br />
          <mat-select
            placeholder="Gender"
            formControlName="gender"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(33)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Disability</label><br />
          <mat-select
            placeholder="disability"
            formControlName="disability"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(49)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Metadata Status</label><br />
          <mat-select
            placeholder="MetadataStatus"
            formControlName="metadataStatus"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(50)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Finalized Flag</label><br />
          <mat-select
            placeholder="finalizedFlag"
            formControlName="finalizedFlag"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(51)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Metadata reviewed by</label><br />
          <mat-select
            placeholder="MetadataReviewedBy"
            formControlName="metadataReviewedBy"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(52)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Metadata review completed</label><br />
          <mat-select
            placeholder="MetadataReviewCompleted"
            formControlName="metadataReviewCompleted"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(53)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Metadata reviewed date</label><br />
          <p class="text-gray-500">
            {{ assetDetails?.Descriptive?.MetadataReviewedDate }}
          </p>
        </div>

        <div class="mt-4 pl-2">
          <label>Orientation</label><br />
          <mat-select
            placeholder="Orientation"
            formControlName="orientation"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(37)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Image Type</label><br />
          <mat-select
            placeholder="Image Type"
            formControlName="imageType"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(38)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Characteristics Image</label><br />
          <mat-select
            placeholder="Characteristics Image"
            formControlName="characteristicsImage"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'characteristicsImage')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(39)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Color/B&W Image</label><br />
          <mat-select
            placeholder="colorBWImage"
            formControlName="colorBWImage"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(35)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Subject Image</label><br />
          <mat-select
            placeholder="subjectImage"
            formControlName="subjectImage"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'subjectImage')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(55)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Color/B&W Video</label><br />
          <mat-select
            placeholder="ColorBWVideo"
            formControlName="colorBWVideo"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(36)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Video Type</label><br />
          <mat-select
            placeholder="videoType"
            formControlName="videoType"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(40)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Characteristics Video</label><br />
          <mat-select
            placeholder="characteristicsVideo"
            formControlName="characteristicsVideo"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'characteristicsVideo')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(41)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>
        <div class="mt-4 pl-2">
          <label>Subject Video</label><br />
          <mat-select
            placeholder="subjectVideo"
            formControlName="subjectVideo"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'subjectVideo')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(56)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>
        <!-- should add values yes or no in db -->
        <div class="mt-4 pl-2">
          <label> Video Sound</label><br />
          <mat-select
            placeholder="videoSound"
            formControlName="videoSound"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(42)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <!-- add options for audio type -->
        <div class="mt-4 pl-2">
          <label>Audio Type</label><br />
          <mat-select
            placeholder="audioType"
            formControlName="audioType"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(43)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Subject Audio</label><br />
          <mat-select
            placeholder="subjectAudio"
            formControlName="subjectAudio"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'subjectAudio')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(57)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Characteristics Audio</label><br />
          <mat-select
            placeholder="characteristicsAudio"
            formControlName="characteristicsAudio"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'characteristicsAudio')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(44)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>
      </form>
    </div>
  </div>

  <!-- INFORMATIONAL -->
  <div class="mb-4">
    <div class="pl-8 pt-4" (click)="toggleDropdown('informational')">
      <i
        class="fa fa-chevron-down"
        [ngClass]="{ rotate: InformationalOpen }"
      ></i>
      <span class="pl-2 text-xl font-bold text-primary">INFORMATIONAL</span>
    </div>
    <div *ngIf="InformationalOpen">
      <form *ngIf="form" [formGroup]="form" class="w-[100%] ml-[5%] mt-[2%]">
        <div class="mt-4 pl-2">
          <label>Filename</label><br />
          <input
            formControlName="filename"
            (keyup)="editMetadataField($event, 'filename')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="filename"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>DAL ID (UID)</label><br />
          <input
            formControlName="dalID"
            (keyup)="editMetadataField($event, 'dalID')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="dalID"
            readonly="readonly"
          />
        </div>
        <div class="mt-4 pl-2">
          <label>Media Type</label><br />
          <mat-select
            placeholder="mediaType"
            formControlName="mediaType"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(45)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Source Asset ID</label><br />
          <input
            formControlName="sourceAssetID"
            (keyup)="addMetadataArray($event, ['sourceAssetID'])"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="{{ 'sourceAssetID' }}"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Source Filename</label><br />
          <input
            formControlName="sourceFilename"
            (keyup)="editMetadataField($event, 'sourceFilename')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="sourceFilename"
            readonly="readonly"
          />
        </div>
        <div class="mt-4 pl-2">
          <label>Asset Creator</label><br />
          <input
            formControlName="assetCreator"
            (keyup)="addMetadataArray($event, ['assetCreator'])"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="{{ 'assetCreator' }}"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Retail Collection</label><br />
          <input
            formControlName="retailCollection"
            (keyup)="editMetadataField($event, 'retailCollection')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="{{ 'retailCollection' }}"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Volume Label</label><br />
          <input
            formControlName="volumeLabel"
            (keyup)="editMetadataField($event, 'volumeLabel')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="{'volumeLabel'}"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Artistic Collection</label><br />
          <input
            formControlName="artisticCollection"
            (keyup)="editMetadataField($event, 'artisticCollection')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="{'artisticCollection'}"
          />
        </div>
        <!-- add values in db -->
        <div class="mt-4 pl-2">
          <label>Provider BU</label><br />
          <mat-select
            placeholder="providerBU"
            formControlName="providerBU"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'providerBU')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of ProviderBusinessDropdownValues"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Language</label><br />
          <mat-select
            placeholder="language"
            formControlName="language"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'language')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(75)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Date imported</label><br />
          <input
            formControlName="dateImported"
            (keyup)="editMetadataField($event, 'dateImported')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="dateImported"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Imported by</label><br />
          <input
            formControlName="importedBy"
            (keyup)="editMetadataField($event, 'importedBy')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="importedBy"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Last Modified Date</label><br />
          <input
            formControlName="lastModifiedDate"
            (keyup)="editMetadataField($event, 'lastModifiedDate')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="lastModifiedDate"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Last Modified by</label><br />
          <input
            formControlName="lastModifiedBy"
            (keyup)="editMetadataField($event, 'lastModifiedBy')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="lastModifiedBy"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Metadata Last Modified by</label><br />
          <input
            formControlName="lastModifiedBy"
            (keyup)="editMetadataField($event, 'metadataLastModifiedBy')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="metadataLastModifiedBy"
            readonly="readonly"
          />
        </div>
      </form>
    </div>
  </div>
  <!-- RIGHTS -->
  <div class="mb-4">
    <div class="pl-8 pt-4" (click)="toggleDropdown('rights')">
      <i class="fa fa-chevron-down" [ngClass]="{ rotate: RightsOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">RIGHTS</span>
    </div>
    <div *ngIf="RightsOpen">
      <form *ngIf="form" [formGroup]="form" class="w-[100%] ml-[5%] mt-[2%]">
        <!-- yesorno option should be added in database -->

        <div class="mt-4 pl-2">
          <label>Adaptation Rights</label><br />
          <mat-select
            placeholder="adaptationRights"
            formControlName="adaptationRights"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(48)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Asset Source</label><br />
          <mat-select
            placeholder="assetSource"
            formControlName="assetSource"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            multiple
            (selectionChange)="onOptionChange($event, 'assetSource')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of AssetSourceDropdownValues"
              [value]="option"
              (click)="editMetadataArray(option, ['assetSource'])"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Notes</label><br />
          <input
            formControlName="notes"
            (keyup)="addMetadataArray($event, ['notes'])"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="Notes"
          />
        </div>

        <div class="mt-4 pl-2">
          <label> Rights Category</label><br />
          <mat-select
            placeholder="rightCategory"
            formControlName="rightCategory"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(77)"
              [value]="option"
            >
              <p class="text-white">{{ option }}</p>
            </mat-option>
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Copyright Holder</label><br />
          <input
            formControlName="copyrightHolder"
            (keyup)="editMetadataField($event, 'copyrightHolder')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="copyrightHolder"
          />
        </div>

        <div class="mt-4 pl-2">
          <label> Displayed Credit Required</label><br />
          <mat-select
            placeholder="displayedCreditRequired"
            formControlName="displayedCreditRequired"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(78)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Credit Text</label><br />
          <input
            formControlName="creditText"
            (keyup)="editMetadataField($event, 'creditText')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="creditText"
          />
        </div>

        <div class="mt-4 pl-2">
          <label> Extended Credit Required</label><br />
          <mat-select
            placeholder="extendedCreditRequired"
            formControlName="extendedCreditRequired"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of MetadataOption?.OptionJson?.yesNoOptions"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Extended Credit Text</label><br />
          <input
            formControlName="extendedCreditText"
            (keyup)="editMetadataField($event, 'extendedCreditText')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="extendedCreditText"
          />
        </div>

        <div class="mt-4 pl-2">
          <label> Location Release</label><br />
          <mat-select
            placeholder="locationRelease"
            formControlName="locationRelease"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(64)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Model Release</label><br />
          <mat-select
            placeholder="modelRelease"
            formControlName="modelRelease"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(65)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>GPS Unique ID</label><br />
          <input
            formControlName="gps"
            (keyup)="editMetadataField($event, 'gps')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="gps"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>GPS Asset Status</label><br />
          <mat-select
            placeholder="gpsAssetStatus"
            formControlName="gpsAssetStatus"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(66)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>GPS Contract Status</label><br />
          <mat-select
            placeholder="contractStatus"
            formControlName="contractStatus"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(67)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Business Unit Rights</label><br />
          <mat-select
            placeholder="businessUnitRights"
            formControlName="businessUnitRights"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'businessUnitRights')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(68)"
              [value]="option"
              (click)="editMetadataArray(option, ['businessUnitRights'])"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Distribution Rights</label><br />
          <mat-select
            placeholder="distributionRights"
            formControlName="distributionRights"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'distributionRights')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(31)"
              [value]="option"
              (click)="editMetadataArray(option, ['distributionRights'])"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>Marketing Use</label><br />
          <mat-select
            placeholder="marketingUse"
            formControlName="marketingUse"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(46)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Usage Restrictions</label><br />
          <mat-select
            placeholder="usageRestriction"
            formControlName="usageRestriction"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'usageRestriction')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(47)"
              [value]="option"
              (click)="editMetadataArray(option, ['usageRestriction'])"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label>DAL Asset Status</label><br />
          <mat-select
            placeholder="dalAssetStatus"
            formControlName="dalAssetStatus"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(69)"
              [value]="option"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>

        <div class="mt-4 pl-2">
          <label> Licensing Limitations</label><br />
          <mat-select
            placeholder="licensingLimitations"
            formControlName="licensingLimitations"
            class="multipleSelect pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
            multiple
            (selectionChange)="onOptionChange($event, 'licensingLimitations')"
          >
            <mat-option
              class="multipleOption"
              *ngFor="let option of dropdownValuesMap.get(70)"
              [value]="option"
              (click)="editMetadataArray(option, ['licensingLimitations'])"
              ><p class="text-white">{{ option }}</p></mat-option
            >
          </mat-select>
        </div>
      </form>
    </div>
  </div>
  <!-- TECHNICAL -->
  <div class="mb-4">
    <div class="pl-8 pt-4" (click)="toggleDropdown('technical')">
      <i class="fa fa-chevron-down" [ngClass]="{ rotate: TechnicalOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">TECHNICAL</span>
    </div>
    <div *ngIf="TechnicalOpen">
      <form *ngIf="form" [formGroup]="form" class="w-[100%] ml-[5%] mt-[2%]">
        <div class="mt-4 pl-2">
          <label>MIME Type</label><br />
          <input
            formControlName="mimetype"
            (keyup)="editMetadataField($event, 'mimetype')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="mimetype"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Color Map</label><br />
          <input
            formControlName="bitmapColorMap"
            (keyup)="editMetadataField($event, 'bitmapColorMap')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="bitmapColorMap"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Aspect Ratio</label><br />
          <input
            formControlName="aspectRatio"
            (keyup)="editMetadataField($event, 'aspectRatio')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="aspectRatio"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Rate</label><br />
          <input
            formControlName="frameRate"
            (keyup)="editMetadataField($event, 'frameRate')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="frameRate"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Tracks Video</label><br />
          <input
            formControlName="audioTracksVideo"
            (keyup)="editMetadataField($event, 'audioTracksVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="audioTracksVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Codec Name Video</label><br />
          <input
            formControlName="audioCodecNameVideo"
            (keyup)="editMetadataField($event, 'audioCodecNameVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="audioCodecNameVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Sample Rate Video</label><br />
          <input
            formControlName="audioSampleRateVideo"
            (keyup)="editMetadataField($event, 'audioSampleRateVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="audioSampleRateVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Sample Size Video</label><br />
          <input
            formControlName="audioSampleSizeVideo"
            (keyup)="editMetadataField($event, 'audioSampleSizeVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="audioSampleSizeVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bit Rate Video</label><br />
          <input
            formControlName="bitRateVideo"
            (keyup)="editMetadataField($event, 'bitRateVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="bitRateVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Color Depth Image</label><br />
          <input
            formControlName="bitmapColorDepthImage"
            (keyup)="editMetadataField($event, 'bitmapColorDepthImage')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="bitmapColorDepthImage"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Height</label><br />
          <input
            formControlName="bitmapHeight"
            (keyup)="editMetadataField($event, 'bitmapHeight')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="bitmapHeight"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Orientation</label><br />
          <input
            formControlName="bitmapOrientation"
            (keyup)="editMetadataField($event, 'bitmapOrientation')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="bitmapOrientation"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Resolution Image</label><br />
          <input
            formControlName="bitmapResolutionImage"
            (keyup)="editMetadataField($event, 'bitmapResolutionImage')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="bitmapResolutionImage"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Width</label><br />
          <input
            formControlName="bitmapWidth"
            (keyup)="editMetadataField($event, 'bitmapWidth')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="bitmapWidth"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Duration (Audio)</label><br />
          <input
            formControlName="durationAudio"
            (keyup)="editMetadataField($event, 'durationAudio')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="durationAudio"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Duration (Video)</label><br />
          <input
            formControlName="durationVideo"
            (keyup)="editMetadataField($event, 'durationVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="durationVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>File Size</label><br />
          <input
            formControlName="fileSize"
            (keyup)="editMetadataField($event, 'fileSize')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300 bg-gray-200 text-black-500 cursor-not-allowed"
            placeholder="fileSize"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Height Video</label><br />
          <input
            formControlName="frameHeightVideo"
            (keyup)="editMetadataField($event, 'frameHeightVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="frameHeightVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Width Video</label><br />
          <input
            formControlName="frameWidthVideo"
            (keyup)="editMetadataField($event, 'frameWidthVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="frameWidthVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Rate Video</label><br />
          <input
            formControlName="frameRateVideo"
            (keyup)="editMetadataField($event, 'frameRateVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="frameRateVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Number of Audio Tracks Video</label><br />
          <input
            formControlName="numberofAudioTracksVideo"
            (keyup)="editMetadataField($event, 'numberofAudioTracksVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="numberofAudioTracksVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Number of Video Tracks Video</label><br />
          <input
            formControlName="numberofVideoTracksVideo"
            (keyup)="editMetadataField($event, 'numberofVideoTracksVideo')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="numberofVideoTracksVideo"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Video Codec</label><br />
          <input
            formControlName="videoCodec"
            (keyup)="editMetadataField($event, 'videoCodec')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="videoCodec"
            readonly="readonly"
          />
        </div>

        <div class="mt-4 pl-2">
          <label>Video Dimension</label><br />
          <input
            formControlName="videoDimension"
            (keyup)="editMetadataField($event, 'videoDimension')"
            class="pl-2 border-2 border-solid rounded w-[80%] focus:outline-none focus:border-blue-500 border-gray-300"
            placeholder="videoDimension"
            readonly="readonly"
          />
        </div>
      </form>
    </div>
  </div>
</div>
