export const MetadataFields = {
  FileName: 1,
  VolumeLabel: 2,
  CopyrightHolder: 3,
  CreditText: 4,
  Description: 5,
  BitmapColorMap: 6,
  AspectRatio: 7,
  FrameRate: 8,
  AudioTracksVideo: 9,
  AudioCodecName: 10,
  AudioSampleRate: 11,
  Bitrate: 12,
  BitmapColorDepth: 13,
  BitmapHeight: 14,
  BitmapWidth: 15,
  BitmapOrientation: 16,
  BitmapResolution: 17,
  AudioDuration: 18,
  VideoDuration: 19,
  DurationSMPTEAudio: 20,
  DurationSMPTEVideo: 21,
  FileSize: 22,
  FrameHeight: 23,
  FrameWidth: 24,
  NumberOfAudioTracks: 25,
  NumberOfVideoTracks: 26,
  VideoCodecName: 27,
  TemporalSpatial: 28,
  BitRateVideo: 29,
  ExtendedCreditText: 30,
  DistributionRights: 31,
  Ethnicity: 32,
  Gender: 33,
  AgeGroup: 34,
  ImageColor: 35,
  VideoColor: 36,
  Orientation: 37,
  ImageType: 38,
  CharacteristicsImage: 39,
  VideoType: 40,
  CharacteristicsVideo: 41,
  VideoSound: 42,
  AudioType: 43,
  CharacteristicsAudio: 44,
  MediaType: 45,
  MarketingUse: 46,
  UsageRestriction: 47,
  AdaptationRights: 48,
  Disability: 49,
  MetadataStatus: 50,
  FinalizedFlag: 51,
  MetadataReviewer: 52,
  MetadataReviewCompleted: 53,
  MetadataReviewDate: 54,
  ImageSubjectGenre: 55,
  VideoSubjectGenre: 56,
  AudioSubjectGenre: 57,
  AssetCreator: 58,
  RetailCollection: 59,
  ArtisticCollection: 60,
  Notes: 62,
  ExtendedCreditTextDuplicate: 63,
  LocationRelease: 64,
  ModelRelease: 65,
  GPSAssetStatus: 66,
  GPSContractStatus: 67,
  BusinessUnitRights: 68,
  DALAssetStatus: 69,
  LicensingLimitations: 70,
  MIMEType: 71,
  VideoDimension: 72,
  AudioSampleSizeVideo: 73,
  Keywords: 74,
  Language: 75,
  ExtendedCreditRequired: 76,
  RightsCategory: 77,
  DisplayedCreditRequired: 78,
  AudioSampleSizeVideoDuplicate: 79
}

