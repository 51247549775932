import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ZiraService } from '../../services/zira.service';

@Component({
  selector: 'dal-shared-ui-media-renderer',
  templateUrl: './mediaRenderer.component.html',
  styleUrl: './mediaRenderer.component.scss',
})
export class MediaRendererComponent {
  @Input() url!: string;
  @Input() type!: string;
  @Input() imagePreview!: boolean;
  @Input() applyClass: boolean = true;
  @Input() authenticateUrl: boolean = true;

  @ViewChild('audioPlayer', { static: false }) audioPlayer!: ElementRef<HTMLAudioElement>;
  @ViewChild('videoPlayer', { static: false }) videoPlayer!: ElementRef<HTMLVideoElement>;

  renditionId: string;
  originalId: string;
  inputUrl: string | null = null;
  idToFetch: string;

  constructor(private ziraService: ZiraService) {}
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['url'] && this.url) {
      if (this.authenticateUrl) {
        this.extractIdsFromUrl(this.url);
        this.idToFetch = this.renditionId || this.originalId;
        if (this.idToFetch) {
          this.ziraService.getAssetUrl(this.idToFetch).subscribe(blob => {
            const objectUrl = URL.createObjectURL(blob);
            this.inputUrl = objectUrl;
            this.updateSource();
          });
        }
      } else {
        this.inputUrl = this.url;
        this.updateSource();
      }
    }
  }
  
  private extractIdsFromUrl(url: string): void {
    const parts = url.split('/');
    if (parts.length >= 2) {
      this.renditionId = parts[parts.length - 1];
      this.originalId = parts[parts.length - 2];
    } else if (parts.length === 1) {
      this.originalId = parts[0];
    }
  }

  private updateSource(): void {
    if (this.type === 'video' && this.videoPlayer && this.videoPlayer.nativeElement) {
      this.videoPlayer.nativeElement.src = this.inputUrl!;
      this.videoPlayer.nativeElement.load();
    } else if (this.type === 'audio' && this.audioPlayer && this.audioPlayer.nativeElement) {
      this.audioPlayer.nativeElement.src = this.inputUrl!;
      this.audioPlayer.nativeElement.load();
    }
  }
}

