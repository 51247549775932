import { Component, EventEmitter, Input, Output, Renderer2, ElementRef, ChangeDetectorRef, input, OnInit } from '@angular/core';

@Component({
  selector: 'dal-shared-ui-dragdropfile',
  templateUrl: './dragdropfile.component.html',
  styleUrl: './dragdropfile.component.scss',
})
export class DragdropfileComponent implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef) { }

  @Input() files: File[] = [];
  @Input() role: string = "";
  @Input() duplicateFiles: string[] = [];
  @Input() dragDropDisabled: boolean;
  @Input() showWarning: boolean = false;
  @Input() warningMessage: string = ''; // Custom warning message
  @Input() supportedFileTypes: any[] = [];
  @Output() uploadEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  dataSource: any[];
  displayedColumns: string[] = []

  ngOnInit() {
    this.displayedColumns = ['type', 'name', 'size', 'action'];
    this.refreshTableData();
  }

  refreshTableData() {
    this.dataSource = [...this.files];  // Create a new reference to the array
    this.cdr.detectChanges();  // Manually trigger change detection
  }

  onFileDropped($event: any): void {
    $event.preventDefault();  // Prevent file from opening in new tab
    $event.stopPropagation(); // Stop it from bubbling up

    if (this.dragDropDisabled) {
      this.showWarning = true;
      this.warningMessage="Please select a metadata import template first";
    }else{
      if ($event.dataTransfer) {
        const files: FileList = $event.dataTransfer.files;
        const valid_files = this.validateFiles(files);
        if (valid_files){
          this.prepareFilesList(valid_files);
        }
      }
    }
  }

  fileBrowseHandler(event: Event): void {
    if (this.dragDropDisabled) {
      this.showWarning = true;
      this.warningMessage="Please select a metadata import template first";
    } else {
      const inputElement = event.target as HTMLInputElement;
      console.log('ss',inputElement.files);
      if (inputElement?.files) {
        const valid_files = this.validateFiles(inputElement.files);
        console.log(valid_files);
        if (valid_files){
          this.prepareFilesList(valid_files);
        }
      }
      this.showWarning = false;
    }
  }

  validateFiles(files: FileList): FileList {
    this.warningMessage = '';
    this.showWarning = false;

    const dataTransfer = new DataTransfer(); // To construct a new FileList
    const filesArray = Array.from(files); // Convert FileList to Array
    filesArray.forEach((file) => {
      const fileExt = '.' + file.name.split('.').pop()?.toLowerCase();
      const isDupfile = this.files.some(item => item.name === file.name && item.lastModified === file.lastModified);
      if (isDupfile) {
        this.showWarning = true;
        this.warningMessage = `${file.name} already selected`;
      } else if (fileExt && this.supportedFileTypes.includes(fileExt)) {
        dataTransfer.items.add(file); // Add valid file to DataTransfer
      } else {
        this.showWarning = true;
        this.warningMessage = `Only ${this.supportedFileTypes.join(', ')} files are allowed for selected template `;
      }
    });  
    return dataTransfer.files; // Final valid FileList
  }    

  prepareFilesList(files: FileList): void {
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      this.files.push(file);
    }
    this.uploadEvent.emit(this.files);
    this.refreshTableData();
  }

  deleteFile(index: number): void {
    this.deleteEvent.emit(this.files[index]);
    this.refreshTableData();
  }

  handleDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  handleDragEnter(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  showWarningIfDisabled(event?: Event) {
    if (this.dragDropDisabled) {
      this.showWarning = true;
      this.warningMessage="Please select a metadata import template first";
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  getFileAcceptTypes(): string {
    if (this.supportedFileTypes?.length > 0) {
      return this.supportedFileTypes.join(',');
    }
    return '';
  }
}
