<div class="w-[100%]">
  <div class="grid grid-cols-12  invisible md:visible py-1">
    <div class="text-xs col-span-2"></div>
    <div class="text-xs col-span-3">FILENAME</div>
    <div class="text-xs col-span-4">TITLE</div>
  </div>
  <form [formGroup]="uploadAssetService.assetPreviewForm" class="max-h">
    <div formArrayName="files">
      @for (file of fileControls; track file;let i = $index) {

      <div
        [formGroupName]="i"
        class="grid grid-cols-12 border-2 border-grey_c"
      >
        <div class="col-span-2 p-2 flex items-center">
          @if (uploadAssetService.files[i].type.startsWith('video/')) {
            <dal-shared-ui-video-upload-thumbnail [file]="uploadAssetService.files[i]"></dal-shared-ui-video-upload-thumbnail>
          } @else if (uploadAssetService.files[i].type.startsWith('audio/')) {
            <dal-shared-ui-audio-upload-thumbnail [file]="uploadAssetService.files[i]"></dal-shared-ui-audio-upload-thumbnail>
          } @else {
            <img
              appDefaultImg
              [src]="file.value.src"
              [alt]="file.value.fileName"
              class="object-fill w-full thumbnail"
            />
          }
        </div>

        <div class="col-span-9 md:col-span-3 flex flex-col justify-center">
          <div class="text-left text-xs block md:hidden">
            FILENAME
          </div>
          <div class="text-left text-xs">
            {{ file.value.fileName }}
          </div>
        </div>
         <div class="col-span-1 flex md:items-center items-start md:justify-center justify-end md:order-last">
          <span class="text-primary cursor-pointer" (click)="deleteImage(i)">
            <mat-icon class="text-base">clear</mat-icon>
          </span>
        </div>

        <div class="col-span-12 md:col-span-6 p-2 md:p-0 flex flex-col justify-start md:justify-center">
          <div class="text-left text-xs block md:hidden">
            TITLE
          </div>
          <input
            type="text"
            class="border-2 max-w-[90%] rounded
          {{
              file.get('title')?.hasError('required')
                ? 'border-red-500'
                : ''
            }}"
            formControlName="title"
          />
          @if ( file.get('title')?.hasError('required')) {
          <p class="text-red-500 text-xs">Title Cannot be Empty</p>
          }
        </div>

       
        
      </div>
      }
    </div>
  </form>
</div>
