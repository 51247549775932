<h1 class="home-heading  text-[24px] text-primary mx-[2%] mt-[2%]">Upload</h1>
<!-- Search Component -->
<dal-shared-ui-drop-down [heading]="heading" [label]="label" [selectedTemplate]="selectedTemplate" [templates]="templates$ | async" [showClearOption]="true" (TemplateEvent)="onSelection($event)"></dal-shared-ui-drop-down>

<!-- Drag and Drop -->
<div class=" w-full pl-[2%] pr-[2%] mt-[2%] h-auto ">
    <dal-shared-ui-dragdropfile [files]="uploadAssetService.files" [dragDropDisabled]="dragDropDisabled" [duplicateFiles]="duplicateFiles" [supportedFileTypes]="supportedFileTypes" (uploadEvent)="onUpload($event)" (deleteEvent)="deleteEvent($event)" [role]="role"></dal-shared-ui-dragdropfile>
</div>

<!-- Button  -->
<div class="flex flex-row-reverse sticky bottom-0 mt-[2%] px-[2%] ">
  <button  class="button  bg-primary w-[8rem] h-[2.5em] border-[1px] border-none text-white font-[2px] text-[1rem]  rounded-3xl ml-[1%] shadow-0 cursor-pointer  focus:ring focus:ring-violet-300 disabled-link"  (click)="onSubmit()" [disabled]="disableSubmit()">NEXT</button>
  <button class="button bg-white w-[8rem] h-[2.5em] border-[1px] rounded-3xl text-primary text-[1em] border-solid cursor-pointer" (click)="clear()">CLEAR</button>
</div>