<div class="container">
  <div class="media-container">
    @for (media of mediaFiles; track media) {
      @switch (getMediaType(media.url)) {
        @case('image'){
          <img [src]="media.url" alt="Your Image" />
        }
        @case('audio'){
          <div class="audio-container">
            <img
              src="/assets/rendition.jpeg"
              alt="Audio Thumbnail"
              class="audio-thumbnail"
            />
            <audio controls class="audio-player">
              <source [src]="media.path" type="audio/mp3" />
              Your browser does not support the audio tag.
            </audio>
          </div>
        }
        @case('video'){
          <video controls>
            <source [src]="media.path" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        }

        @default{
          <div>Unsupported media type</div>
        }
      }
      @if (!isPreviewRoute()){
        <div class="preview-link" (click)="openImageInNewTab(media.path)">
          <span class="preview-icon">
            <i class="fas fa-external-link-alt"></i>
          </span>
        </div>
      }
    }
  </div>
</div>
