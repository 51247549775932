import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'dal-shared-ui-activity-queue',
  templateUrl: './activityQueue.component.html',
  styleUrl: './activityQueue.component.scss',
})
export class ActivityQueueComponent {
  @Input() inProgress  = 0;
  @Input() activities = [
    { id: 262772, date: "02/14/2024", name: "Durall", status: "Upload Success", isDone: true},
    { id: 7862278, date: "02/11/2024", name: "Durall", status: "Upload Success", isDone: true },
    { id: 31235, date: "02/14/2024", name: "Marron", status: "Upload Success", isDone: true},
    { id: 262772, date: "02/14/2024", name: "Durall", status: "Upload Success", isDone: true },
    { id: 262772, date: "02/14/2024", name: "Durall", status: "Upload Success", isDone: true },
  ];

  @Output() close = new EventEmitter<void>();

  ngOnChanges() {
    this.inProgress = this.calculateInProgress();
  }

  closeModal() {
    this.close.emit();
  }

  private calculateInProgress(): number {
    return this.activities.reduce((countInProgress, activity) => {
      return countInProgress + (activity.isDone ? 0 : 1);
    }, 0);
  }
}
