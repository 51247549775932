<div class="mt-4 flex items-center justify-between">
  <span class="pl-[2%] mt-4 text-[1.5em] text-primary flex items-center" (click)="cancel()">
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="36" height="36" rx="5" stroke="#007C91"/>
      <rect x="9" y="9" width="20" height="20" fill="black" fill-opacity="0.01"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2496 10.75L14 18.9996L22.2496 27.2492L23.4281 26.0706L16.357 18.9996L23.4281 11.9285L22.2496 10.75Z" fill="#007C91"/>
    </svg>
    <span class="ml-4">UPLOAD</span>
  </span>
  <span class="mr-4 flex">
    <p class="text-primary mr-2"><span class="font-bold">Template: </span>{{uploadAssetService.currentTemplate.template_name}}</p>
  </span>
</div>
<div class="flex items-center mt-2 ml-8">
  <h1 class="text-[0.8em]">IMPORTED FILES</h1>
  <span class="ml-[1.5em] text-[0.8em]">FILENAME</span>
  <span class="ml-[11.5em] text-[0.8em]">TITLE</span>
</div>
<div class="ml-6 mr-4">
  <div class="grid grid-cols-2 border-2 border-grey-500">

    <div class="bg-gray-100">
      <dal-assetuploadpreview></dal-assetuploadpreview>
    </div>

    <div class="bg-gray-100">
      <dal-bulk-add-edit-metadata ></dal-bulk-add-edit-metadata>
    </div>

  </div>

  @if (uploadAssetService.assetPreviewForm.invalid && this.isFinishClicked ) {
    <p class="text-red-500 text-right"> All Asset is not valid. Please check the highlighted area.</p>
  }
  <div class="flex flex-row-reverse my-[2%]">
    <button
      class="button bg-primary w-[8rem] h-[2.5em] border-[1px] border-none text-white font-[2px] text-[1rem] rounded-3xl mx-[1%] shadow-0 cursor-pointer focus:ring focus:ring-violet-300 
      disabled-link"
      [disabled]="!checkIfFormIsValid()"
      (click)="submit()"
    >
      FINISH
    </button>
    <button
      class="button bg-white w-[8rem] h-[2.5em] border-[1px] rounded-3xl text-primary text-[1em] border-solid cursor-pointer"
      (click)="cancel()"
    >
      CLOSE
    </button>
  </div>
</div>


