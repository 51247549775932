import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from '@dal/core';

@Injectable({
  providedIn: 'root'
})
export class ZiraService {
  baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {
  }

  getAssetUrl(assetUUID: string): Observable<Blob> {
    return this.http.get<any>(`${this.baseUrl}/abs/asset/${assetUUID}`, {
      responseType: 'blob' as 'json',
    }).pipe(
      catchError(error => {
        // Error fetching assets
        return throwError(() => error);
      })
    );
  }

  
}

