<div class="bg-white p-12">
  <!-- Heading -->
  <div class="popup-heading text-2xl font-bold text-gray-800 pb-4">
    Template Manager
  </div>

  <hr class="separator">

  <!-- Template Dropdown -->
  <mat-form-field appearance="fill" class="mt-6 w-full">
    <mat-label>Select Template</mat-label>
    <mat-select
      id="template-select"
      [(ngModel)]="selectedTemplate"
      class="w-full">
      @for (template of (templates$ | async); ; track template) {
        <mat-option [value]="template">
          {{template.name}}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <!-- Template Metadata Section -->
  @if (selectedTemplate) {
    <div class="mt-6 p-4">
      <h2 class="text-xl font-semibold text-gray-800">{{ selectedTemplate.name }}</h2>
      <p class="text-gray-600 mt-3">{{ selectedTemplate.description }}</p>

      <hr class="separator mt-3">

      <!-- Metadata Fields Table -->
      <div class="menu mt-6 mb-6" (click)="metadataFieldsVisible = !metadataFieldsVisible">
        <i class="fa fa-chevron-right transition-transform duration-300" [class.rotate-90]="metadataFieldsVisible"></i>        
        <span class="pl-2 font-bold" (click)="metadataFieldsVisible = !metadataFieldsVisible">Metadata Fields</span>
      </div>
      @if (metadataFieldsVisible) {
        <div class="px-6 mt-2">
          <div class="w-full overflow-x-auto">
            <table class="w-full striped-table border border-gray-200">
              <thead class="bg-gray-200">
                <tr>
                  <th class="py-2 px-4 border-b text-left text-gray-700 first">Metadata Name</th>
                  <th class="py-2 px-4 border-b text-left text-gray-700">Default Value</th>
                </tr>
              </thead>
              <tbody>
                @if (selectedTemplate && selectedTemplate.fields) {
                  @for (field of selectedTemplate.fields; track field; let even = $even) {
                    <tr [class.bg-white]="even" [class.bg-gray-50]="!even" class="hover:bg-gray-100">
                      <td class="py-2 px-4 border-b first">{{ field.metadata_type.name || 'N/A' }}</td>
                      <td class="py-2 px-4 border-b">{{ field.default_value }}</td>
                    </tr>
                  }
                }
              </tbody>
            </table>
          </div>
        </div>
      }

      <hr class="separator">

      <!-- Supported MIME Types Table -->
      <div class="mt-6" (click)="supportedMimeTypesVisible = !supportedMimeTypesVisible">
        <i class="fa fa-chevron-right transition-transform duration-300" [class.rotate-90]="supportedMimeTypesVisible"></i>
        <span class="pl-2 font-bold">Supported MIME Types</span>
      </div>
      @if (supportedMimeTypesVisible) {
        <div class="px-6 pt-2">
          <div class="w-full overflow-x-auto">
            <table class="w-full striped-table border border-gray-200">
              <thead class="bg-gray-200">
              <tr>
                <th class="py-2 px-4 border-b text-left text-gray-700">Extension</th>
                <th class="py-2 px-4 border-b text-left text-gray-700">Type</th>
              </tr>
              </thead>
              <tbody>
                @for (mimeType of selectedTemplate.supported_mime_types; track mimeType; let even = $even) {
                  <tr [class.bg-white]="even" [class.bg-gray-50]="!even" class="hover:bg-gray-100">
                    <td class="py-2 px-4 border-b">{{ mimeType.extension }}</td>
                    <td class="py-2 px-4 border-b">{{ mimeType.name }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
     }
    </div>
  }
</div>
