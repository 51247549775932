<h1 class="text-[24px] text-primary px-[4%] py-[2%]">Search Results</h1>
<div class="grid grid-cols-12 px-[4%]">
  <dal-shared-ui-search-filter
    [filters]="filters"
    [showFilters]="showFilters"
    (ToggleFilterSection)="showFilters = !showFilters"
    (CheckedFilters)="onCheckedFilters($event)"
    (KeywordSearch)="keywordChanged($event)"
    [ngClass]="{'filters': true, 'w-[40px] overflow-hidden': showFilters, 'col-span-2': !showFilters}"
  ></dal-shared-ui-search-filter>
  <dal-shared-ui-search-preview
    class="border-custom"
    [ngClass]="showFilters ? '-ml-[5%] col-span-11' : 'col-span-10'"
    [searchTerm]="searchTerm"
    [termFilters]="termFilters"
    [dataSource]="dataSource"
    [loading]="loading"
    [searchCount]="searchCount"

    (PageChangeEvent)="pageChanged($event)"
    (SortChangeEvent)="sortChanged($event)"

  ></dal-shared-ui-search-preview>
</div>
