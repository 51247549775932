import { Component, EventEmitter, Input, Output, Inject } from '@angular/core';

@Component({
  selector: 'dal-shared-ui-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  showSideNav = false;
  @Input() user = { name: 'User' };
  @Input() title = 'DAL | Media and Metadata Administration';
  @Input() showActivityQueue = false;
  @Output() SearchEvent: EventEmitter<string> = new EventEmitter();
  @Output() toggleSideNavEvent: EventEmitter<boolean> = new EventEmitter();

  searchText: string = '';
  search(searchText: string) {
    const trimmedText = searchText.trim();
    this.SearchEvent.emit(trimmedText);
  }

  toggleSideNav() {
    this.showSideNav = !this.showSideNav;
    this.toggleSideNavEvent.emit(this.showSideNav);
  }

  toggleActivityQueue() {
    this.showActivityQueue = !this.showActivityQueue;
  }
}
