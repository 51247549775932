import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNull } from '../../utils/string-utils'

@Component({
  selector: 'dal-shared-ui-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent {
  @Input() SearchLabel = 'Search...'
  @Input() showIcon = true;
  @Input() options: string[] = [];
  @Output() SearchEvent = new EventEmitter<string>();
  searchText: string = ""

  search() {
    if (isNull(this.searchText)){
      this.searchText = '%';
    }
    this.SearchEvent.emit(this.searchText.trim());
  }
}
