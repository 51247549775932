import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  private baseUrl = environment.apiBaseUrl
  constructor(private http: HttpClient) { }

  // Lock an asset
  lockAsset(assetID: number, assetLock: any): Observable<any> {
    const url = `${this.baseUrl}/asset/locks/${assetID}`;
    return this.http.post(url, assetLock);
  }

  // Delete an asset lock by asset ID
  deleteAssetLock(assetID: number): Observable<any> {
    const url = `${this.baseUrl}/asset/locks/${assetID}`;
    return this.http.delete(url);
  }

  // Create a new asset
  createAsset(assetData: any): Observable<any> {
    const url = `${this.baseUrl}/assets/create`;
    return this.http.post(url, assetData);
  }
  createAssetBulk(assetData: any): Observable<any> {
    const url = `${this.baseUrl}/assets/create-bulk`;
    return this.http.post(url, assetData);
  }

  // Get an asset by ID
  getAssetById(id: number): Observable<any> {
    const url = `${this.baseUrl}/assets/id/${id}`;
    return this.http.get(url);
  }

  // Get an asset by DAL ID
  getAssetByDalId(id: string): Observable<any> {
    const url = `${this.baseUrl}/assets/uuid/${id}`;
    return this.http.get(url);
  }

  // Get asset lock status by asset ID
  isAssetLocked(assetID: number): Observable<any> {
    const url = `${this.baseUrl}/assets/locks/${assetID}`;
    return this.http.get(url);
  }

  // Get all asset sources
  getAllAssetSources(): Observable<any> {
    const url = `${this.baseUrl}/assets/sources`;
    return this.http.get(url);
  }

  // Add a bank to an asset
  addBankToAsset(assetUUID: string, bankUUID: string): Observable<any> {
    const url = `${this.baseUrl}/assets/${assetUUID}/banks/${bankUUID}`;
    return this.http.put(url, {});
  }

  // Delete an asset by its ID
  deleteAsset(id: string): Observable<any> {
    const url = `${this.baseUrl}/assets/${id}`;
    return this.http.delete(url);
  }

  // Get asset by UUID
  getAssetByUUID(uuid: string): Observable<any> {
    const url = `${this.baseUrl}/assets/${uuid}`;
    return this.http.get(url);
  }
}
