import { Component, Input } from '@angular/core';

@Component({
  selector: 'dal-shared-ui-audio-upload-thumbnail',
  templateUrl: './audioUploadThumbnail.component.html',
  styleUrl: './audioUploadThumbnail.component.scss',
})
export class AudioUploadThumbnailComponent {
  @Input() file: File;
}
