import { Component, OnInit } from '@angular/core';
import { UploadAssetService } from '../../services/upload-asset.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dal-upload-page',
  templateUrl: './uploadPage.component.html',
  styleUrl: './uploadPage.component.scss',
})
export class UploadPageComponent implements OnInit {
  isFinishClicked: boolean = false;

  constructor(public uploadAssetService: UploadAssetService,
    private router: Router) {
  }

  ngOnInit() {
    if (this.uploadAssetService.files.length === 0) {
      this.cancel()
    }
  }
  checkIfFormIsValid() {
    return this.uploadAssetService.assetPreviewForm?.valid && this.uploadAssetService.files.length > 0 && this.uploadAssetService.metadataForm.valid
  }

  submit() {
    this.isFinishClicked = true;
    if (this.checkIfFormIsValid()) {
      this.uploadAssetService.uploadToS3()
        .subscribe(() => {
          this.uploadAssetService.files = [];
          this.router.navigate(['/activity-queue']);
        });
    }
  }

  cancel() {
    this.isFinishClicked = false
    this.router.navigate(['/']);
  }
}
