<div class="container">
  <div class="media-container">
    <ng-container *ngFor="let media of mediaFiles">
      <ng-container [ngSwitch]="getMediaType(media.path)">
        <img *ngSwitchCase="'image'" [src]="media.path" alt="Your Image" />
        <video *ngSwitchCase="'video'" controls>
          <source [src]="media.path" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div *ngSwitchCase="'audio'" class="audio-container">
          <img
            src="/assets/rendition.jpeg"
            alt="Audio Thumbnail"
            class="audio-thumbnail"
          />
          <audio controls class="audio-player">
            <source [src]="media.path" type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
        </div>
        <div *ngSwitchDefault>Unsupported media type</div>
        <div
          class="preview-link"
          *ngIf="!isPreviewRoute()"
          (click)="openImageInNewTab(media.path)"
        >
          <span class="preview-icon"
            ><i class="fas fa-external-link-alt"></i
          ></span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
