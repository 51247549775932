import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {
  private baseUrl = environment.apiBaseUrl

  constructor(private http: HttpClient) { }

  // Get all metadata types
  getAllMetadataTypes(): Observable<any> {
    const url = `${this.baseUrl}/metadata`;
    return this.http.get(url);
  }

  // Get all metadata templates
  getAllMetadataTemplates(): Observable<any> {
    const url = `${this.baseUrl}/metadata/templates`;
    return this.http.get(url);
  }

  // Get metadata template by ID
  getMetadataTemplateById(id: number): Observable<any> {
    const url = `${this.baseUrl}/metadata/templates/${id}`;
    return this.http.get(url);
  }

  // Apply metadata template to a list of assets
  applyMetadataTemplate(id: number, assetIds: number[]): Observable<any> {
    const url = `${this.baseUrl}/metadata/templates/${id}/apply`;
    return this.http.post(
      url,
      { asset_ids: assetIds }
    );
  }

  // Get metadata type values by ID
  getMetadataValuesByTypeId(id: number): Observable<any> {
    const url = `${this.baseUrl}/metadata/${id}/values`;
    return this.http.get(url);
  }
}
