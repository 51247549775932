import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { sortBy } from './searchpreview.constants';

export interface previewData {
  filename: string;
  image: string;
  gpsUniqueId: string;
  dalUniqueId: string;
  title: string;
  assetSource: string;
  rightsCategory: string;
  finalizedFlag: string;
  usageRestrictions: string;
}
@Component({
  selector: 'dal-shared-ui-search-preview',
  templateUrl: './searchPreview.component.html',
  styleUrl: './searchPreview.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SearchPreviewComponent {
  @Input()loading: boolean = true; 
  @Input() dataSource:any[]= [];
  @Input() total = 0;
  @Input() searchTerm: string = '';
  @Input() termFilters: { [key: string]: string[] } = {};
  @Output() SortChangeEvent: EventEmitter<string> = new EventEmitter();
  @Output() PageChangeEvent: EventEmitter<number> = new EventEmitter();
  searchQuery: string = '';
  currentPage: number = 1;
  totalPages: number = 1;
  selectedSortOption: string = '';
  sortBy = sortBy;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('add-circle', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add-circle.svg'));
    iconRegistry.addSvgIcon('edit-pencil', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit-pencil.svg'));
    iconRegistry.addSvgIcon('attach', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/attach.svg'));
    iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/delete.svg'));
    iconRegistry.addSvgIcon('transcode', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/transcode.svg'));
    iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trash.svg'));
  }

 
  onSortChange(sortBy:string): void {
    this.SortChangeEvent.emit(sortBy)
  }

  onPageChange(page:number): void {
    if (this.currentPage===1 && page=== -1) {
      this.currentPage = 1;
    } else {
      this.currentPage +=page
    }
    this.PageChangeEvent.emit(this.currentPage+page)
  }


}

