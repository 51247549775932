

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class S3Service {
  private baseUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) { }

  getS3PresignedUrl(files: string[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/assets/create-presigned-urls`, JSON.stringify(files));
  }

  pushToPresignedUrl(file: File, url: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': file.type
    });

    return this.http.put<any>(url, file, { headers });
  }
}