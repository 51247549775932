<div class="mt-4 flex items-center justify-between">
  <span class="pl-[2%] mt-4 text-[1.5em] text-primary flex items-center" routerLink="/">
    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="36" height="36" rx="5" stroke="#007C91"/>
      <rect x="9" y="9" width="20" height="20" fill="black" fill-opacity="0.01"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.2496 10.75L14 18.9996L22.2496 27.2492L23.4281 26.0706L16.357 18.9996L23.4281 11.9285L22.2496 10.75Z" fill="#007C91"/>
    </svg>
    <span class="ml-4">UPLOAD</span>
  </span>
  @if (!!uploadAssetService.currentTemplate ) {
    <span class="mr-8">
      <dal-shared-ui-drop-down [heading]="heading" [selectedTemplate]="selectedTemplate" [label]="label" [templates]="templates$ | async" (TemplateEvent)="onSelection($event)"></dal-shared-ui-drop-down>
    </span>
  }
</div>
<div class="ml-6 mr-6">
  <div class="grid grid-cols-12 ">
      <dal-assetuploadpreview class="col-span-6 sm:col-span-5 md:col-span-6"></dal-assetuploadpreview>
      <dal-bulk-add-edit-metadata class="col-span-6 sm:col-span-7 md:col-span-6" [mediaType]="mediaType"></dal-bulk-add-edit-metadata>
  </div>

  <div class="flex flex-row-reverse p-2 items-center sticky bottom-0 justify-between bg-gray-100">
    <div class="flex flex-row-reverse">

    <button
      class="button bg-primary w-[8rem] h-[2.5em] border-[1px] border-none text-white font-[2px] text-[1rem] rounded-3xl mx-[1%] shadow-0 cursor-pointer focus:ring focus:ring-violet-300 
      disabled-link"
      [disabled]="!checkIfFormIsValid()"
      (click)="submit()"
    >
      UPLOAD
    </button>
    <button
      class="button bg-white w-[8rem] h-[2.5em] border-[1px] rounded-3xl text-primary text-[1em] border-solid cursor-pointer"
      (click)="reset()"
    >
      CANCEL
    </button>
    </div>

  </div>
</div>


