@if (!showFilters){
<div class="grid grid-cols-2 w-card border-custom">
  <div class="col-span-1">
    <i class="bi bi-filter-circle-fill filter"></i>
    <span class="font-bold text-md p-4 -mt-2"> Filters </span>
  </div>
  <div class="col-span-1 flex justify-end">
    <i class="bi bi-caret-left" (click)="toggleFilterSection()"></i>
  </div>
</div>
@if (showKeywordSearch ) {

<div class="w-card border-custom">
  <mat-form-field
    class="custom-mat-form-field search-keyword-field"
    appearance="outline"
  >
    <mat-icon matTextPrefix class="">search</mat-icon>
    <input
      matInput
      type="text"
      [formControl]="keyword"
      placeholder="Keyword Search"
      (keyup.enter)="onSearch()"
    />
  </mat-form-field>
</div>
}
<div class="max-h-filter overflow-auto">
  <mat-accordion class="headers-align" multi>
    @for (filter of filteredFilters; track filters) {
    <mat-expansion-panel [togglePosition]="'before'" class="border-custom">
      <mat-expansion-panel-header>
        <mat-panel-title class="text-xs"> {{ filter.title }} </mat-panel-title>
      </mat-expansion-panel-header>
      <ul class="">
        @for (input of filter.inputs; track input) {
        <li>
          <mat-checkbox
            value="input.name"
            [(ngModel)]="input.value"
            (ngModelChange)="updateFiltersList()"
          >
            {{ input.name }}
          </mat-checkbox>
        </li>
        }
      </ul>
    </mat-expansion-panel>
    }
  </mat-accordion>
</div>
} @else {
<div class="w-card min-h-screen overflow-auto border-custom">
  <i
    class="bi bi-filter-circle-fill filter"
    (click)="toggleFilterSection()"
  ></i>
</div>
}
