import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { UploadAssetService } from '../../../services/upload-asset.service';
import { AbsService } from '@dal/core';
import { MetadataOption } from '../../../constants/MetadataOption.constants';
import { Observable } from 'rxjs';
import { MetadataFields } from '../../../constants/asset.metadata.constants';


@Component({
  selector: 'dal-bulk-add-edit-metadata',
  templateUrl: './bulk-add-edit-metadata.component.html',
  styleUrl: './bulk-add-edit-metadata.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class BulkAddEditMetadataComponent implements OnInit {
  DescriptiveOpen: boolean = true;
  InformationalOpen: boolean = true;
  RightsOpen: boolean = true;

  //Form ptions
  assetSources$: Observable<any>;
  DistributionRights$: Observable<any>;
  RightsCategory$: Observable<any>;
  AdaptationRights$: Observable<any>;
  BusinessUnitRights$: Observable<any>;
  ModelRelease$: Observable<any>;
  LocationRelease$: Observable<any>;
  UsageRestriction$: Observable<any>;
  MediaType$: Observable<any>;
  ImageColor$: Observable<any>;
  VideoColor$: Observable<any>;
  Disability$: Observable<any>;
  VideoSound$: Observable<any>;
  provider_bu$: Observable<any>;
  yes_no: string[];


  constructor(
    public uploadAssetService: UploadAssetService,
    private absService: AbsService,
  ) {
  }

  ngOnInit(): void {
    this.assetSources$ = this.absService.fetchAssetMetadataDropDownValuesforAssetSource();
    this.provider_bu$ = this.absService.fetchAssetMetadataDropDownValuesforProviderBusinessUnit();
    this.DistributionRights$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.DistributionRights);
    this.RightsCategory$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.RightsCategory);
    this.AdaptationRights$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.AdaptationRights);
    this.BusinessUnitRights$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.BusinessUnitRights);
    this.ModelRelease$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.ModelRelease);
    this.LocationRelease$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.LocationRelease);
    this.UsageRestriction$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.UsageRestriction);
    this.MediaType$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.MediaType);
    this.ImageColor$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.ImageColor);
    this.VideoColor$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.VideoColor);
    this.Disability$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.Disability);
    this.VideoSound$ = this.absService.fetchAssetMetadataDropDownValues(MetadataFields.VideoSound);
  }

  toggleDropdown(section: string) {
    if (section == "descriptive") {
      this.DescriptiveOpen = !this.DescriptiveOpen;
    }
    else if(section == "informational") {
      this.InformationalOpen = !this.InformationalOpen;
    }
    else if(section == "rights") {
      this.RightsOpen = !this.RightsOpen;
    }
  }
}
