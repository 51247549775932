.filter {
  color: #767676;
}
.w-card {
  box-shadow: 0 0 2px #333;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14);
  .mat-mdc-form-field-infix {
    min-height: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  div {
    border-color: #A2A2A2 !important;
   }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__notch{
    border-right-width: 0;
  }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch{
    border-right-width: 0;
  }
  mat-icon {
    padding-top: 10px !important;
    font-size: large;
    color: #767676;
  }
}
.search-keyword-field {
  width: 100%;
  margin: 0 auto;
}

.max-h-filter {
  max-height: calc(100vh - 120px);
  overflow: auto;
}
.mat-expansion-panel,
.mat-expansion-panel-spacing {
  margin: 5px 0 !important;
  box-shadow: none !important;
}