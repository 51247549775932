<mat-sidenav-container class="h-vh sidenav-container" [hasBackdrop]="true">
  <mat-sidenav #sidenav class="sidenav-content" fixedTopGap="64">
    <mat-nav-list>
      @for (item of elements; track item) {
      <li matRipple class="text-white li-nav" (click)="goto(item.link)">
        @if (item.name === '') {
        <mat-divider class="bg-white"></mat-divider>
        } @else {
        {{ item.name }}
        }
      </li>
      }
      <li matRipple class="text-white li-nav" (click)="logout()">
        <a (click)="logout()">Logout</a>
      </li>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
