import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { sortBy } from './searchpreview.constants';
import { PageDetail, SearchResult } from '../../interfaces/search-result.interface';
import { AssetTypeId } from '../../interfaces/asset.interface';

@Component({
  selector: 'dal-shared-ui-search-preview',
  templateUrl: './searchPreview.component.html',
  styleUrl: './searchPreview.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SearchPreviewComponent implements OnChanges {
  @Input() loading: boolean = true;
  @Input() dataSource: SearchResult[] = [];
  @Input() searchCount = 1;
  @Input() searchTerm: string = '';
  @Input() termFilters: { [key: string]: string[] } = {};
  @Input() currentPage: number = 1;
  @Input() itemsPerPage: number = 50;  //default
  @Output() SortChangeEvent: EventEmitter<string> = new EventEmitter();
  @Output() PageChangeEvent: EventEmitter<PageDetail> = new EventEmitter();
  totalPages = Math.ceil(this.searchCount / this.itemsPerPage) || 1;

  sortBy = sortBy;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('add-circle', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/add-circle.svg'));
    iconRegistry.addSvgIcon('edit-pencil', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/edit-pencil.svg'));
    iconRegistry.addSvgIcon('attach', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/attach.svg'));
    iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/delete.svg'));
    iconRegistry.addSvgIcon('transcode', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/transcode.svg'));
    iconRegistry.addSvgIcon('trash', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/trash.svg'));
  }

  onSortChange(sortBy:string): void {
    this.SortChangeEvent.emit(sortBy)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchTerm']) {
      this.currentPage = 1; // Reset to 1 when searchTerm changes
    }
    if (changes['searchCount']) {
      this.totalPages = Math.ceil(this.searchCount / this.itemsPerPage) || 1;
    }
  }

  onPageChange(direction: number, direct = false): void {
    if (direction === -1 && this.currentPage > 1 && this.currentPage <= this.totalPages) {
      this.changePage(this.currentPage - 1);
    } else if (direction === 1 && this.currentPage < this.totalPages) {
      this.changePage(this.currentPage + 1);
    }
    // for input box change, direction is considered as pageNum
    if (direct && direction > 0 && direction <= this.totalPages) {
      this.changePage(direction)
    }
  }

  changePage(page: number) {
    this.currentPage = page;
    const pageDetails: PageDetail = { itemsPerPage: this.itemsPerPage, currentPage: this.currentPage };
    this.PageChangeEvent.emit(pageDetails);
  }

  getThumbnailUrl(data: SearchResult): string {
    let assetUrl = '';
    if (data.asset_type.id === AssetTypeId.Audio) {
      assetUrl = "/assets/rendition.jpeg";
    } else {
      const renditions = data.renditions
      const thumbnail = renditions.find(rendition => rendition.rendition_type.name === 'Thumbnail');
      assetUrl = thumbnail ? thumbnail.url : '';
    }
    return assetUrl;
  }


}

