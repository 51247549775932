/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

import { getStringValue, getEncodedString } from './tag-names-utils.js';
import TagNamesCommon from './tag-names-common.js';
export default {
  0x829a: {
    'name': 'ExposureTime',
    'description': TagNamesCommon.ExposureTime
  },
  0x829d: {
    'name': 'FNumber',
    'description': TagNamesCommon.FNumber
  },
  0x8822: {
    'name': 'ExposureProgram',
    'description': TagNamesCommon.ExposureProgram
  },
  0x8824: 'SpectralSensitivity',
  0x8827: 'ISOSpeedRatings',
  0x8828: {
    'name': 'OECF',
    'description': () => '[Raw OECF table data]'
  },
  0x882a: 'TimeZoneOffset',
  0x882b: 'SelfTimerMode',
  0x8830: {
    name: 'SensitivityType',
    description: value => ({
      1: 'Standard Output Sensitivity',
      2: 'Recommended Exposure Index',
      3: 'ISO Speed',
      4: 'Standard Output Sensitivity and Recommended Exposure Index',
      5: 'Standard Output Sensitivity and ISO Speed',
      6: 'Recommended Exposure Index and ISO Speed',
      7: 'Standard Output Sensitivity, Recommended Exposure Index and ISO Speed'
    })[value] || 'Unknown'
  },
  0x8831: 'StandardOutputSensitivity',
  0x8832: 'RecommendedExposureIndex',
  0x8833: 'ISOSpeed',
  0x8834: 'ISOSpeedLatitudeyyy',
  0x8835: 'ISOSpeedLatitudezzz',
  0x9000: {
    'name': 'ExifVersion',
    'description': value => getStringValue(value)
  },
  0x9003: 'DateTimeOriginal',
  0x9004: 'DateTimeDigitized',
  0x9009: 'GooglePlusUploadCode',
  0x9010: 'OffsetTime',
  0x9011: 'OffsetTimeOriginal',
  0x9012: 'OffsetTimeDigitized',
  0x9101: {
    'name': 'ComponentsConfiguration',
    'description': TagNamesCommon.ComponentsConfiguration
  },
  0x9102: 'CompressedBitsPerPixel',
  0x9201: {
    'name': 'ShutterSpeedValue',
    'description': TagNamesCommon.ShutterSpeedValue
  },
  0x9202: {
    'name': 'ApertureValue',
    'description': TagNamesCommon.ApertureValue
  },
  0x9203: 'BrightnessValue',
  0x9204: 'ExposureBiasValue',
  0x9205: {
    'name': 'MaxApertureValue',
    'description': value => {
      return Math.pow(Math.sqrt(2), value[0] / value[1]).toFixed(2);
    }
  },
  0x9206: {
    'name': 'SubjectDistance',
    'description': value => value[0] / value[1] + ' m'
  },
  0x9207: {
    'name': 'MeteringMode',
    'description': TagNamesCommon.MeteringMode
  },
  0x9208: {
    'name': 'LightSource',
    description: TagNamesCommon.LightSource
  },
  0x9209: {
    'name': 'Flash',
    'description': value => {
      if (value === 0x00) {
        return 'Flash did not fire';
      } else if (value === 0x01) {
        return 'Flash fired';
      } else if (value === 0x05) {
        return 'Strobe return light not detected';
      } else if (value === 0x07) {
        return 'Strobe return light detected';
      } else if (value === 0x09) {
        return 'Flash fired, compulsory flash mode';
      } else if (value === 0x0d) {
        return 'Flash fired, compulsory flash mode, return light not detected';
      } else if (value === 0x0f) {
        return 'Flash fired, compulsory flash mode, return light detected';
      } else if (value === 0x10) {
        return 'Flash did not fire, compulsory flash mode';
      } else if (value === 0x18) {
        return 'Flash did not fire, auto mode';
      } else if (value === 0x19) {
        return 'Flash fired, auto mode';
      } else if (value === 0x1d) {
        return 'Flash fired, auto mode, return light not detected';
      } else if (value === 0x1f) {
        return 'Flash fired, auto mode, return light detected';
      } else if (value === 0x20) {
        return 'No flash function';
      } else if (value === 0x41) {
        return 'Flash fired, red-eye reduction mode';
      } else if (value === 0x45) {
        return 'Flash fired, red-eye reduction mode, return light not detected';
      } else if (value === 0x47) {
        return 'Flash fired, red-eye reduction mode, return light detected';
      } else if (value === 0x49) {
        return 'Flash fired, compulsory flash mode, red-eye reduction mode';
      } else if (value === 0x4d) {
        return 'Flash fired, compulsory flash mode, red-eye reduction mode, return light not detected';
      } else if (value === 0x4f) {
        return 'Flash fired, compulsory flash mode, red-eye reduction mode, return light detected';
      } else if (value === 0x59) {
        return 'Flash fired, auto mode, red-eye reduction mode';
      } else if (value === 0x5d) {
        return 'Flash fired, auto mode, return light not detected, red-eye reduction mode';
      } else if (value === 0x5f) {
        return 'Flash fired, auto mode, return light detected, red-eye reduction mode';
      }
      return 'Unknown';
    }
  },
  0x920a: {
    'name': 'FocalLength',
    'description': TagNamesCommon.FocalLength
  },
  0x9211: 'ImageNumber',
  0x9212: {
    name: 'SecurityClassification',
    description: value => ({
      'C': 'Confidential',
      'R': 'Restricted',
      'S': 'Secret',
      'T': 'Top Secret',
      'U': 'Unclassified'
    })[value] || 'Unknown'
  },
  0x9213: 'ImageHistory',
  0x9214: {
    'name': 'SubjectArea',
    'description': value => {
      if (value.length === 2) {
        return `Location; X: ${value[0]}, Y: ${value[1]}`;
      } else if (value.length === 3) {
        return `Circle; X: ${value[0]}, Y: ${value[1]}, diameter: ${value[2]}`;
      } else if (value.length === 4) {
        return `Rectangle; X: ${value[0]}, Y: ${value[1]}, width: ${value[2]}, height: ${value[3]}`;
      }
      return 'Unknown';
    }
  },
  0x927c: {
    'name': 'MakerNote',
    'description': () => '[Raw maker note data]'
  },
  0x9286: {
    'name': 'UserComment',
    'description': getEncodedString
  },
  0x9290: 'SubSecTime',
  0x9291: 'SubSecTimeOriginal',
  0x9292: 'SubSecTimeDigitized',
  0x935c: 'ImageSourceData',
  0x9400: {
    'name': 'AmbientTemperature',
    'description': value => value[0] / value[1] + ' °C'
  },
  0x9401: {
    'name': 'Humidity',
    'description': value => value[0] / value[1] + ' %'
  },
  0x9402: {
    'name': 'Pressure',
    'description': value => value[0] / value[1] + ' hPa'
  },
  0x9403: {
    'name': 'WaterDepth',
    'description': value => value[0] / value[1] + ' m'
  },
  0x9404: {
    'name': 'Acceleration',
    'description': value => value[0] / value[1] + ' mGal'
  },
  0x9405: {
    'name': 'CameraElevationAngle',
    'description': value => value[0] / value[1] + ' °'
  },
  0xa000: {
    'name': 'FlashpixVersion',
    'description': value => value.map(charCode => String.fromCharCode(charCode)).join('')
  },
  0xa001: {
    'name': 'ColorSpace',
    'description': TagNamesCommon.ColorSpace
  },
  0xa002: 'PixelXDimension',
  0xa003: 'PixelYDimension',
  0xa004: 'RelatedSoundFile',
  0xa005: 'Interoperability IFD Pointer',
  0xa20b: 'FlashEnergy',
  0xa20c: {
    'name': 'SpatialFrequencyResponse',
    'description': () => '[Raw SFR table data]'
  },
  0xa20e: 'FocalPlaneXResolution',
  0xa20f: 'FocalPlaneYResolution',
  0xa210: {
    'name': 'FocalPlaneResolutionUnit',
    'description': TagNamesCommon.FocalPlaneResolutionUnit
  },
  0xa214: {
    'name': 'SubjectLocation',
    'description': ([x, y]) => `X: ${x}, Y: ${y}`
  },
  0xa215: 'ExposureIndex',
  0xa217: {
    'name': 'SensingMethod',
    'description': value => {
      if (value === 1) {
        return 'Undefined';
      } else if (value === 2) {
        return 'One-chip color area sensor';
      } else if (value === 3) {
        return 'Two-chip color area sensor';
      } else if (value === 4) {
        return 'Three-chip color area sensor';
      } else if (value === 5) {
        return 'Color sequential area sensor';
      } else if (value === 7) {
        return 'Trilinear sensor';
      } else if (value === 8) {
        return 'Color sequential linear sensor';
      }
      return 'Unknown';
    }
  },
  0xa300: {
    'name': 'FileSource',
    'description': value => {
      if (value === 3) {
        return 'DSC';
      }
      return 'Unknown';
    }
  },
  0xa301: {
    'name': 'SceneType',
    'description': value => {
      if (value === 1) {
        return 'A directly photographed image';
      }
      return 'Unknown';
    }
  },
  0xa302: {
    'name': 'CFAPattern',
    'description': () => '[Raw CFA pattern table data]'
  },
  0xa401: {
    'name': 'CustomRendered',
    'description': TagNamesCommon.CustomRendered
  },
  0xa402: {
    'name': 'ExposureMode',
    'description': TagNamesCommon.ExposureMode
  },
  0xa403: {
    'name': 'WhiteBalance',
    'description': TagNamesCommon.WhiteBalance
  },
  0xa404: {
    'name': 'DigitalZoomRatio',
    'description': value => {
      if (value[0] === 0) {
        return 'Digital zoom was not used';
      }
      return '' + value[0] / value[1];
    }
  },
  0xa405: {
    'name': 'FocalLengthIn35mmFilm',
    'description': value => {
      if (value === 0) {
        return 'Unknown';
      }
      return value;
    }
  },
  0xa406: {
    'name': 'SceneCaptureType',
    'description': TagNamesCommon.SceneCaptureType
  },
  0xa407: {
    'name': 'GainControl',
    'description': value => {
      if (value === 0) {
        return 'None';
      } else if (value === 1) {
        return 'Low gain up';
      } else if (value === 2) {
        return 'High gain up';
      } else if (value === 3) {
        return 'Low gain down';
      } else if (value === 4) {
        return 'High gain down';
      }
      return 'Unknown';
    }
  },
  0xa408: {
    'name': 'Contrast',
    'description': TagNamesCommon.Contrast
  },
  0xa409: {
    'name': 'Saturation',
    'description': TagNamesCommon.Saturation
  },
  0xa40a: {
    'name': 'Sharpness',
    'description': TagNamesCommon.Sharpness
  },
  0xa40b: {
    'name': 'DeviceSettingDescription',
    'description': () => '[Raw device settings table data]'
  },
  0xa40c: {
    'name': 'SubjectDistanceRange',
    'description': value => {
      if (value === 1) {
        return 'Macro';
      } else if (value === 2) {
        return 'Close view';
      } else if (value === 3) {
        return 'Distant view';
      }
      return 'Unknown';
    }
  },
  0xa420: 'ImageUniqueID',
  0xa430: 'CameraOwnerName',
  0xa431: 'BodySerialNumber',
  0xa432: {
    'name': 'LensSpecification',
    'description': value => {
      const focalLengths = `${value[0][0] / value[0][1]}-${value[1][0] / value[1][1]} mm`;
      if (value[3][1] === 0) {
        return `${focalLengths} f/?`;
      }
      return `${focalLengths} f/${1 / (value[2][1] / value[2][1] / (value[3][0] / value[3][1]))}`;
    }
  },
  0xa433: 'LensMake',
  0xa434: 'LensModel',
  0xa435: 'LensSerialNumber',
  0xa460: {
    name: 'CompositeImage',
    description: value => ({
      1: 'Not a Composite Image',
      2: 'General Composite Image',
      3: 'Composite Image Captured While Shooting'
    })[value] || 'Unknown'
  },
  0xa461: 'SourceImageNumberOfCompositeImage',
  0xa462: 'SourceExposureTimesOfCompositeImage',
  0xa500: 'Gamma',
  0xea1c: 'Padding',
  0xea1d: 'OffsetSchema',
  0xfde8: 'OwnerName',
  0xfde9: 'SerialNumber',
  0xfdea: 'Lens',
  0xfe4c: 'RawFile',
  0xfe4d: 'Converter',
  0xfe4e: 'WhiteBalance',
  0xfe51: 'Exposure',
  0xfe52: 'Shadows',
  0xfe53: 'Brightness',
  0xfe54: 'Contrast',
  0xfe55: 'Saturation',
  0xfe56: 'Sharpness',
  0xfe57: 'Smoothness',
  0xfe58: 'MoireFilter'
};