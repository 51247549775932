import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UploadAssetService } from '../../services/upload-asset.service';
import { map, Observable } from 'rxjs';
import { SupportedMimeType, TemplateDetails } from '../../models/asset.metadata.model';
import { MetadataService } from '@dal/core';


@Component({
  selector: 'dal-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  duplicateFiles: any[] = [];
  dragDropDisabled: boolean = true;
  heading: string = "DAL IMPORT TEMPLATES";
  label: string = "Select a DAL Import Template";
  templates$: Observable<TemplateDetails[]>;
  role: any = sessionStorage.getItem('role');
  forceSubmit: boolean;
  dropDown: boolean = true;
  selectedTemplate: string;
  supportedFileTypes: SupportedMimeType[];

  constructor(
    private router: Router,
    private metadataService: MetadataService,
    public uploadAssetService: UploadAssetService
  ) { }


  ngOnInit(): void {
    this.templates$ = this.metadataService.getAllMetadataTemplates().pipe(map((templates: TemplateDetails[]) => {
      return templates.map((template: TemplateDetails) => {
        return {
          ...template,
          supportedMimeTypes: template.supported_mime_types.map((type: any) => type.extension),
          model: template.supported_mime_types[0].asset_type.name
        }
      })
    }));
    this.selectedTemplate = this.uploadAssetService.currentTemplate?.name || ''
    this.dragDropDisabled = !this.selectedTemplate;
    this.supportedFileTypes = this.uploadAssetService.currentTemplate?.supportedMimeTypes || [];
  }

  onUpload(files: File[]) {
    this.uploadAssetService.files = files.filter(file => {
      const dupfile = this.uploadAssetService.files.findIndex(item => item.name === file.name && item.lastModified === file.lastModified);
      return dupfile === -1
    })
    this.forceSubmit = false
  }

  deleteEvent(deletedFile: File) {
    this.uploadAssetService.files.splice(this.uploadAssetService.files.indexOf(deletedFile), 1);
  }

  onSelection(template: TemplateDetails) {
    if (this.uploadAssetService.currentTemplate !== template){
      this.selectedTemplate = template?.name || '';
      this.uploadAssetService.currentTemplate = template;
      this.uploadAssetService.resetFileCache();
      this.supportedFileTypes = template?.supportedMimeTypes || [];
      this.dragDropDisabled = false;
    }
  }

  onSubmit() {
    if (this.disableSubmit()) return
    this.uploadAssetService.duplicateCheck().subscribe(async (files) => {
      this.duplicateFiles = files;
      if (this.duplicateFiles.length > 0 && !this.forceSubmit && this.role == 'PA') {
        this.forceSubmit = true
      } else {
        const extractedTagsMetadata = await this.uploadAssetService.extractedTagsMetadata()

        this.uploadAssetService.initAssetPayload(extractedTagsMetadata)
        this.router.navigate(['/upload']);
      }
    });

  }

  disableSubmit() {
    return this.uploadAssetService.files.length === 0 || this.uploadAssetService.currentTemplate === undefined
  }

  clear() {
    this.uploadAssetService.resetFileCache();
    this.forceSubmit = false;
    this.selectedTemplate = '';
    this.dragDropDisabled = true;
    this.uploadAssetService.currentTemplate = {} as TemplateDetails;
    this.uploadAssetService.resetFileCache();
  }
}