import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'dal-shared-ui-drop-down',
  templateUrl: './dropDown.component.html',
  styleUrl: './dropDown.component.scss',
})
export class DropDownComponent {

  @Input() templates: any[] | null = [];
  @Input() heading: string;
  @Input() selectedTemplate: string;
  @Input() label: string;
  @Input() showClearOption: boolean = false;
  @Input() showInfo = true
  @Input() infoText = "Select a template to apply global metadata to all assets in the batch."
  @Output() TemplateEvent: EventEmitter<any> = new EventEmitter();

  onSelectionChange(event: any) {
    this.selectedTemplate = event.value;
    const template = this.templates?.find((template: any) => template.name === event.value)
    this.TemplateEvent.emit(template);
  }

  changeTemplate(event: any) {
    this.selectedTemplate = event;
  }
}
