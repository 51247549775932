<div class="media-container">
  @switch (type) {
    @case('image'){
      <img appDefaultImg [src]="inputUrl" alt="Image" [ngClass]="{'image': applyClass, 'g-image-height': applyClass}" />
    }
    @case('audio'){
      <div class="audio-container">
        <img src="/assets/rendition.jpeg" alt="Audio Thumbnail" class="audio-thumbnail" />
        <audio #audioPlayer controls class="audio-player">
          <source [src]="inputUrl" type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      </div>
    }
    @case('video'){
      <video #videoPlayer controls>
        <source [src]="inputUrl" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    }
    @case('application'){
      <img appDefaultImg [src]="inputUrl" alt="Image" [ngClass]="{'image': applyClass, 'g-image-height': applyClass}" />
    }
    @default{
      <div>Unsupported media type</div>
    }
  }
</div>
