.rotate {
  transform: rotate(180deg);
}

button{
  border-color: #50737C;
}

.search{
  padding: 0;
  font-size: 10px;
  height: 1%;
}

.multipleSelect{
  background-color: white ;
}

.disabled {
  background-color: #CACACA;
  cursor: not-allowed;
  border-color: #CACACA;
  pointer-events: none;
}

.fields-width{
  width: 90% !important;
  padding: 0 10px;
  margin-left: 5px;
}

@media screen and (max-width: 640px) {
    .fields-width {
      width: 100% !important;
    }
}

.multipleOption{
  background-color: #666667 !important;
}

.mat-mdc-select-arrow{
  padding: 10px !important;
}

.mat-mdc-select-panel {
  padding: 0 !important;
  margin-right: 20px;
}

.mat-pseudo-checkbox-full {
  border-color: rgb(251 251 251 / 54%) !important;
}
