/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

import { getEncodedString, getCalculatedGpsValue } from './tag-names-utils.js';
export default {
  0x0000: {
    'name': 'GPSVersionID',
    'description': value => {
      if (value[0] === 2 && value[1] === 2 && value[2] === 0 && value[3] === 0) {
        return 'Version 2.2';
      }
      return 'Unknown';
    }
  },
  0x0001: {
    'name': 'GPSLatitudeRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'N') {
        return 'North latitude';
      } else if (ref === 'S') {
        return 'South latitude';
      }
      return 'Unknown';
    }
  },
  0x0002: {
    'name': 'GPSLatitude',
    'description': getCalculatedGpsValue
  },
  0x0003: {
    'name': 'GPSLongitudeRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'E') {
        return 'East longitude';
      } else if (ref === 'W') {
        return 'West longitude';
      }
      return 'Unknown';
    }
  },
  0x0004: {
    'name': 'GPSLongitude',
    'description': getCalculatedGpsValue
  },
  0x0005: {
    'name': 'GPSAltitudeRef',
    'description': value => {
      if (value === 0) {
        return 'Sea level';
      } else if (value === 1) {
        return 'Sea level reference (negative value)';
      }
      return 'Unknown';
    }
  },
  0x0006: {
    'name': 'GPSAltitude',
    'description': value => {
      return value[0] / value[1] + ' m';
    }
  },
  0x0007: {
    'name': 'GPSTimeStamp',
    'description': values => {
      return values.map(([numerator, denominator]) => {
        const num = numerator / denominator;
        if (/^\d(\.|$)/.test(`${num}`)) {
          return `0${num}`;
        }
        return num;
      }).join(':');
    }
  },
  0x0008: 'GPSSatellites',
  0x0009: {
    'name': 'GPSStatus',
    'description': value => {
      const status = value.join('');
      if (status === 'A') {
        return 'Measurement in progress';
      } else if (status === 'V') {
        return 'Measurement Interoperability';
      }
      return 'Unknown';
    }
  },
  0x000a: {
    'name': 'GPSMeasureMode',
    'description': value => {
      const mode = value.join('');
      if (mode === '2') {
        return '2-dimensional measurement';
      } else if (mode === '3') {
        return '3-dimensional measurement';
      }
      return 'Unknown';
    }
  },
  0x000b: 'GPSDOP',
  0x000c: {
    'name': 'GPSSpeedRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'K') {
        return 'Kilometers per hour';
      } else if (ref === 'M') {
        return 'Miles per hour';
      } else if (ref === 'N') {
        return 'Knots';
      }
      return 'Unknown';
    }
  },
  0x000d: 'GPSSpeed',
  0x000e: {
    'name': 'GPSTrackRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'T') {
        return 'True direction';
      } else if (ref === 'M') {
        return 'Magnetic direction';
      }
      return 'Unknown';
    }
  },
  0x000f: 'GPSTrack',
  0x0010: {
    'name': 'GPSImgDirectionRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'T') {
        return 'True direction';
      } else if (ref === 'M') {
        return 'Magnetic direction';
      }
      return 'Unknown';
    }
  },
  0x0011: 'GPSImgDirection',
  0x0012: 'GPSMapDatum',
  0x0013: {
    'name': 'GPSDestLatitudeRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'N') {
        return 'North latitude';
      } else if (ref === 'S') {
        return 'South latitude';
      }
      return 'Unknown';
    }
  },
  0x0014: {
    'name': 'GPSDestLatitude',
    'description': value => {
      return value[0][0] / value[0][1] + value[1][0] / value[1][1] / 60 + value[2][0] / value[2][1] / 3600;
    }
  },
  0x0015: {
    'name': 'GPSDestLongitudeRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'E') {
        return 'East longitude';
      } else if (ref === 'W') {
        return 'West longitude';
      }
      return 'Unknown';
    }
  },
  0x0016: {
    'name': 'GPSDestLongitude',
    'description': value => {
      return value[0][0] / value[0][1] + value[1][0] / value[1][1] / 60 + value[2][0] / value[2][1] / 3600;
    }
  },
  0x0017: {
    'name': 'GPSDestBearingRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'T') {
        return 'True direction';
      } else if (ref === 'M') {
        return 'Magnetic direction';
      }
      return 'Unknown';
    }
  },
  0x0018: 'GPSDestBearing',
  0x0019: {
    'name': 'GPSDestDistanceRef',
    'description': value => {
      const ref = value.join('');
      if (ref === 'K') {
        return 'Kilometers';
      } else if (ref === 'M') {
        return 'Miles';
      } else if (ref === 'N') {
        return 'Knots';
      }
      return 'Unknown';
    }
  },
  0x001a: 'GPSDestDistance',
  0x001b: {
    'name': 'GPSProcessingMethod',
    'description': getEncodedString
  },
  0x001c: {
    'name': 'GPSAreaInformation',
    'description': getEncodedString
  },
  0x001d: 'GPSDateStamp',
  0x001e: {
    'name': 'GPSDifferential',
    'description': value => {
      if (value === 0) {
        return 'Measurement without differential correction';
      } else if (value === 1) {
        return 'Differential correction applied';
      }
      return 'Unknown';
    }
  },
  0x001f: 'GPSHPositioningError'
};