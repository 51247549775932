<div>
  <h3 class="mx-[4%] my-[1%] font-normal text-[12px]">{{heading}}</h3>
  <mat-form-field class="custom-mat-form-field dense-3 ml-[4%] mr-[1%] w-[25rem]" appearance="outline">
      <mat-label class="text-m">{{label}}</mat-label>
      <mat-icon matTextPrefix class="mt-[15px] mr-[12px]">search</mat-icon>
      <mat-select class="mt-[1px] mr-[1px] text-m p-0">
        @for (theme of templates; track theme) {
          <mat-option class="mat-option" [value]=theme.template_name (click)="onSelectionChange(theme)">{{theme.template_name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  @if (showInfo) {
    <span (mouseenter)="onInfoHover()" (mouseleave)="onInfoHover()" class="material-symbols-outlined p-0 cursor-pointer hover:text-primary">
      info
    </span>
    @if (onInfoHovers) {
      <span class="italic text-gray border border-black p-1 mx-[5px] max-w-24 text-wrap">
        {{ infoText }}
      </span>
    }
  }
</div>
