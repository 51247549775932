import { Component } from '@angular/core';
import { SearchStateService } from './services/search-state.service';

@Component({
  selector: 'dal-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  showSideNav: boolean;
  navItems: { name: string; link: string; }[] = [
    { name: 'Activities', link: 'activity-queue' },
    { name: 'Manage Image Carousel', link: '' },
    { name: 'Manage Media Portal Lightboxes', link: '' },
    { name: 'Manage Metadata Templates', link: '' },
    { name: 'Manage Notifications', link: '' },
    { name: 'Manage Users', link: 'users' },
  ];
  searchTerm: string = '';

  constructor(private searchStateService: SearchStateService){}

  toggleSideNav(toggleSideNav: boolean) {
    this.showSideNav = toggleSideNav;
  }

  handleSearch(searchText: string) {
    this.searchTerm = searchText.trim();
    this.searchStateService.updateSearchTerm(this.searchTerm)
  }
}
