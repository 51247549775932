export const sortBy = ["Adaptation Rights ",
  "Artistic Collection ",
  "Aspect Ratio ",
  "Asset Status ",
  "Asset Version ",
  "Audio Type ",
  "Color BW  Image ",
  "Color BW Video ",
  "Copyright Holder ",
  "Date: Imported ",
  "Duration ",
  "GPS Asset ",
  "GPS Asset Status ",
  "GPS Contract Status ",
  "GPS Unique ID ",
  "Gender ",
  "Image Type ",
  "Imported By ",
  "Ingestion ID ",
  "Is Latest Version ",
  "Last Modified By ",
  "Last Modified Date ",
  "Marketing Use ",
  "Metadata Last Modified By ",
  "Name ",
  "Number of People ",
  "Orientation ",
  "Permission Required ",
  "Relevance ",
  "Retail Collection ",
  "Rights Category ",
  "Special Needs ",
  "Video Dimension ",
  "Video Type ",
  "Volume Label ",
  "Approval State ",
  "Arm Identifier ",
  "Arm Version ",
  "Asset Condition ",
  "Asset Description ",
  "Asset Last Deleted Or Un - deleted By ",
  "Availability Date ",
  "Bitmap Color Depth ",
  "Bitmap Height ",
  "Bitmap Orientation ",
  "Bitmap Resolution ",
  "Bitmap Width ",
  "Caption ",
  "City ",
  "Clip Parent Start Time SMPTE ",
  "Coded Char Set ",
  "Color Mgmt Profile Image ",
  "Container type  ",
  "Content Editor ",
  "Content Last Locked Or Un - locked By ",
  "Content Status ",
  "Content Subtype ",
  "Content Type ",
  "Copyright ",
  "Copyright Year YYYY ",
  "Country Code ",
  "Country Name ",
  "Create Date ",
  "Create Time ",
  "Created Date ",
  "Credit ",
  "Date Sent ",
  "Date: Approval State ",
  "Date: Asset Last Deleted Or Un - deleted ",
  "Date: Content Last Locked Or Un - locked ",
  "Date: Content Status ",
  "Date: Properties First Modified ",
  "Date: Properties Last Locked Or Un - Locked ",
  "Department ",
  "Destination ",
  "Dig.Orig.Software ",
  "Digital Object ID ",
  "Document Author ",
  "Document Level ID ",
  "Document Subject ",
  "Document Title ",
  "Dominant Hue ",
  "Email Address ",
  "Email Enabled ",
  "Embargo Date ",
  "End Time SMPTE ",
  "Envelope Number ",
  "Envelope Priority ",
  "Expiration Date ",
  "File Format ",
  "File Format Version ",
  "File Size(MB) ",
  "First Name ",
  "Footage Description ",
  "Footage Keywords ",
  "Footage Location ",
  "IPTC Image Type ",
  "Interview Date ",
  "Interview Keywords ",
  "Interview Subject ",
  "Interviewee ",
  "Interviewer ",
  "Is Editable ",
  "Last Name ",
  "Layout: Date Created ",
  "Layout: Date Modified ",
  "Layout: Facing Page ",
  "Layout: File Size ",
  "Layout: Height ",
  "Layout: Master Page ",
  "Layout: Pages ",
  "Layout: Picture Missing ",
  "Layout: Start Page ",
  "Layout: Width ",
  "Lightbox Business Unit ",
  "Lightbox End Date ",
  "Lightbox Last Updated By ",
  "Lightbox Name ",
  "Lightbox Size ",
  "Lightbox Start Date ",
  "Lightbox Status ",
  "Lightbox UOI Id ",
  "Login Name ",
  "Mime Type ",
  "Mime Type ",
  "Mime Type ",
  "Mime Type ",
  "OTMM Expiration Date ",
  "Object Name ",
  "Orig Prg Version ",
  "Orig.Program ",
  "Original Asset ID ",
  "Participant Business Unit ",
  "Participant ID ",
  "Photographer ",
  "Photographer Title ",
  "Preserved File Name ",
  "Product Id ",
  "Properties First Edited By ",
  "Properties Last Locked Or Un - locked By ",
  "Property Status ",
  "Public Identifier ",
  "Role ",
  "Service Identifier ",
  "Source ",
  "Source: Dig Type ",
  "Source: Phys Color ",
  "Source: Phys Loc ",
  "Source: Phys Type ",
  "Source: Reference ",
  "Start Time SMPTE ",
  "Suppl.Category ",
  "Time Sent ",
  "Uno ",
  "User State ",
  "Work Phone "]