<div class="border-2 border-solid  w-[100%] ">
  <form 
  [formGroup]="this.uploadAssetService.metadataForm">
    <div class="mb-4">
      <div class="pl-8 pt-4">
        <i
          class="fa fa-chevron-down"
          (click)="toggleDropdown('rights')"
          [ngClass]="{ rotate: RightsOpen }"
        ></i>
        <span class="pl-2 text-xl font-bold text-primary">RIGHTS</span>
      </div>
      @if (RightsOpen) {
        <div class="flex">
          <div
            class="w-[100%] ml-[5%] mr-[20%] mt-[2%]"
          >
            <div class="mt-2 flex justify-end">
              <label> Asset Source:</label>
              <mat-select
                formControlName="assetSource"
                placeholder="Select Asset Source"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] {{
                  this.uploadAssetService.metadataForm.controls['assetSource'].invalid && this.uploadAssetService.metadataForm.touched
                    ? 'border-red-500'
                    : 'border-gray-300'
                }}"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let source of assetSources$ | async"
                  [value]="source.name"
                  ><p class="text-white">{{ source.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['assetSource'].invalid && this.uploadAssetService.metadataForm.touched) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Please provide an Asset Sorce.
              </div>
            }
            <div class="mt-2 flex justify-end">
              <label> Rights Category:</label>
              <mat-select
                placeholder="Select Right Category"
                formControlName="rights-category"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] {{
                  this.uploadAssetService.metadataForm.controls['rights-category'].invalid && this.uploadAssetService.metadataForm.touched
                    ? 'border-red-500'
                    : 'border-gray-300'
                }}">
                <mat-option
                  class="multipleOption"
                  *ngFor="let category of RightsCategory$ | async"
                  [value]="category.name"
                  ><p class="text-white">{{ category.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['rights-category'].invalid && this.uploadAssetService.metadataForm.touched) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Please provide an Right Category.
              </div>
              
            }
            
            <div class="mt-2 flex justify-end">
              <label> Adaptation Rights:</label>
              <mat-select
                placeholder="Select Adaptation Right"
                formControlName="adaptation-rights"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let right of AdaptationRights$ | async"
                  [value]="right.name"
                  ><p class="text-white">{{ right.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex justify-end">
              <label> Business Unit Rights:</label>
              <mat-select
                placeholder="Select Unit Right"
                formControlName="business-unit-rights"
                multiple
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let right of BusinessUnitRights$ | async"
                  [value]="right.name"
                  ><p class="text-white">{{ right.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex justify-end">
              <label>Credit Text</label>
              <input
                formControlName="credit-text"
                class="ml-2 pl-2 border-2 border-solid rounded w-[70%] focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Edit Credit Text"
              />
            </div>

            <div class="mt-2 flex justify-end">
              <label>Model Release</label>
              <mat-select
                placeholder="Select Model Release"
                formControlName="model-release"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of ModelRelease$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex justify-end">
              <label>Location Release:</label>
              <mat-select
                placeholder="Select Location Release"
                formControlName="location-release"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of LocationRelease$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex justify-end">
              <label> Usage Restriction:</label>
              <mat-select
                placeholder="Select Usage"
                formControlName="usage-restriction"
                multiple
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let usage of UsageRestriction$  | async"
                  [value]="usage.name"
                  ><p class="text-white">{{ usage.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex justify-end">
              <label> Notes:</label>
              <input
                formControlName="notes"
                class="ml-2 pl-2 border-2 border-solid rounded w-[70%] focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Add Notes"
              />
            </div>

            <div class="mt-2 flex justify-end">
              <label> Distribution Rights:</label>
              <mat-select
                placeholder="Select Distribution Right"
                formControlName="distribution-rights"
                multiple
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let right of DistributionRights$ | async"
                  [value]="right.name"
                  ><p class="text-white">{{ right.name }}</p></mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      }
    </div>

    <!-- INFORMATIONAL -->
    <div class="mb-4">
      <div class="pl-8 pt-4">
        <i
          class="fa fa-chevron-down"
          (click)="toggleDropdown('informational')"
          [ngClass]="{ rotate: InformationalOpen }"
        ></i>
        <span class="pl-2 text-xl font-bold text-primary">INFORMATIONAL</span>
      </div>
      @if (InformationalOpen ) {
        <div  class="flex">
          <div
            class="w-[100%] ml-[5%] mr-[20%] mt-[2%]"
          >
            <div class="flex justify-end">
              <label> Provider BU:</label>
              <mat-select
                placeholder="Select Provider BU"
                formControlName="providerBU"
                
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] {{
                this.uploadAssetService.metadataForm.controls['providerBU'].invalid && this.uploadAssetService.metadataForm.touched
                ? 'border-red-500'
                : 'border-gray-300' }}"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of provider_bu$  | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['providerBU'].invalid && this.uploadAssetService.metadataForm.touched) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Please provide an Provider Business Unit.
              </div>
              
            }

  
            <div class="pt-2 flex justify-end">
              <label> Asset Creator:</label>
              <input
                formControlName="asset-creator"
                class="ml-2 pl-2 border-2 border-solid rounded w-[70%] focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Add Asset Creator"
              />
            </div>
  
            <div class="flex justify-end pt-2 pb-2">
              <label>Retail Collection:</label>
              <input
                formControlName="retail-collection"
                class="ml-2 pl-2 border-2 border-solid rounded w-[70%] focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Retail Collection"
              />
            </div>
  
            <div class="flex justify-end pb-2">
              <label>Volume Label:</label>
              <input
                formControlName="volume-label"
                class="ml-2 pl-2 border-2 border-solid rounded w-[70%] focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Volume Label"
              />
            </div>
          </div>
        </div>
      }
    </div>

    <!-- DESCRIPTIVE -->
    <div class="mb-4">
      <div class="pl-8 pt-4" (click)="toggleDropdown('descriptive')">
        <i class="fa fa-chevron-down" [ngClass]="{ rotate: DescriptiveOpen }"></i>
        <span class="pl-2 text-xl font-bold text-primary">DESCRIPTIVE</span>
      </div>
      @if (DescriptiveOpen ) {
        <div  class="flex">
          <div
            class="w-[100%] ml-[5%] mr-[20%] mt-[2%]"
          >
            <div class="flex justify-end">
              <label>Media Type:</label>
              <mat-select
                placeholder="Select Media Type"
                formControlName="media-type"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] {{
                  this.uploadAssetService.metadataForm.controls['media-type'].invalid && this.uploadAssetService.metadataForm.touched
                    ? 'border-red-500'
                    : 'border-gray-300'
                }}"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of MediaType$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['media-type'].invalid && this.uploadAssetService.metadataForm.touched) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Asset Type/ Media Type cannot be empty.
              </div>
              
            }

  
            <div class="mt-2 flex justify-end">
              <label>Color/BW Image:</label>
              <mat-select
                placeholder="Select Color/B&W"
                formControlName="image-color-or-bw"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of ImageColor$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
  
            <div class="mt-2 flex justify-end">
              <label>Color/BW Video:</label>
              <mat-select
                placeholder="Select Color/B&W"
                formControlName="video-color-or-bw"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of VideoColor$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
  
            <div class="mt-2 flex justify-end">
              <label>Disability:</label>
              <mat-select
                placeholder="Select option"
                formControlName="disability"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of Disability$   | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
  
            <div class="mt-2 flex justify-end">
              <label>Video Sound:</label>
              <mat-select
                placeholder="Select option"
                formControlName="video-sound"
                class="multipleSelect ml-2 pl-2 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of VideoSound$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      }
    </div>
  </form>
</div>


