import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dal-shared-ui-video-upload-thumbnail',
  templateUrl: './video-upload-thumbnail.component.html',
  styleUrl: './video-upload-thumbnail.component.scss',
})

export class VideoUploadThumbnailComponent implements OnInit {

  @Input() file: File;
  thumbnailUrl: string | null = null;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['file'] && this.file) {
      this.generateThumbnail();
    }
  }

  ngOnInit() {
  }

  generateThumbnail() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (!context) return;

    video.src = URL.createObjectURL(this.file);
    video.muted = true;
    video.playsInline = true;

    video.addEventListener('loadeddata', () => {
      video.currentTime = 1; // Capture a frame at 1 second
    });

    video.addEventListener('seeked', () => {
      canvas.width = video.videoWidth / 2; // Scale down if needed
      canvas.height = video.videoHeight / 2;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      this.thumbnailUrl = canvas.toDataURL('image/png');
      URL.revokeObjectURL(video.src); // Cleanup
    });
  }

}
