import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetType } from '../../interfaces/asset.interface';

@Component({
  selector: 'dal-shared-ui-assetpreview',
  templateUrl: './assetpreview.component.html',
  styleUrl: './assetpreview.component.scss',
})
export class AssetpreviewComponent{
@Input() mediaFiles: any[] = []
@Input() assetTypeId: number;
showCloseButton = false;
url: any;
assetType: any;

constructor(private router: Router, private route: ActivatedRoute, ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.url = params.get('imageUrl');
      this.assetTypeId = parseInt(params.get('assetType') || '0', 10);
    });
  }

  getMediaType(): string {
    switch (this.assetTypeId) {
      case 1:
        return AssetType[1].toLowerCase();
      case 2:
        return AssetType[2].toLowerCase();
      case 3:
        return AssetType[3].toLowerCase();
      case 4:
        return AssetType[4].toLowerCase();
      default:
        return AssetType[5].toLowerCase();
    }
  }

  openImageInNewTab(url: any): void {
    window.open(`/preview?imageUrl=${encodeURIComponent(url)}&assetType=${this.assetTypeId}`, '_blank');
  }

  isPreviewRoute(): boolean {
    return this.router.url.includes('/preview?imageUrl');
  }

  isPreviewOpen: boolean = false;

  openPreview(mediaPath: string) {
    this.isPreviewOpen = true;
  }

  closePreview() {
    this.isPreviewOpen = false;
    window.close();
  }

}
