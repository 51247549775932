import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'dal-shared-ui-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrl: './search-filter.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SearchFilterComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() filters: any;
  @Input() showKeywordSearch: boolean = true;
  @Input() showFilters: boolean = true;
  @Output() CheckedFilters: EventEmitter<any[]> = new EventEmitter();
  @Output() KeywordSearch: EventEmitter<string> = new EventEmitter();
  @Output() ToggleFilterSection: EventEmitter<boolean> = new EventEmitter();
  filteredFilters: any[] = [];
  accordian: boolean = false;
  keyword = new FormControl('');

  ngOnInit() {
    this.filteredFilters = [...this.filters];
  }
  toggleFilterSection() {
    this.ToggleFilterSection.emit(false);
  }
  // updateFiltersList(): void {
  //   const checkedFilters = this.filters
  //     .filter((item: any) => item.inputs
  //       .filter((input: any) => input.value)
  //       .length)
  //     .map((item: any) => item.inputs
  //       .filter((input: any) => input.value)
  //       .map((input: any) => input.name))
  //     .flat();
  //   this.CheckedFilters.emit(checkedFilters);
  // }

  updateFiltersList(): void {
    const checkedFilters = this.filters
      .map((filter: any) => {
        const selectedInputs = filter.inputs
          .filter((input: any) => input.value)
          .map((input: any) => input.name);
        return selectedInputs.length
          ? { title: filter.title, inputs: selectedInputs }
          : null;
      })
      .filter((filter: any) => filter !== null);

    this.CheckedFilters.emit(checkedFilters);
  }

  // filterByKeyword() {
  //   const searchTerm = (this.keyword.value || '').toLowerCase();
  //   if (!searchTerm) {
  //     this.filteredFilters = [...this.filters];
  //     return;
  //   }
  //   this.filteredFilters = this.filters
  //     .map((filter: { inputs: any[] }) => {
  //       const filteredInputs = filter.inputs.filter((input) =>
  //         input.name.toLowerCase().includes(searchTerm)
  //       );
  //       return filteredInputs.length > 0
  //         ? { ...filter, inputs: filteredInputs }
  //         : null;
  //     })
  //     .filter((filter: null) => filter !== null);
  // }

  onSearch() {
    const searchTerm = this.keyword.value ? this.keyword.value.trim() : '';
    this.KeywordSearch.emit(searchTerm);
  }

}
