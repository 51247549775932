.container {
  border: 1px solid #b9b9b9;
  max-width: 484px;
  display: flex;
  padding: 10px;
  position: relative;
}

.main {
  display: flex;
  flex-wrap: wrap;
}

.image {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.image a {
  display: block;
  width: 100%;
}

.icons {
  position: absolute;
  bottom: 0;
  display: flex;
  gap: 10px;
}

.text-sub {
  font-weight: 600;
  font-size: 10px;
}

.text {
  font-weight: 400;
  font-size: 10px;
  padding: 0 0 4px 0;
  line-height: 10px;
}

.content {
  width: 25%;
  overflow-wrap: break-word;
  padding-left: 15px;
  text-align: left;
}

.main {
  .icon {
    margin-left: 5px;
    color: #265867;
  }
}

.mat-icon {
  margin: 0 5px 0 0;
}

.paginate-section {
  // height: 40px;
  line-height: 35px;
  padding: 5px 14px;
  .mat-mdc-form-field-infix,
  .mdc-text-field {
    margin-top: 1px;
    min-height: 0px !important;
    max-height: 30px;
    overflow: hidden;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%; 
    left: 55%; 
    transform: translate(-50%, -50%); 
    z-index: 1000;
    background: transparent;
  }
  
  .spinner {
    width: 50px;
    height: 50px;
    animation: rotate 2s linear infinite;
  }
  
  .spinner .path {
    stroke: grey;
    stroke-width: 4;
    stroke-dasharray: 4, 10;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 4, 10;
      stroke-dashoffset: -20;
    }
    100% {
      stroke-dasharray: 4, 10;
      stroke-dashoffset: -100;
    }
  }
  
  .no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh; 
    font-size: 1.5rem;
    color: #777; 
    margin: 0 auto;
    text-align: center;
    width: 200%;
    position: absolute; 
    top: 50%;
    left: 50%; 
    transform: translate(-50%, -50%); 
  }
  
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
