import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router ,ActivatedRoute} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SearchStateService {
  private searchTermSubject = new BehaviorSubject<string>('');
  private filterTermSubject = new BehaviorSubject<string>('');
  searchTerm$ = this.searchTermSubject.asObservable();
  filterTerm$ = this.filterTermSubject.asObservable();
  constructor( private route: Router, private activatedRoute: ActivatedRoute) {}

   updateSearchTerm(term: string) {
    const filter = this.activatedRoute.snapshot.queryParamMap.get('filter') || '';
    this.route.navigate(["/search"], { queryParams: { search: term,filter:filter } });
    this.searchTermSubject.next(term);
  }

   setFilterTerm(term: string) {
    const search = this.activatedRoute.snapshot.queryParamMap.get('search') || '';
    this.route.navigate(["/search"], { queryParams: { search: search,filter:term } });

    this.filterTermSubject.next(term);
  }
  getCombinedQuery(): string {
    return `${this.searchTermSubject.getValue()} ${this.filterTermSubject.getValue()}`.trim();
  }
}
