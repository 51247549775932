import { Route } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ActivityQueuePageComponent } from './components/activityQueuePage/activityQueuePage.component';
import { SearchResultComponent } from './components/search-results/search-result.component';
import { AssetpreviewComponent } from '@dal/shared-ui';
import { EditAssetPageComponent } from './components/EditAssetPage/edit-asset-page.component';
import { UploadPageComponent } from './components/uploadPage/uploadPage.component';
import { UserPageComponent } from './components/userPage/userPage.component';
import { EditUserPageComponent } from './components/editUserPage/editUserPage.component';
import { OktaCallbackComponent } from './components/oktaCallback/oktaCallback.component'; // Add this line


import { OktaAuthService } from '@dal/core';
import {
  MetadataTemplateAdminPageComponent
} from './components/metadataTemplateAdminPage/metadataTemplateAdminPage.component';
export const appRoutes: Route[] = [
  { path: '', title: 'Upload Files', component: HomeComponent, canActivate: [OktaAuthService] },
  { path: 'upload', title: 'Update Metadata', component: UploadPageComponent, canActivate: [OktaAuthService] },
  { path: 'activity-queue', title: 'Activity Queue', component: ActivityQueuePageComponent, canActivate: [OktaAuthService] },
  { path: 'preview', title: '', component: AssetpreviewComponent, canActivate: [OktaAuthService] },
  { path: 'search', title: 'Search Results', component: SearchResultComponent, canActivate: [OktaAuthService] },
  { path: 'edit/:id', title: 'Edit Asset', component: EditAssetPageComponent, canActivate: [OktaAuthService] },
  { path: 'users', title: 'Users', component: UserPageComponent },
  { path: 'user-edit', title: 'Edit User', component: EditUserPageComponent },
  { path: 'okta-callback', title: 'Callback', component: OktaCallbackComponent },
  { path: 'template-manager', title: 'Template Manager', component: MetadataTemplateAdminPageComponent, canActivate: [OktaAuthService] },
];
