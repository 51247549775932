
<div class="bg-white w-[100%] h-[113vh] overflow-scroll">

  <!-- DESCRIPTIVE -->
  <div class="mb-4">
    <div class="pl-8 pt-4" (click)="toggleDropdown('descriptive')">
      <i class="fa fa-chevron-down" [ngClass]="{ rotate: DescriptiveOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">DESCRIPTIVE</span>
    </div>
    <div *ngIf="DescriptiveOpen">
      <form class="w-[90%] ml-[5%] mr-[0%] mt-[2%]">

        <!-- <div *ngIf="AssetMetadata">
          <pre>{{ AssetMetadata | json }}</pre>
        </div> -->
        <div class="pl-2">
          <label>Title</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.Title}}</p>
        </div>

        <div class="pl-2 mt-4">
          <label>Description</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Descriptive?.Description }}</p>
        </div>
        <div class="pl-2 mt-4">
          <label>Temporal/Spatial</label><br>
        </div>
        <ul class="mt-2 pl-2 flex flex-wrap list-none">
          <li
            class="pl-2 pr-2 border-2 w-auto mr-2 mb-2 flex items-center justify-between">
            <!-- *ngFor="let keyword of AssetMetadata?.Metadata?.TemporalSpatial; let i = index"> -->
            <span>{{ AssetMetadata?.Descriptive?.TemporalSpatial }}</span>
          </li>
        </ul>

        <div class="mt-4 pl-2">
          <label>Keywords</label><br>
        </div>
        <ul class="mt-2 pl-2 flex flex-wrap list-none">
          <li
            class="pl-2 pr-2 border-2 w-auto mr-2 mb-2 flex items-center justify-between">
            <!-- *ngFor="let keyword of AssetMetadata?.Metadata?.Keywords; let i = index"> -->
            <span>{{ AssetMetadata?.Descriptive?.Keyword }}</span>
          </li>
        </ul>

        <div class="mt-4 pl-2">
          <label> Number of People</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.NumberOfPeople}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Age Group</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.AgeGroup}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Ethnicity</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.Ethnicity}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Gender</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.Gender}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Disability</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.Disability}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Metadata Status</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.MetadataStatus}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Finalized Flag</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.FinalizedFlag}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Metadata reviewed by</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.MetadataReviewedBy}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Metadata review completed</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.MetadataReviewCompleted}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Metadata reviewed date</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.MetadataReviewedDate}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Color/B&W Image</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.ImageColor}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Orientation</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.Orientation}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Image Type</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.ImageType}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Characteristics Image</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.CharacteristicsImage}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Subject Image</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.ImageSubjectGenre}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Color/B&W Video</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.ColorBWVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label> Video Type</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.VideoType}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Characteristics Video</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.CharacteristicsVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Subject Video</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.VideoSubjectGenre}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Video Sound</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.VideoSound}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Type</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.AudioType}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Subject Audio</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.AudioSubjectGenre}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Characteristics Audio</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Descriptive?.CharacteristicsAudio}}</p>
        </div>

      </form>
    </div>
  </div>

  <!-- INFORMATIONAL -->
  <div class="mb-4">
    <div class="pl-8 pt-4" (click)="toggleDropdown('informational')">
      <i
        class="fa fa-chevron-down" [ngClass]="{ rotate: InformationalOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">INFORMATIONAL</span>
    </div>
    <div *ngIf="InformationalOpen">
      <form class="w-[90%] ml-[5%] mr-[0%] mt-[2%]">

        <div class="pl-2">
          <label>Filename</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.Filename}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>DAL ID (UID)</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.DalID}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Media Type</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Informational?.MediaType}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Alternate File Name</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.AlternateFileName}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Source Filename</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.SourceFilename}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Asset Creator</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.AssetCreator}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Retail Collection</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.RetailCollection}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Volume Label</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.VolumeLabel}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Artistic Collection</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.ArtisticCollection}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Provider BU</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Informational?.ProviderBU}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Language</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Informational?.Language}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Date imported</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.DateImported}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Imported by</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.ImportedBy}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Last Modified Date</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.LastModifiedDate}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Last Modified by</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.LastModifiedBy}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Metadata Last Modified by</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Informational?.MetadataLastModifiedBy}}</p>
        </div>
      </form>
    </div>
  </div>

  <!-- RIGHTS -->
  <div class="mb-4">
    <div class="pl-8 pt-4">
      <i
        class="fa fa-chevron-down"
        (click)="toggleDropdown('rights')"
        [ngClass]="{ rotate: RightsOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">RIGHTS</span>
    </div>
    <div *ngIf="RightsOpen" class="flex">
      <form class="w-[90%] ml-[5%] mr-[0%] mt-[2%]">

        <div class="pl-2">
          <label>Adaptation Rights</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.AdaptationRights}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Asset Source</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.AssetSource}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Notes</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Rights?.Notes}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Rights Category</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.RightsCategory}}</p>
        </div>

        <div class="pl-2">
          <label>Copyright Holder</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Rights?.CopyrightHolder}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Displayed Credit Required</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.DisplayedCreditRequired}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Credit Text</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Rights?.CreditText}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Extended Credit Required</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.ExtendedCreditRequired}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Extended Credit Text</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Rights?.ExtendedCreditText}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Location Release</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.LocationRelease}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Model Release</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.ModelReleaseName}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>GPS Unique ID</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Rights?.GPSUniqueID}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>GPS Asset Status</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.GpsAssetStatus}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>GPS Contract Status</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.GpsContractStatus}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Business Unit Rights</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.BusinessUnitRights}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Distribution Rights</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.DistributionRights}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Marketing Use</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.MarketingUse}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Usage Restrictions</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.UsageRestriction}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>DAL Asset Status</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.DalAssetStatus}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Licensing Limitations</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Rights?.LicensingLimitations}}</p>
        </div>
      </form>
    </div>
  </div>


  <!-- TECHNICAL -->
  <div class="mb-4">
    <div class="pl-8 pt-4">
      <i
        class="fa fa-chevron-down"
        (click)="toggleDropdown('technical')"
        [ngClass]="{ rotate: TechnicalOpen }"></i>
      <span class="pl-2 text-xl font-bold text-primary">TECHNICAL</span>
    </div>
    <div *ngIf="TechnicalOpen" class="flex">
      <form class="w-[90%] ml-[5%] mr-[0%] mt-[2%]">
        <div class="pl-2">
          <label>MIME Type</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Technical?.MimeType}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Color Map</label><br>
          <p class="text-gray-500">{{AssetMetadata?.Technical?.BitmapColorMap}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Aspect Ratio</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.AspectRatio}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Rate</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.FrameRate}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Tracks Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.AudioTracksVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Codec Name Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.AudioCodecNameVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Sample Rate Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.AudioSampleRateVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Audio Sample Size Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.AudioSampleSizeVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bit Rate Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.BitRateVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Color Depth Image</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.BitmapColorDepthImage}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Height</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.BitmapHeight}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Orientation</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.BitmapOrientation}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Resolution Image</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.BitmapResolutionImage}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Bitmap Width</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.BitmapWidth}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Duration (Audio)</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.DurationAudio}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Duration (Video)</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.DurationVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>File Size</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.FileSize}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Height Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.FrameHeight}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Width Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.FrameWidth}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Frame Rate Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.FrameRate}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Number of Audio Tracks Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.AudioTracksVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Number of Video Tracks Video</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.VideoTracksVideo}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Video Codec</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.VideoCodecName}}</p>
        </div>

        <div class="mt-4 pl-2">
          <label>Video Dimension</label><br>
            <p class="text-gray-500">{{AssetMetadata?.Technical?.VideoDimension}}</p>
        </div>
      </form>
    </div>
  </div>
</div>
