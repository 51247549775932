<h1 class="text-[24px] text-primary mx-[4%] mt-[3%]">{{title}}</h1>

<div class="toolbar mx-[4%] mt-[10px] bg-[#DCDCDC] h-[80px]">
  <div class="menu-items flex flex-row">
    <div class="item py-[30px]">
      User:
    </div>
    <div class="search-box ml-[5px]">
      <span class="search-icon mt-1">
        <mat-icon>search</mat-icon>
      </span>
      <input type="text" placeholder="Search for user" class="pl-10">
    </div>
    @for (filter of displayedFilters; track filter; let index = $index) {
      @if (index > 0) {
      <div class="item py-[30px]">{{filter}}:</div>
      <div class="custom-select dropdown text-primary my-[20px] ml-[5px] h-[40px]">
        <select [value]="options[0]">
        @for (option of options; track option) {
          <option [value]="option">{{option}}</option>
        }
        </select>
      </div>
      }
    }
    <div class="item py-[30px] text-primary">
      <a href="#">[Clear All]</a>
    </div>
  </div>
</div>

<div class="mx-[4%] mt-[30px]">
  <table mat-table [dataSource]="dataSource" class="custom-border">
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    
    @for (column of displayedColumns; track column; let index = $index) {
      <ng-container matColumnDef="{{column}}">
        @if (column == 'thumbnails') {
          <th mat-header-cell *matHeaderCellDef> {{column | uppercase}} </th>
          <td mat-cell *matCellDef="let element" class="image flex flex-row"> 
            @for (thumbnail of element.thumbnails; track thumbnail; let index = $index) {
              @if (index < 3) {
                <img src="assets/{{thumbnail}}" alt="thumbnail" class="h-[40px] w-[50px] mt-3 mb-2 mr-[5px]">
              }
            }
            <div class="text mt-5">{{element.thumbnails.length}} total</div>
          </td>
        }@else if (column == 'status') {
          <th mat-header-cell *matHeaderCellDef> {{column | uppercase}} </th>
          <td mat-cell *matCellDef="let element">
            <div class="icon-text-container">
              @if (element.status == 'started') {
                <mat-icon class="mr-1" svgIcon="arrow-repeat"></mat-icon>
                <span>Uploading</span>
              }@else if (element.status == 'completed') {
                @if (dialogLink) {
                  <mat-icon class="mr-1 text-green-500">check_circle</mat-icon>
                  <button (click)="openDialog(1)" class="text-[#017890] underline">Successfully uploaded</button>
                }
              }@else if (element.status == 'failed') {
                @if (dialogLink) {
                  <mat-icon class="mr-1" svgIcon="exclamation-square-fill"></mat-icon>
                  <button (click)="openDialog(2)" class="text-[#017890] underline">Failed to Upload</button>
                }
              }
            </div>
          </td>
        }@else if (column == 'actions') {
          <th mat-header-cell *matHeaderCellDef> {{column | uppercase}} </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="actions"></mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editElement(element)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteElement(element)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </td>
        }@else {
          <th mat-header-cell *matHeaderCellDef [style.width]="(column === 'start_time' || column === 'end_time') ? '130px' : column === 'participant_business_unit' ? '200px' : 'auto'">
            @if (title == 'Users')
            {
              <div class="sort-header">
                <div class="sort-icon-container">
                  <mat-icon (click)="toggleSort('asc', column)" class="sort-icon up-arrow" 
                    [ngClass]="{'active': sortOrder === 'asc' && sortColumn === column}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 -6 24 24">
                      <path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19z"></path>
                    </svg>
                  </mat-icon>
            
                  <mat-icon (click)="toggleSort('desc', column)" class="sort-icon down-arrow" 
                    [ngClass]="{'active': sortOrder === 'desc' && sortColumn === column}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24">
                      <path d="M11.178 19.569a.998.998 0 0 0 1.644 0l9-13A.999.999 0 0 0 21 5H3a1.002 1.002 0 0 0-.822 1.569l9 13z"></path>
                    </svg>
                  </mat-icon>
                </div>
            
                <div class="sort-text">
                  {{ column.split('_').join(' ') | uppercase }}
                </div>
              </div>
            }@else {
              {{ column.split('_').join(' ') | uppercase }}
            }
          </th>
          <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
        }
      </ng-container>
    }
  </table>
</div>

<div class="mx-[20%] my-[40px]">
  <div class="flex justify-center">
    <div class="paginator text-primary flex items-center">
      <mat-icon>navigate_before</mat-icon>
      <a href="javascript:void(0);" #previous class="previous disabled-link mt-0.5" (click)="getData(currentPage - 1)">PREVIOUS</a>
      @if (currentPage - 1 > 0) {<button class="page mt-0.5 ml-3" (click)="getData(currentPage - 1)"> {{currentPage - 1}} </button>}
      <button id="currentPage" class="page mt-0.5 ml-3 square-button"> {{currentPage}} </button>
      @if (currentPage + 1 < pages) {<button class="page mt-0.5 ml-3" (click)="getData(currentPage + 1)"> {{currentPage + 1}} </button>}
      @if (currentPage + 2 < pages) {<button class="page mt-0.5 ml-3" (click)="getData(currentPage + 2)"> {{currentPage + 2}} </button>}
      @if (currentPage + 3 < pages) {<button class="page mt-0.5 ml-3" (click)="getData(currentPage + 3)"> {{currentPage + 3}} </button>}
      @if (pages - 1 > currentPage && pages - 1 > currentPage + 4) {<span class="mt-0.5 ml-3">...</span>}
      @if (pages - 1 > currentPage && pages - 1 > currentPage + 3) {<button class="page mt-0.5 ml-3" (click)="getData(pages - 1)"> {{pages-1}} </button>}
      @if (pages > currentPage && (pages > currentPage + 2 || pages > currentPage + 1 || pages > currentPage)) {<button class="page mt-0.5 ml-3" (click)="getData(pages)"> {{pages}} </button>}
      <a href="javascript:void(0);" #next class="next mt-0.5 ml-3" (click)="getData(currentPage + 1)">NEXT</a>
      <mat-icon>navigate_next</mat-icon>
    </div>
  </div>
</div>
