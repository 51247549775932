<div class="w-[100%]">
  <div class="py-1">
    <div class="text-xs">BULK METADATA </div>
  </div>
  <form class="border-2 border-solid border-grey_c text-xs p-2"
  [formGroup]="this.uploadAssetService.metadataForm">
    <!-- DESCRIPTIVE -->
    <div class="mb-4">
      <div class="cursor-pointer" (click)="toggleDropdown('descriptive')">
        <i class="fa fa-chevron-down" [ngClass]="{ rotate: DescriptiveOpen }"></i>
        <span class="pl-2 text-xl font-bold text-primary">DESCRIPTIVE</span>
      </div>
      @if (DescriptiveOpen ) {
        <div  class="flex">
          <div
            class="w-[100%] p-2"
          >
            <div class="flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Media Type <span class="text-red-500 px-1">*</span> : </label>
              <mat-select
                placeholder="Select Media Type"
                formControlName="media-type"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] disabled {{
                  this.uploadAssetService.metadataForm.controls['media-type'].invalid
                    ? 'border-red-500'
                    : 'border-gray-300'
                }}"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of MediaType$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['media-type'].invalid) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Asset Type/ Media Type cannot be empty.
              </div>
              
            }

            @if (mediaType == mediaTypeEnum.Image){
              <div class="mt-2 flex grid grid-cols-12 ">
                <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Color/BW Image : </label>
                <mat-select
                  placeholder="Select Color/B&W"
                  formControlName="image-color-or-bw"
                  class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
                >
                  <mat-option
                    class="multipleOption"
                    *ngFor="let option of ImageColor$ | async"
                    [value]="option.name"
                    ><p class="text-white">{{ option.name }}</p></mat-option
                  >
                </mat-select>
              </div>

              <div class="mt-2 flex grid grid-cols-12 ">
                <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Image Type : </label>
                <mat-select
                  placeholder="Select Image Type"
                  formControlName="image-type"
                  class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
                >
                  <mat-option
                    class="multipleOption"
                    *ngFor="let option of ImageType$ | async"
                    [value]="option.name"
                    ><p class="text-white">{{ option.name }}</p></mat-option
                  >
                </mat-select>
              </div>
            }
            
            @if (mediaType == mediaTypeEnum.Video){
              <div class="mt-2 flex grid grid-cols-12 ">
                <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Color/BW Video : </label>
                <mat-select
                  placeholder="Select Color/B&W"
                  formControlName="video-color-or-bw"
                  class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
                >
                  <mat-option
                    class="multipleOption"
                    *ngFor="let option of VideoColor$ | async"
                    [value]="option.name"
                    ><p class="text-white">{{ option.name }}</p></mat-option
                  >
                </mat-select>
              </div>

              <div class="mt-2 flex grid grid-cols-12 ">
                <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Video Sound : </label>
                <mat-select
                  placeholder="Select option"
                  formControlName="video-sound"
                  class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
                >
                  <mat-option
                    class="multipleOption"
                    *ngFor="let option of VideoSound$ | async"
                    [value]="option.name"
                    ><p class="text-white">{{ option.name }}</p></mat-option
                  >
                </mat-select>
              </div>

              <div class="mt-2 flex grid grid-cols-12 ">
                <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Video Type : </label>
                <mat-select
                  placeholder="Select Video Type"
                  formControlName="video-type"
                  class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
                >
                  <mat-option
                    class="multipleOption"
                    *ngFor="let option of VideoType$ | async"
                    [value]="option.name"
                    ><p class="text-white">{{ option.name }}</p></mat-option
                  >
                </mat-select>
              </div>
            }

            @if (mediaType == mediaTypeEnum.Audio){
              <div class="mt-2 flex grid grid-cols-12 ">
                <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Audio Type : </label>
                <mat-select
                  placeholder="Select Audio Type"
                  formControlName="audio-type"
                  class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
                >
                  <mat-option
                    class="multipleOption"
                    *ngFor="let option of AudioType$ | async"
                    [value]="option.name"
                    ><p class="text-white">{{ option.name }}</p></mat-option
                  >
                </mat-select>
              </div>
            }
            
            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Disability : </label>
              <mat-select
                placeholder="Select option"
                formControlName="disability"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of Disability$   | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      }
    </div>

    <!-- INFORMATIONAL -->
    <div class="mb-4">
      <div class="cursor-pointer">
        <i
          class="fa fa-chevron-down"
          (click)="toggleDropdown('informational')"
          [ngClass]="{ rotate: InformationalOpen }"
        ></i>
        <span class="pl-2 text-xl font-bold text-primary">INFORMATIONAL</span>
      </div>
      @if (InformationalOpen ) {
        <div  class="flex">
          <div
            class="w-[100%] p-2"
          >
            <div class="flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Provider BU <span class="text-red-500 px-1">*</span> : </label>
              <mat-select
                placeholder="Select Provider BU"
                formControlName="provider-bu"
                
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] {{
                this.uploadAssetService.metadataForm.controls['provider-bu'].invalid
                ? 'border-red-500'
                : 'border-gray-300' }}"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of provider_bu$  | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['provider-bu'].invalid) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Please provide an Provider Business Unit.
              </div>
              
            }

  
            <div class="pt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Asset Creator : </label>
              <input
                formControlName="asset-creator"
                class="border-2 border-solid rounded fields-width col-span-12 sm:col-span-9 focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Add Asset Creator"
              />
            </div>
  
            <div class="flex grid grid-cols-12  pt-2 pb-2">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Retail Collection : </label>
              <input
                formControlName="retail-collection"
                class="border-2 border-solid rounded fields-width col-span-12 sm:col-span-9 focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Retail Collection"
              />
            </div>
  
            <div class="flex grid grid-cols-12  pb-2">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Volume Label : </label>
              <input
                formControlName="volume-label"
                class="border-2 border-solid rounded fields-width col-span-12 sm:col-span-9 focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Volume Label"
              />
            </div>
          </div>
        </div>
      }
    </div>

    <div class="mb-4">
      <div class="cursor-pointer">
        <i
          class="fa fa-chevron-down"
          (click)="toggleDropdown('rights')"
          [ngClass]="{ rotate: RightsOpen }"
        ></i>
        <span class="pl-2 text-xl font-bold text-primary">RIGHTS</span>
      </div>
      @if (RightsOpen) {
        <div class="flex">
          <div
            class="w-[100%] p-2"
          >
            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Asset Source <span class="text-red-500 px-1">*</span> : </label>
              <mat-select
                formControlName="asset-source"
                placeholder="Select Asset Source"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] {{
                  this.uploadAssetService.metadataForm.controls['asset-source'].invalid
                    ? 'border-red-500'
                    : 'border-gray-300'
                }}"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let source of assetSources$ | async"
                  [value]="source.name"
                  ><p class="text-white">{{ source.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['asset-source'].invalid) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Please provide an Asset Source.
              </div>
            }
            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Rights Category <span class="text-red-500 px-1">*</span> : </label>
              <mat-select
                placeholder="Select Right Category"
                formControlName="rights-category"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] {{
                  this.uploadAssetService.metadataForm.controls['rights-category'].invalid
                    ? 'border-red-500'
                    : 'border-gray-300'
                }}">
                <mat-option
                  class="multipleOption"
                  *ngFor="let category of RightsCategory$ | async"
                  [value]="category.name"
                  ><p class="text-white">{{ category.name }}</p></mat-option
                >
              </mat-select>
            </div>
            @if (this.uploadAssetService.metadataForm.controls['rights-category'].invalid) {
              <div
                class="pl-[30%] text-red-500 text-sm"
              >
                Please provide an Right Category.
              </div>
              
            }
            
            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Adaptation Rights : </label>
              <mat-select
                placeholder="Select Adaptation Right"
                formControlName="adaptation-rights"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let right of AdaptationRights$ | async"
                  [value]="right.name"
                  ><p class="text-white">{{ right.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Business Unit Rights : </label>
              <mat-select
                placeholder="Select Unit Right"
                formControlName="business-unit-rights"
                multiple
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let right of BusinessUnitRights$ | async"
                  [value]="right.name"
                  ><p class="text-white">{{ right.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Licensing Limitations : </label>
              <mat-select
                placeholder="Select Licensing Limitations"
                formControlName="licensing-limitations"
                multiple
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let ll of LicensingLimitations$ | async"
                  [value]="ll.name"
                  ><p class="text-white">{{ ll.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Marketing Use : </label>
              <mat-select
                placeholder="Select Marketing Use"
                formControlName="marketing-use"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let mu of MarketingUse$ | async"
                  [value]="mu.name"
                  ><p class="text-white">{{ mu.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="pt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> GPS Unique ID : </label>
              <input
                formControlName="gps"
                class="border-2 border-solid rounded fields-width col-span-12 sm:col-span-9 focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Add GPS ID"
              />
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Credit Text : </label>
              <input
                formControlName="credit-text"
                class="border-2 border-solid rounded fields-width col-span-12 sm:col-span-9 focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Edit Credit Text"
              />
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Model Release : </label>
              <mat-select
                placeholder="Select Model Release"
                formControlName="model-release"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of ModelRelease$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end">Location Release : </label>
              <mat-select
                placeholder="Select Location Release"
                formControlName="location-release"
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let option of LocationRelease$ | async"
                  [value]="option.name"
                  ><p class="text-white">{{ option.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Usage Restriction : </label>
              <mat-select
                placeholder="Select Usage"
                formControlName="usage-restriction"
                multiple
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let usage of UsageRestriction$  | async"
                  [value]="usage.name"
                  ><p class="text-white">{{ usage.name }}</p></mat-option
                >
              </mat-select>
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Notes : </label>
              <input
                formControlName="notes"
                class="border-2 border-solid rounded fields-width col-span-12 sm:col-span-9 focus:outline-none focus:border-blue-500 'border-gray-300'"
                placeholder="Add Notes"
              />
            </div>

            <div class="mt-2 flex grid grid-cols-12 ">
              <label class="items-center col-span-12 sm:col-span-3 flex justify-start sm:justify-end"> Distribution Rights : </label>
              <mat-select
                placeholder="Select Distribution Right"
                formControlName="distribution-rights"
                multiple
                class="multipleSelect fields-width col-span-12 sm:col-span-9 border-2 border-solid rounded h-[25px] border-gray-300"
              >
                <mat-option
                  class="multipleOption"
                  *ngFor="let right of DistributionRights$ | async"
                  [value]="right.name"
                  ><p class="text-white">{{ right.name }}</p></mat-option
                >
              </mat-select>
            </div>
          </div>
        </div>
      }
    </div>
  </form>
</div>
