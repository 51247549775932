import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  // Function to generate a random UUID
  generateUuid(): string {
    return uuidv4().replace(/-/g, '');
  }

  computeMd5Hash(input: string): string {
    return CryptoJS.MD5(input).toString(CryptoJS.enc.Hex);
  }


}

