<div class="w-[38%] fixed top-inherit right-[0.5%] bg-primary border border-gray-300 p-[10px] z-[99] shadow-md rounded-md">
  <img src="assets/cross.svg" alt="active queue" class="close-button absolute top-[2px] right-[2px] h-6 w-6" (click)="closeModal()"/>  
  <div class="flex items-center mb-[10px]">
    <img src="assets/spinner-loader.svg" alt="spinner" class="loading-logo h-[30px] w-[30px] m-0 mr-5">
    <h2 class="m-0 mb-[0.4em] text-white mt-[5px] text-2xl">Activities Queue
      <span class="italic text-sm ml-5 tracking-wide">( {{inProgress}} items in progress )</span></h2>
  </div>
  <ul class="list-none p-0 mb-2">
    <li *ngFor="let activity of activities" class="activity p-4 mb-1 border-b border-gray-300 flex justify-between items-center activity-li rounded-md">
        <a [routerLink]="['/activity-queue', activity.id]">{{ activity.id }}; {{ activity.date }}; {{ activity.name }} </a>
        <span class="activity-status ml-auto font-bold text-[#1f6715]">
          {{ activity.status }}
        </span>
    </li>
  </ul>
  <p class="view-activity flex justify-end mb-0 text-white">
    <a routerLink="/activity-queue">View Activities Queue</a>
  </p>
</div>
