import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private roles = ['Researcher', 'Fulfiller', 'Power Archivist'];

  getRoles(): string[] {
    return this.roles;
  }
}
