<dal-shared-ui-tabular-data
[title]="title"
[displayedFilters]="displayedFilters"
[dataSource]="dataSource"
[currentPage]="currentPage"
[pages]="pages"
[options]="options"
[displayedColumns]="displayedColumns"
[dialogLink]="dialogLink"
(pageChanged)="getData($event)"
></dal-shared-ui-tabular-data>
