import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'dal-shared-ui-tabular-data',
  templateUrl: './tabular-data.component.html',
  styleUrl: './tabular-data.component.scss',
})

export class TabularDataComponent implements AfterViewInit {
  @ViewChild('previous') myElement: ElementRef;
  @ViewChild('next') myElement2: ElementRef;

  @Input() dataSource: any[] = [];
  @Input() currentPage: number = 1;
  @Input() pages: number = 0;
  @Input() title: string;
  @Input() options: string[];
  @Input() displayedColumns: string[];
  @Input() displayedFilters: string[];
  @Input() dialogLink = false;
  element: any;
  element2: any;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() dialogOpened = new EventEmitter<number>();

  constructor(private router: Router, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('arrow-repeat', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow-repeat.svg'));
    iconRegistry.addSvgIcon('sort-alt', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/sort-alt.svg'));
    iconRegistry.addSvgIcon('actions', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/actions.svg'));
    iconRegistry.addSvgIcon('exclamation-square-fill', sanitizer.bypassSecurityTrustResourceUrl('/assets/icons/exclamation-square-fill.svg'));
  }

  ngAfterViewInit() {
    this.element = this.myElement.nativeElement;
    this.element2 = this.myElement2.nativeElement;
  }

  getData(page: number) {
    if(page > 1) this.element.classList.remove('disabled-link');
    if(page == 1) this.element.classList.add('disabled-link');

    if(page == this.pages) this.element2.classList.add('disabled-link');
    if(page < this.pages) this.element2.classList.remove('disabled-link');

    this.pageChanged.emit(page);
  }
  
  sortOrder: string = '';
  sortColumn: string = '';

  toggleSort(order: string, column: string) {
    if (this.sortColumn === column && this.sortOrder === order) {
      // If the column is already sorted in this order, reset
      this.sortOrder = '';
      this.sortColumn = '';
    } else {
      this.sortOrder = order;
      this.sortColumn = column;
    }
  }

   // Function to handle edit action
   editElement(element: any) {
    this.router.navigate(['/edit']); // Adjust the route as per your routing setup
  }

  // Function to handle delete action
  deleteElement(element: any) {
    if (confirm('Are you sure you want to delete this item?')) {
      // Add your delete logic here
    }
  }

  openDialog(row: number) {
    this.dialogOpened.emit(row);
  }
}
