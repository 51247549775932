<div class="paginate-section bg-secondary text-xs text-white grid grid-cols-3 items-center flex">
  <div class="grid-cols-1">
    SORT BY:
    <mat-form-field appearance="outline" disableOptionCentering class="w-[120px]">
      <mat-select (valueChange)="onSortChange($event)">
        @for (option of sortBy; track option) {
        <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="grid-cols-1"></div>

  <div class="grid-cols-1 justify-end flex ">
    {{ searchCount }} ASSET{{ searchCount !== 1 ? 'S' : '' }}
    <span>
      <i class="bi bi-chevron-left m-1 cursor-pointer" (click)="onPageChange(-1)" [ngClass]="{ 'text-gray-400': currentPage === 1 }"></i>
    </span>
    <input class="rounded w-[30px] pl-2 text-black" type="number" 
      [(ngModel)]="currentPage" (ngModelChange)="onPageChange(currentPage, true)" min="1" [max]="totalPages"
      [ngClass]="{ 'text-red-800 font-bold': currentPage < 1 || currentPage > totalPages }" />
    <span class="mx-[10px]">of {{totalPages}}
      <i class="bi bi-chevron-right cursor-pointer" (click)="onPageChange(1)" [ngClass]="{ 'text-gray-400': currentPage === totalPages }"></i>
    </span>
  </div>
  <div *ngIf="loading" class="loading-spinner">
    <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
      <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
    </svg>
  </div>

</div>
<div *ngIf="!loading">
  <div class="main ">
    @for (data of dataSource; track data) {
    <div class="m-5 container">
      <div class="flex">
        <div class="image p-2">
          <a [routerLink]="['/edit', data.dal_id]" matTooltip="View Metadata Record" matTooltipPosition="above">
          <dal-shared-ui-media-renderer [url]="getThumbnailUrl(data)" [authenticateUrl]="data.asset_type.id !== 3" [type]="'image'"></dal-shared-ui-media-renderer>
          </a>
          <div class="icons pt-2">
            <a href="#" matTooltip="Add to Carousel" matTooltipPosition="above">
            <mat-icon
              svgIcon="add-circle"
              aria-hidden="false"
              aria-label="Add"
            ></mat-icon>
            </a>
          <a [routerLink]="['/edit', data.dal_id]" [queryParams]="{ edit: true }" matTooltip="Edit Metadata Record" matTooltipPosition="above">
            <mat-icon
              svgIcon="edit-pencil"
              aria-hidden="false"
              aria-label="Edit"
            ></mat-icon>
            </a>
            <a href="#" matTooltip="Attached Preview" matTooltipPosition="above">
            <mat-icon
              svgIcon="attach"
              aria-hidden="false"
              aria-label="attachment"
            ></mat-icon>
            </a>
            <a href="#" matTooltip="Play" matTooltipPosition="above">
            <mat-icon
              svgIcon="transcode"
              aria-hidden="false"
              aria-label="Transcode"
            ></mat-icon>
            </a>
            <a href="#" matTooltip="Delete" matTooltipPosition="above">
            <mat-icon
              svgIcon="trash"
              aria-hidden="false"
              aria-label="Trash"
            ></mat-icon>
            </a>
          </div>
        </div>
        <div class="content">
          <p class="text-sub">FILENAME</p>
          <p class="text">{{ data.filename }}</p>
          <p class="text-sub pt-0.5">GPS ID</p>
          <p class="text">{{ data.gps_id }}</p>
          <p class="text-sub pt-0.5">DAL ID</p>
          <p class="text">{{ data.dal_id }}</p>
          <p class="text-sub pt-0.5">TITLE</p>
          <p class="text">{{ data.title }}</p>
          <p class="text-sub pt-0.5">ASSET SOURCE</p>
          <p class="text">{{ data.asset_source.name }}</p>
          <p class="text-sub pt-0.5">RIGHTS CATEGORY</p>
          <p class="text">{{ data.rights_category }}</p>
          <p class="text-sub pt-0.5">FINALIZED FLAG</p>
          <p class="text">{{ data.finalized }}</p>
          <p class="text-sub pt-0.5">USUAGE RESTRICTIONS</p>
          <p class="text">{{ data.usage_restriction }}</p>
        </div>
      </div>
    </div>
    }
  </div>
  <div *ngIf="dataSource.length === 0" class="no-results">
    No Results Found
  </div>
</div>
