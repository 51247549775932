<div class="w-[100%] border border-gray-300">
  <form [formGroup]="uploadAssetService.assetPreviewForm">
    <div formArrayName="files">
      @for (file of fileControls; track file;let i = $index) {

      <div [formGroupName]="i" class="mb-2 p-2 grid grid-cols-4 border-2 grid-cols-custom "
        >
        <div class="align-center p-2">
          <img [src]="file.value.src" [alt]=file.value.fileName
            class="object-fill h-10 w-full" />
        </div>

        <div class="ml-2 flex flex-col text-left">
          <input type="text" class="border-2 text-m max-w-auto rounded mt-4 mr-8 
            {{
              file.get('fileName')?.invalid && file.get('fileName')?.touched
                ? 'border-red-500'
                : ''
            }}" formControlName="fileName" style="background-color: inherit"  />

          @if ( file.get('fileName')?.hasError('required') &&
                file.get('fileName')?.touched) {
            <p class="text-red-500 text-xs">Filename Cannot be Empty!</p>
          }
        </div>

        <div class="flex flex-col text-left">
          <input type="text" class="border-2 text-m max-w-[80%] rounded mt-4 
          {{
              file.get('title')?.hasError('required') &&
              file.touched
                ? 'border-red-500'
                : ''
          }}" formControlName="title" style="background-color: inherit" />
          @if ( file.get('title')?.hasError('required') &&
                file.touched) {
            <p class="text-red-500 text-xs">Title Cannot be Empty!</p>
          }
        </div>

        <div class="flex items-center p-2 justify-center">
          <span class="text-primary cursor-pointer" (click)="deleteImage(i)">
            <mat-icon class="text-base">clear</mat-icon>
          </span>
        </div>
      </div>
      }
    </div>
  </form>
</div>
