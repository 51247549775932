// src/app/sse.service.ts
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@dal/core';

@Injectable({
  providedIn: 'root'
})
export class SseService {
  private baseUrl: string = environment.apiBaseUrl;

  constructor(private zone: NgZone) { }

  getJobStepsStream(): Observable<any> {
    return new Observable(observer => {
      const eventSource = new EventSource(`${this.baseUrl}/jobs/stream`);
      
      eventSource.onmessage = (event) => {
        this.zone.run(() => {
          observer.next(JSON.parse(event.data));
        });
      };

      eventSource.onerror = (error) => {
        this.zone.run(() => {
          observer.error(error);
        });
        eventSource.close();
      };

      return () => {
        eventSource.close();
      };
    });
  }
}
