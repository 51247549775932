import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchStateService {
  private searchTermSubject = new BehaviorSubject<string>('');
  private filterTermSubject = new BehaviorSubject<string>('');
  searchTerm$ = this.searchTermSubject.asObservable();
  filterTerm$ = this.filterTermSubject.asObservable();

  updateSearchTerm(term: string) {
    this.searchTermSubject.next(term);
  }

  setFilterTerm(term: string): void {
    this.filterTermSubject.next(term);
  }
  getCombinedQuery(): string {
    return `${this.searchTermSubject.getValue()} ${this.filterTermSubject.getValue()}`.trim();
  }
}
