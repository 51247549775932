import { Component, OnInit } from '@angular/core';
import { MetadataService } from '@dal/core';
import { Observable, catchError, map, of } from 'rxjs';
import { TemplateDetails } from '../../models/asset.metadata.model';

@Component({
  selector: 'dal-metadata-template-admin-page',
  templateUrl: './metadataTemplateAdminPage.component.html',
  styleUrls: ['./metadataTemplateAdminPage.component.scss'],
})

export class MetadataTemplateAdminPageComponent implements OnInit {

  templates: TemplateDetails[] = [];
  templates$: Observable<TemplateDetails[]>;
  selectedTemplate: TemplateDetails | null = null;

  metadataFieldsVisible = false;
  supportedMimeTypesVisible = false;

  constructor(private templateService: MetadataService) {}

  ngOnInit(): void {
    this.templates$ = this.templateService.getAllMetadataTemplates().pipe(
      map(templates => templates.map((template: { fields: any[]; }) => ({
        ...template,
        fields: template.fields.map(field => ({
          ...field,
          metadata_type: field.metadata_type || {
            id: -1,
            name: 'Unknown',
            key: '',
            description: ''
          },
        })),
      }))),
      catchError(error => {
        console.error('Error fetching templates:', error);
        return of([]);
      })
    )
  }
}
