<div>
  <h3 class="mx-[2%] my-[1%] font-normal text-[12px]">{{heading}}</h3>
  <div class="flex">
    <mat-form-field class="custom-mat-form-field dense-3 ml-[2%] mr-[1%] w-[25rem]" appearance="outline">
      <mat-label class="text-m">{{label}}</mat-label>
      <mat-icon matTextPrefix class="mt-[15px] mr-[12px]">search</mat-icon>
      <mat-select class="mt-[1px] mr-[1px] text-m p-0" [value]="selectedTemplate" (selectionChange)="onSelectionChange($event)">
        @if (showClearOption ) {
          <mat-option >{{label}}</mat-option>
        }
        @for (template of templates; track template.name) {
          <mat-option class="mat-option" [value]=template?.name >{{template?.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="mt-4 relative">
      <span
        class="material-symbols-outlined p-0 cursor-pointer hover:text-primary"
        [matTooltip]="infoText">
        info
      </span>
    </div>
  </div>
</div>
