import { Injectable } from '@angular/core';
import { AccessToken, IDToken, OktaAuth, TokenResponse } from '@okta/okta-auth-js';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { UserClaims } from '../types/userclaims.interface';

@Injectable({
  providedIn: 'root'
})
export class OktaAuthService implements CanActivate {
  private oktaAuth: OktaAuth | null = null;

  constructor(private router: Router) {
    if (environment.oktaEnabled) {
      // Initialize OktaAuth only if oktaEnabled is true
      this.oktaAuth = new OktaAuth({
        clientId: environment.oktaClientId,
        issuer: environment.oktaIssuer,
        redirectUri: environment.oktaRedirectUri,
        scopes: environment.oktaScopes,
        pkce: true,
      });
    }
  }

  // Start the login process, redirecting to Okta
  async login(): Promise<void> {
    if (environment.oktaEnabled && this.oktaAuth) {
      await this.oktaAuth.signInWithRedirect();
    } else {
      console.log('Login called but Okta is disabled in local environment.');
    }
  }

  // Handle the callback after user authentication
  async handleCallback(): Promise<void> {
    if (environment.oktaEnabled && this.oktaAuth) {
      try {
        const tokenResponse: TokenResponse = await this.oktaAuth.token.parseFromUrl();
        if (tokenResponse && tokenResponse.tokens) {
          const { idToken, accessToken } = tokenResponse.tokens;

          // Store the tokens in the Okta token manager
          if (idToken) {
            this.oktaAuth.tokenManager.add('idToken', idToken);
          }
          if (accessToken) {
            this.oktaAuth.tokenManager.add('accessToken', accessToken);
          }
        }
        // Redirect to the home page or a secure route
        await this.router.navigate(['/']);
      } catch (error) {
        console.error('Error handling the callback:', error);
        this.oktaAuth.clearStorage();
        // Redirect to home page if there was an error in callback handling
        await this.router.navigate(['/']);
      }
    } else {
      console.log('Callback handling skipped as Okta is disabled in local environment.');
    }
  }

  // Checks if the user is authenticated
  async isAuthenticated(): Promise<boolean> {
    if (!environment.oktaEnabled) {
      // Assume the user is authenticated in local development
      return true;
    }

    try {
      const accessToken = await this.oktaAuth?.tokenManager.get('accessToken');
      return this.isAccessToken(accessToken);
    } catch (error) {
      console.error('Error checking authentication status:', error);
      return false;
    }
  }

  // Log out the user and clear the session
  async logout(): Promise<void> {
    if (environment.oktaEnabled && this.oktaAuth) {
      await this.oktaAuth.signOut({
        postLogoutRedirectUri: environment.oktaPostLogoutRedirectUri,
      });
      sessionStorage.removeItem('okta-token-storage');
      await this.router.navigate([environment.oktaPostLogoutRedirectUri]);
    } else {
      console.log('Logout called but Okta is disabled in local environment.');
    }
  }

  // Retrieve the current access token
  async getAccessToken(): Promise<string | undefined> {
    if (!environment.oktaEnabled) {
      // Return a dummy token or undefined in local development
      return 'dummy-access-token';
    }

    try {
      const token = await this.oktaAuth?.tokenManager.get('accessToken');
      if (this.isAccessToken(token)) {
        return token.accessToken;
      }
      return undefined;
    } catch (error) {
      console.error('Error retrieving access token:', error);
      return undefined;
    }
  }

  // Retrieve user information (claims)
  async getUser(): Promise<UserClaims | undefined> {
    if (!environment.oktaEnabled) {
      // Return dummy user information in local development
      return {
        sub: 'local-user',
        name: 'Local Dev User',
        email: 'localuser@example.com',
      } as UserClaims;
    }

    try {
      const token = await this.oktaAuth?.tokenManager.get('idToken');
      if (this.isIDToken(token)) {
        return token.claims as UserClaims;
      }
      return undefined;
    } catch (error) {
      console.error('Error retrieving user information:', error);
      return undefined;
    }
  }

  // Type Guard to check if the token is an AccessToken and is not expired
  private isAccessToken(token: unknown): token is AccessToken {
    if (typeof token === 'object' && token !== null && 'accessToken' in token) {
      const accessToken = token as AccessToken;
      const payload = this.decodeJwt(accessToken.accessToken);

      if (payload && payload.exp) {
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        return currentTime < payload.exp; // return false if the token is expired
      }
    }
    return false;
  }

  // Helper function to decode a JWT and get its payload
  private decodeJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error('Error decoding JWT:', e);
      return null;
    }
  }

  // Type Guard to check if the token is an IDToken
  private isIDToken(token: unknown): token is IDToken {
    return typeof token === 'object' && token !== null && 'idToken' in token;
  }

  // Route guard to protect pages requiring authentication
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!environment.oktaEnabled) {
      // Allow all routes in local development
      return true;
    }

    return this.isAuthenticated().then((isAuthenticated) => {
      if (!isAuthenticated) {
        this.login(); // Redirect to login if not authenticated
        return false;
      }
      return true;
    });
  }
}
