import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const oktaTokenStorage = localStorage.getItem('okta-token-storage');
    let accessToken: string | undefined;

    if (oktaTokenStorage) {
      try {
        // Parse the JSON data stored in localStorage
        const parsedData = JSON.parse(oktaTokenStorage);

        // Safely access the idToken and accessToken if they exist
        accessToken = parsedData?.accessToken?.accessToken;
      } catch (error) {
        console.error('Failed to parse Okta token storage data:', error);
      }
    }

    if (accessToken && req.url.includes(environment.apiBaseUrl)) {
      const cloned = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

