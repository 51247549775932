export const TABLE_DATA = [
  {name: 'Sai Kiran Thotakura', email_name: 'sai@gmail.com', participant_business_unit: 'SEG-ALL', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Kiran', email_name: 'kiran@gmail.com', participant_business_unit: 'SEG-ALL', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Sai', email_name: 'sai@gmail.com', participant_business_unit: 'HPI', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Kiran', email_name: 'kiran@gmail.com', participant_business_unit: 'HPI', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Kitty', email_name: 'kitty@gmail.com', participant_business_unit: 'MHEUSA', role: 'No Access', account_status: 'Disabled', date_created: '10/26/23'},
  {name: 'Greg', email_name: 'greg@gmail.com', participant_business_unit: 'HPI', role: 'No Access', account_status: 'Disabled', date_created: '10/26/23'},
  {name: 'Sai', email_name: 'sai@gmail.com', participant_business_unit: 'HPI', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Kiran', email_name: 'kiran@gmail.com', participant_business_unit: 'HPI', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Kitty', email_name: 'kitty@gmail.com', participant_business_unit: 'MHEUSA', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
  {name: 'Greg', email_name: 'greg@gmail.com', participant_business_unit: 'HPI', role: 'No Access', account_status: 'Active', date_created: '10/26/23'},
];
