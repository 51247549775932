import { Component, OnInit } from '@angular/core';
import { OktaAuthService } from '@dal/core';

@Component({
  selector: 'dal-okta-callback-page',
  template: `
    <div class="container">
      <h2 class="heading text-[24px] text-primary mx-[4%]">Processing Login...</h2>
    </div>`,
  styles: []
})
export class OktaCallbackComponent implements OnInit {

  constructor(private oktaAuthService: OktaAuthService) {}

  async ngOnInit(): Promise<void> {
    try {
      await this.oktaAuthService.handleCallback();
    } catch (error) {
      console.error('Error during Okta callback handling:', error);
      // Note: What should we do here? Standard 403 page? Back to a login? Error?
    }
  }
}
