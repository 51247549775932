<div class="media-container">
  @if (isPreviewRoute()){
    <dal-shared-ui-media-renderer [url]="url" [type]="getMediaType()" [imagePreview]="true" [applyClass]="false"></dal-shared-ui-media-renderer>
  }
  @for (media of mediaFiles; track media) {
    <dal-shared-ui-media-renderer [url]="media.url" [type]="getMediaType()" [imagePreview]="false" [applyClass]="false"></dal-shared-ui-media-renderer>
    @if (!isPreviewRoute()){
      <div class="preview-link" (click)="openImageInNewTab(media.url)">
        <span class="preview-icon">
          <i class="fas fa-external-link-alt"></i>
        </span>
      </div>
    }
  }
</div>
