import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RolesService } from '../../services/roles.service';
import { AbsService } from '@dal/core';
@Component({
  selector: 'dal-edit-user-page',
  templateUrl: './editUserPage.component.html',
  styleUrls: ['./editUserPage.component.css'],
})
export class EditUserPageComponent {
  userForm: FormGroup;

  businessUnitss: string[] = [];
  businessUnits: string[] = [];
  roles: string[] = [];
  availableRolesPerUnit: any = {};

  constructor(private fb: FormBuilder, private roleService: RolesService, private absService: AbsService) {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      status: ['Disabled'],
      businessUnitsRoles: this.fb.array([]),
    });

    this.roles = this.roleService.getRoles();
    this.initializeAvailableRoles();
    this.loadBusinessUnits();
    this.addBusinessUnitRole();
  }

  get businessUnitsRoles(): FormArray {
    return this.userForm.get('businessUnitsRoles') as FormArray;
  }

  loadBusinessUnits() {
    this.absService.fetchAssetMetadataDropDownValuesforProviderBusinessUnit().subscribe(
      (units: any) => {
        this.businessUnits = units.map((unit: any) => unit.name);
      },
      (error) => {
        console.error('Error fetching business units:', error);
      }
    );
  }

  initializeAvailableRoles() {
    this.businessUnits.forEach((unit) => {
      this.availableRolesPerUnit[unit] = [...this.roles];
    });
  }

  addBusinessUnitRole() {
    const businessUnitRoleGroup = this.fb.group({
      businessUnit: ['', Validators.required],
      role: [{ value: '', disabled: true }, Validators.required],
    });
    this.businessUnitsRoles.push(businessUnitRoleGroup);
  }

  removeBusinessUnitRole(index: number) {
    const removedUnit = this.businessUnitsRoles
      .at(index)
      .get('businessUnit')?.value;
    const removedRole = this.businessUnitsRoles.at(index).get('role')?.value;
    if (removedUnit && removedRole) {
      if (!this.availableRolesPerUnit[removedUnit]) {
        this.availableRolesPerUnit[removedUnit] = [];
      }
      this.availableRolesPerUnit[removedUnit].push(removedRole);
    }
    this.businessUnitsRoles.removeAt(index);
  }

  checkDuplicateBusinessUnitRole(index: number): boolean {
    const selectedUnit = this.businessUnitsRoles
      .at(index)
      .get('businessUnit')?.value;
    const selectedRole = this.businessUnitsRoles.at(index).get('role')?.value;
    return this.businessUnitsRoles.controls.some((control, idx) => {
      return (
        idx !== index &&
        control.get('businessUnit')?.value === selectedUnit &&
        control.get('role')?.value === selectedRole
      );
    });
  }

  onBusinessUnitChange(index: number) {
    const selectedUnit = this.businessUnitsRoles
      .at(index)
      .get('businessUnit')?.value;
    if (selectedUnit) {
      this.businessUnitsRoles.at(index).get('role')?.enable();
      if (this.checkDuplicateBusinessUnitRole(index)) {
        alert('This business unit and role combination already exists!');
        this.businessUnitsRoles.at(index).get('businessUnit')?.reset();
      }
    } else {
      this.businessUnitsRoles.at(index).get('role')?.disable();
    }
  }

  onRoleChange(index: number) {
    if (this.checkDuplicateBusinessUnitRole(index)) {
      alert('This business unit and role combination already exists!');
      this.businessUnitsRoles.at(index).get('role')?.reset();
    }
  }

  getAvailableRoles(index: number): string[] {
    const selectedUnit = this.businessUnitsRoles
      .at(index)
      .get('businessUnit')?.value;

    if (!selectedUnit || !this.roles || this.roles.length === 0) {
      return [];
    }
    const selectedRolesForUnit = this.businessUnitsRoles.controls
      .filter(
        (control, idx) =>
          idx !== index && control.get('businessUnit')?.value === selectedUnit
      )
      .map((control) => control.get('role')?.value);
    const availableRoles = this.roles.filter(
      (role) => !selectedRolesForUnit.includes(role)
    );
    return availableRoles;
  }

  isBusinessUnitFullyAssigned(unit: string): boolean {
    return this.businessUnitsRoles.controls.some(
      (control) => control.get('businessUnit')?.value === unit
    );
  }
  onSave() {
    let hasDuplicate = false;
    this.businessUnitsRoles.controls.forEach((control, index) => {
      if (this.checkDuplicateBusinessUnitRole(index)) {
        hasDuplicate = true;
        alert('Duplicate business unit-role combination found!');
      }
    });
    if (!(!hasDuplicate && this.userForm?.valid)) {
    // firm is invalid or has duplicate business unit-role combination
      this.userForm.markAllAsTouched();
    }
  }

  onDeleteUser() {
    // handle delete user
  }
}
