import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; // Import RouterModule
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MaterialModule } from './material.module';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragdropfileComponent } from './components/dragdropfile/dragdropfile.component';
import { ActivityQueueComponent } from './components/activityQueue/activityQueue.component';
import { SearchComponent } from './components/search/search.component';
import { DropDownComponent } from './components/dropDown/dropDown.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SearchPreviewComponent } from './components/searchPreview/searchPreview.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { AssetpreviewComponent } from './components/assetpreview/assetpreview.component';
import { TabularDataComponent } from './components/tabular-data/tabular-data.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxLoadingModule } from 'ngx-loading';
import { ByteToMbPipe } from './pipes/bytetomb.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoaderComponent } from './components/loader/loader.component';
import { DefaultImgDirective } from './directives/default-img.directive';
import { MediaRendererComponent } from './components/mediaRenderer/mediaRenderer.component';
import { VideoUploadThumbnailComponent } from './components/video-upload-thumbnail/video-upload-thumbnail.component';
import { AudioUploadThumbnailComponent } from './components/audioUploadThumbnail/audioUploadThumbnail.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule, // Ensure RouterModule is imported
    MatTooltipModule,
  ],
  declarations: [
    NavbarComponent,
    SidenavComponent,
    ActivityQueueComponent,
    SearchComponent,
    DragdropfileComponent,
    DropDownComponent,
    DialogComponent,
    SearchPreviewComponent,
    SearchFilterComponent,
    AssetpreviewComponent,
    TabularDataComponent,
    LoaderComponent,
    ByteToMbPipe,
    DefaultImgDirective,
    MediaRendererComponent,
    VideoUploadThumbnailComponent,
    AudioUploadThumbnailComponent,
  ],
  exports: [
    NavbarComponent,
    SidenavComponent,
    ActivityQueueComponent,
    SearchComponent,
    DragdropfileComponent,
    MaterialModule,
    FormsModule,
    DropDownComponent,
    DialogComponent,
    ReactiveFormsModule,
    SearchPreviewComponent,
    SearchFilterComponent,
    AssetpreviewComponent,
    TabularDataComponent,
    MatTooltipModule,
    LoaderComponent,
    [NgxSpinnerModule.forRoot()],
    [NgxLoadingModule.forRoot({})],
    DefaultImgDirective,
    MediaRendererComponent,
    VideoUploadThumbnailComponent,
    AudioUploadThumbnailComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
  ],
})
export class SharedUiModule {}
