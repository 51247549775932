<div
  class="drag-drop-container text-center flex flex-col justify-center align-center text-primary border-2 border-solid border-grey_medium bg-grey_light min-h-80 h-auto"
  (drop)="onFileDropped($event)" (dragover)="handleDragOver($event)" (dragenter)="handleDragEnter($event)">

  <div>
    <i class="fas fa-cloud-upload fa-2x justify-center" aria-hidden="true"></i>
  </div>
  <div class="flex justify-center">
    <label for="fileInput" class="flex flex-column text-primary text-2xl items-center">
      Drag and drop files here or &nbsp;
      <a href="#" class="text-primary underline" (click)="fileInput.click()">Browse</a>
    </label>
    <input #fileInput type="file" hidden (change)="fileBrowseHandler($event)" multiple>
  </div>
</div>

<div class="max-h-80 overflow-y-auto">

  @if (files.length > 0 ) {
  @for (file of files; track file; let i = $index) {
  <div id="{{ file.name }}" class="file-list-box flex justify-between items-center border border-solid border-grey_medium bg-grey_light mt-[10px]">
    <span>{{ file.name }}</span>
    <span class="delete-button-wrapper text-primary cursor-pointer" (click)="deleteFile(i)"><mat-icon>delete</mat-icon></span>
  </div>
  }
  }
</div>
<div id="error" class="flex justify-between items-center mt-[10px]" style="display: none; color: red;">
  <span>One or more assets with names </span>
    @for (file of duplicateFiles; track file; let i = $index) {
    [{{file}} @if(i < duplicateFiles.length-1) { , }]
    }
  <span> already exist in the system. Please rename them and upload again.</span>
  @if(role == "PA") {<p>Do you want to override? To Import anyway Please click on upload button again.</p>}
</div>
