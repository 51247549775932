.rotate {
  transform: rotate(180deg);
}

button{
  border-color: #50737C;
}

.search{
  padding: 0;
  font-size: 10px;
  height: 1%;
}

.multipleSelect{
  background-color: white !important;
  width: 70% !important;
}

.multipleOption{
  background-color: #666667 !important;
}

.mat-mdc-select{
  width: 300px !important;
}

.mat-mdc-select-arrow{
  padding: 10px !important;
}

.mat-mdc-select-panel {
  padding: 0 !important;
  margin-right: 20px;
}

.mat-pseudo-checkbox-full {
  border-color: rgba(220, 245, 251, 0.54) !important;
}

.description::placeholder{
  position: relative;
  top: -25px;
}

// .description{
//   // position: relative;
  
//   // text-align: right;
//   // display: flex;
//   //   align-items: flex-start;
//   // vertical-align: top;
// }
.selected-option {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #000000;
  background-color: #ffffff;
  color: #333;
  border-radius: 5px;
  font-size: 12px;
  margin: 2px;
}

.remove-btn {
  background-color: #b5b4b4;
  border: 1px solid #ffffff;
  color: #ffff;
  font-size: 12px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 8px;
  padding: 0;
}

.remove-btn:hover {
  background-color: #f0f0f0;
  border-color: #000000;
}

.cross-mark {
  font-weight: bold;
  font-size: 12px;
  color: #ffff;
}

.remove-btn:hover .cross-mark {
  color: #e74c3c;
}
