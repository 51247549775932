import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'dal-shared-ui-assetpreview',
  templateUrl: './assetpreview.component.html',
  styleUrl: './assetpreview.component.scss',
})
export class AssetpreviewComponent{
@Input() mediaFiles = [
  { path: 'assets/BLD210015.jpg', type: 'image' }
  // { path: 'assets/46308539.jpg', type: 'image' }
  // { path: 'assets/6641030.mp4', type: 'video' }
  // { path: 'assets/test.mp4', type: 'video' }
  // { path: 'assets/music.mp3', type: 'audio' }
];
showCloseButton = false;

  constructor(private router: Router) { }

  getMediaType(url: string): string {
    const extension = this.getFileExtension(url);
    switch (extension.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'png':
        return 'image';
      case 'mp4':
      case 'webm':
        return 'video';
      case 'mp3':
      case 'wav':
        return 'audio';
      default:
        return 'unknown';
    }
  }

  getFileExtension(url: string): string {
    const parts = url.split('.');
    if (parts.length === 1) {
      return '';
    }
    return parts.pop()!;
  }

  openImageInNewTab(url: string): void {
    window.open('/preview', '_blank');
  }

  isPreviewRoute(): boolean {
    return this.router.url === '/preview';
  }

  isPreviewOpen: boolean = false;

  openPreview(mediaPath: string) {
    this.isPreviewOpen = true;
  }

  closePreview() {
    this.isPreviewOpen = false;
    window.close();
  }

}
